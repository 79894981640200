import { useQuery } from '@tanstack/react-query';
import { IObject } from 'types';
import { useApi } from './useApi';

export const useHealthcheck = () => {
  const api = useApi();
  return useQuery<IObject[]>(['healthcheck'], () => api.healthcheck(), {
    retry: false,
  });
};
