import { Table } from 'antd';
import { useParams } from 'react-router';
import type { IAnalyticsPosition, IProvider } from '../../../types';
import { usePositions } from '../../../hooks/usePositions';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { useApi } from '../../../hooks/useApi';
import { useExcludedProviders } from '../../../hooks/useExcludedProviders';
import { PricesCell } from './PricesCell';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { useSortPositions } from '../hooks/useSortPositions';
import { PositionTitle } from '../../../components/PositionTitle';

interface Props {
  positions: IAnalyticsPosition[];
  isLite?: boolean;
  query?: string;
  providers: IProvider[];
  documentPositionId?: string;
}

export const SettingsTable = ({
  positions,
  isLite,
  providers,
  documentPositionId,
  query,
}: Props) => {
  const { budgetId } = useParams();
  const excludedPositions = usePositions();
  const excludedProviders = useExcludedProviders();
  const api = useApi();
  const analytics = useAnalytics(budgetId || '');

  const queryFromDocument = positions.find(
    ({ id }) => id === documentPositionId,
  );

  const sortPositions = useSortPositions(
    excludedPositions?.data,
    queryFromDocument?.name,
  );

  const data = sortPositions(positions).map(p => ({
    ...p,
    countString: `${p.count} ${p.unit}`,
    selected: !excludedPositions.data?.includes(p.id),
  }));

  const filteredProviders = providers
    .filter(p => !excludedProviders.data?.includes(p.id))
    .map(({ name, id, type }, index) => ({
      render: (value: any) => <PricesCell {...value} />,
      title: () => (
        <div>
          {!!type && (
            <p className="text-xs opacity-50">
              {type === 'market' ? 'Цены СтройСети' : 'Ваши цены'}
            </p>
          )}
          <p>{name}</p>
        </div>
      ),
      dataIndex: id,
      key: id,
      fixed: false,
    }));

  let boldRow = '';

  positions.forEach(p => {
    const found = analytics?.data?.personal?.find(a => a.positionId === p.id);
    if (found) boldRow = p.name;
  });

  const columns = isLite
    ? [
        {
          title: 'Номенклатура контрагента',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          width: 300,
        },
      ]
    : [
        {
          title: 'Номенклатура контрагента',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          width: 300,
          render: (v: string, d: any) => (
            <div
              style={{
                color: v === queryFromDocument?.name ? 'blue' : 'black',
                fontWeight: v === boldRow ? 600 : 400,
                opacity: data?.find(({ name }) => name === v)?.selected
                  ? 1
                  : 0.4,
              }}
            >
              <PositionTitle id={d.id} title={v} />
            </div>
          ),
        },
        {
          title: 'Кол-во',
          dataIndex: 'countString',
          key: 'countString',
          fixed: 'left',
          width: 100,
        },
        ...filteredProviders,
        {
          title: 'Разброс',
          dataIndex: 'total',
          width: 200,
          key: 'total',
          fixed: 'right',
        },
      ];

  const onSelect = (id: string) => api.includePosition(id);
  const onUnselect = (id: string) => api.excludePosition(id);

  const onSelectAll = () =>
    data.forEach(({ id }) => {
      api.includePosition(id);
    });

  const onUnSelectAll = () =>
    data.forEach(({ id }) => {
      api.excludePosition(id);
    });

  if (excludedPositions.isLoading) return <LoadingScreen />;

  const defaultSelectedPositions = positions
    .map(({ id }) => id)
    .filter(id => !excludedPositions.data?.includes(id));

  return (
    <Table
      size="middle"
      scroll={{
        x: filteredProviders.length * 150 + 400,
        y: 500,
      }}
      dataSource={data?.length ? data : positions}
      // @ts-ignore
      columns={columns}
      rowKey="id"
      pagination={false}
      locale={{ emptyText: 'Нет данных' }}
      rowSelection={{
        checkStrictly: true,
        defaultSelectedRowKeys: defaultSelectedPositions,
        onSelect: ({ id }, val) => (val ? onSelect(id) : onUnselect(id)),
        onSelectAll: e => (e ? onSelectAll() : onUnSelectAll()),
      }}
    />
  );
};
