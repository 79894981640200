import React from 'react';
import { IProvider } from '../types';
import { Table } from 'antd';
import { useExcludedProviders } from '../hooks/useExcludedProviders';
import { useApi } from '../hooks/useApi';
import BeatLoader from 'react-spinners/BeatLoader';
import { useUpdateToast } from '../pages/Order/hooks/useUpdateToast';

interface Props {
  providers: IProvider[];
}

export const ProvidersList = ({ providers }: Props) => {
  const excludedProviders = useExcludedProviders();
  const api = useApi();
  const { updateNotify } = useUpdateToast();

  const columns = [
    {
      title: 'Поставщик',
      dataIndex: 'name',
      key: 'id',
    },
  ];

  const onSelect = (id: string) => {
    api.includeProvider(id);
    updateNotify();
  };
  const onUnselect = (id: string) => {
    api.excludeProvider(id);
    updateNotify();
  };

  const defaultSelectedProviders = providers
    .map(({ id }) => id)
    .filter(id => !excludedProviders.data?.includes(id));

  if (!excludedProviders?.data || !providers.length) return <BeatLoader />;

  return (
    <Table
      showHeader={false}
      dataSource={providers.filter(({ hidden }) => !hidden)}
      columns={columns}
      pagination={false}
      rowKey={'id'}
      size={'middle'}
      scroll={{ y: 500 }}
      rowSelection={{
        checkStrictly: true,
        defaultSelectedRowKeys: defaultSelectedProviders,
        onSelect: ({ id }, val) => (val ? onSelect(id) : onUnselect(id)),
      }}
    />
  );
};
