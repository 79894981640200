import {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import useNotification from 'antd/es/notification/useNotification';
import { useApi } from '../../../hooks/useApi';
import { notification } from 'antd';
import { useParams } from 'react-router';
import { UpdateNotificationBody } from '../components/UpdateNotificationBody';

export const UpdateToastContext = createContext({
  updated: false,
  setUpdated: (state: boolean) => null,
});

export const UpdateToastContextProvider = ({ children }: PropsWithChildren) => {
  const [updated, setUpdated] = useState(false);

  const value = useMemo(
    () => ({
      updated,
      setUpdated,
    }),
    [updated],
  );

  return (
    <UpdateToastContext.Provider
      // @ts-ignore
      value={value}
    >
      {children}
    </UpdateToastContext.Provider>
  );
};

export const useUpdateToast = () => {
  const { setUpdated } = useContext(UpdateToastContext);
  const [_, context] = useNotification();
  const { orderId } = useParams();
  const api = useApi();

  const updateNotify = () => {
    setUpdated(true);

    notification.info({
      placement: 'bottomRight',
      message: 'Вы изменили данные, пересчитайте заказ',
      description: (
        <UpdateNotificationBody
          onClick={async () => {
            await api.optimizeOrder(orderId!);
            window.location.reload();
          }}
        />
      ),
    });
  };

  return { updateNotify, context };
};
