import { create } from 'zustand';
import type { PriceType } from '~/types';

type OrderState = {
  priceType: PriceType;
  changePriceType: (value: PriceType) => void;
};

export const useOrderStore = create<OrderState>(set => ({
  priceType: 'wholesale',
  changePriceType: value => set(() => ({ priceType: value })),
}));
