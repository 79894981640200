/* eslint-disable react/destructuring-assignment */
import { type FC } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { type IProduct } from '../../../types';

interface Props extends IProduct {
  onClick?: VoidFunction;
}
export const BudgetItem: FC<Props> = props => {
  return (
    <div
      onClick={props.onClick}
      className="bg-white rounded p-5 shadow hover:bg-gray-50 cursor-pointer justify-between flex gap-5"
    >
      {Boolean(props?.searchQuery) && (
        <p className="opacity-50">{props?.searchQuery}</p>
      )}

      <p className="flex grow">{props?.productName}</p>

      <p className="flex" style={{ minWidth: 100, textAlign: 'end' }}>
        {Math.round(props.count * props.koef)} {props.unit}
      </p>

      <Tooltip title="Изменить">
        <Button shape="circle" icon={<EditOutlined />} />
      </Tooltip>
    </div>
  );
};
