import { useCallback, useState } from 'react';
import type { TableProps } from 'antd';
import { Table } from 'antd';

import { useVerifiedPositionsStore } from 'store/useVerifiedPositionsStore';
import type { IAnalyticsPosition } from '~/types';

type Props = {
  productId: string;
  positions: {
    id: string;
    name: string;
    verified: boolean;
  }[];
};

export const DefaultVerifiedPositions = ({ productId, positions }: Props) => {
  const { toggle, setPositions } = useVerifiedPositionsStore();
  const columns: TableProps<IAnalyticsPosition>['columns'] = [
    {
      title: 'Номенклатура контрагента',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 300,
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState(() => {
    return positions.filter(({ verified }) => verified).map(({ id }) => id);
  });

  const handleChange = useCallback((newSelectedRowKeys: string[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  }, []);

  const handleSelect = useCallback(
    (row: { id: string }, checked: boolean) => {
      toggle(row.id, {
        positionId: row.id,
        productId,
        verified: checked,
      });
    },
    [productId, toggle],
  );

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      setPositions(
        positions.reduce(
          (acc, position) => ({
            ...acc,
            [position.id]: {
              positionId: position.id,
              productId,
              verified: checked,
            },
          }),
          {},
        ),
      );
    },
    [productId, positions, setPositions],
  );

  return (
    <Table
      size="middle"
      scroll={{
        y: 500,
      }}
      dataSource={positions}
      columns={columns}
      rowKey="id"
      pagination={false}
      locale={{ emptyText: 'Нет данных' }}
      rowSelection={{
        checkStrictly: true,
        selectedRowKeys,
        onChange: handleChange,
        onSelect: handleSelect,
        onSelectAll: handleSelectAll,
      }}
    />
  );
};
