import { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { LeftCircleFilled } from '@ant-design/icons';
import { Tabs, Button } from 'antd';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { OfferTab } from './components/OfferTab';
import { OrderPayment } from './components/OrderPayment';
import { OrderTab } from './components/OrderTab';
import { ProductsTab } from './components/ProductsTab';
import { Settings } from './components/Settings';
import { UpdateToastContext, useUpdateToast } from './hooks/useUpdateToast';
import { BudgetReportModal } from '../../components/BudgetReportModal';
import { ExpiredPricesModal } from '../../components/ExpiredPricesModal';
import { LoadingScreen } from '../../components/LoadingScreen';
import { OptimizationErrorModal } from '../../components/OptimizationErrorModal';
import { useApi } from '../../hooks/useApi';
import { useOrder } from '../../hooks/useOrder';
import { useOrderDetails } from '../../hooks/useOrderDetails';
import { useOrderStore } from '../../store/useOrderStore';
import type { PriceType } from '~/types';

export const Order = () => {
  const api = useApi();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const UpdateToast = useUpdateToast();
  const order = useOrder(orderId || '');
  const details = useOrderDetails(orderId || '');
  const { priceType, changePriceType } = useOrderStore();
  const { updated } = useContext(UpdateToastContext);
  const [page, setPage] = useState<string>('Order');

  const [reportModalOpened, setReportModalOpened] = useState(false);
  const [invalidTimeStamp, setInvalidTimeStamp] = useState<Date | null>(
    details.data?.updatedAt || null,
  );

  const initialPriceType: PriceType = useMemo(() => {
    if (details.data) {
      return details.data.priceType || 'wholesale';
    } else {
      return 'wholesale';
    }
  }, [details.data]);

  useEffect(() => {
    changePriceType(initialPriceType);
  }, [initialPriceType, changePriceType]);

  const [isError, setIsError] = useState(false);
  const [isNotFound, setNotFound] = useState(false);

  useEffect(() => {
    api.getOptimizationStatus(orderId || '').then(data => {
      if (data === 'notFound') setNotFound(true);
      if (data === 'error') setIsError(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const onReOptimize = useCallback(async () => {
    setInvalidTimeStamp(details.data?.updatedAt || null);
    await api.optimizeOrder(orderId, priceType);
    window.location.reload();
  }, [api, details.data?.updatedAt, orderId, priceType]);

  const onBack = () => navigate('/orders');

  const tabs = useMemo(
    () => [
      {
        key: 'Order',
        label: 'Заявка',
        children: <OrderTab onReOptimize={onReOptimize} />,
      },
      {
        key: 'Offers',
        label: 'Предложения',
        children: <OfferTab onOptimize={onReOptimize} />,
      },
      {
        key: 'Products',
        label: 'Смета',
        children: <ProductsTab />,
      },
    ],
    [onReOptimize],
  );

  if (isError) return <OptimizationErrorModal onReOptimize={onReOptimize} />;
  if (isNotFound) return <ExpiredPricesModal onReOptimize={onReOptimize} />;

  if (
    !order?.data?.budgetId ||
    order.isLoading ||
    !details.data ||
    details.isLoading ||
    invalidTimeStamp === details.data?.updatedAt
  ) {
    return (
      <LoadingScreen description={'Обновляем цены. \nПожалуйста подождите'} />
    );
  }

  if (order.data?.status === 'PAYMENT') return <OrderPayment />;

  return (
    <div className="px-5 py-5 flex flex-col gap-5 grow">
      {updated && (
        <div className="bg-yellow-400 bbudget-2 bbudget-yellow-100 rounded-xl p-5 flex flex-row justify-between">
          <div className="flex flex-col gap-1">
            <p className="font-bold">В заказе неактуальные данные</p>
            <p className="text-sm">
              Вы внесли изменения в заказ, которые нужно применить, чтобы
              получить актуальную информацию.
            </p>
          </div>

          <Button onClick={onReOptimize} type="primary">
            Обновить данные
          </Button>
        </div>
      )}
      <div className="bg-white p-5 pb-0 rounded-xl shadow w-full flex flex-col grow">
        <div className="flex row w-full">
          <div
            onClick={onBack}
            className="cursor-pointer mb-3 text-lg hover:text-black text-gray-500"
          >
            <LeftCircleFilled style={{ marginRight: 5 }} />
            заявки
          </div>
        </div>

        <Settings
          orderName={order.data.name}
          deliveryDate={order?.data?.deliveryDate}
          recipientName={order?.data?.recipientName}
          deliveryAddress={order?.data?.AddressInOrder?.name ?? ''}
        />

        <Tabs
          activeKey={page}
          onChange={setPage}
          className="mb-0 pb-0"
          items={tabs}
        />
      </div>

      <BudgetReportModal
        onClose={() => setReportModalOpened(false)}
        open={reportModalOpened}
        fallbackBudgetId={order.data.budgetId}
      />

      {UpdateToast.context}
    </div>
  );
};
