import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { ObjectItem } from './components/ObjectItem';
import { useApi } from '../../hooks/useApi';
import { type IObject } from '../../types';
import { RenameModal } from '../../components/RenameModal';
import { useObjects } from '../../hooks/useObjects';
import { Button } from 'react-daisyui';
import { AddModal } from '../../components/AddModal';
import { DeleteModal } from '../../components/DeleteModal';
import { PageHeader } from '../../components/PageHeader';
import { analytics } from '../../utils/analytics';
import { LoadingScreen } from '../../components/LoadingScreen';

export const Objects = () => {
  const { data, refetch, isLoading } = useObjects();
  const navigate = useNavigate();
  const api = useApi();
  const [renamingObject, setRenamingObject] = useState<IObject | null>(null);
  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [deletingObject, setDeletingObject] = useState<IObject | null>(null);

  const onObjectClick = (id: string) => {
    navigate('/object/' + id);
  };

  const onCreate = async ({
    name,
    address,
  }: {
    name?: string;
    address?: string;
  }) => {
    if (name) {
      const created: IObject = await api.createObject(name, address);
      await refetch();
      setAddModalOpened(false);
      analytics.createObject(created);
      navigate('/object/' + created.id);
    }
  };

  const onDelete = async () => {
    if (deletingObject?.id) {
      await api.deleteObject(deletingObject.id);
      await refetch();
      analytics.removeObject(deletingObject);
      setDeletingObject(null);
    }
  };

  const onRename = async (name: string) => {
    if (renamingObject?.id) {
      const id = renamingObject?.id || '';
      await api.updateObject(id, { name });
      await refetch();
      analytics.renameObject(renamingObject);
      setRenamingObject(null);
    }
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <Layout>
      <div className="flex flex-col gap-3 w-full">
        <PageHeader>
          <div className="flex flex-row justify-between items-center">
            <p className="text-3xl font-bold">Ваши объекты</p>
            <Button onClick={() => setAddModalOpened(true)}>
              Создать объект
            </Button>
          </div>
        </PageHeader>

        {data?.map(object => (
          <ObjectItem
            key={object.id}
            onDelete={() => setDeletingObject(object)}
            onRename={() => setRenamingObject(object)}
            onClick={() => onObjectClick(object.id)}
            {...object}
          />
        ))}
      </div>

      {!data?.length && (
        <p className="opacity-50 text-center m-10">
          Объекты — это основные рабочие пространства в StroySet «Аналитика». В
          них вы можете вносить сметы, чтобы получать прогноз смет, оформлять
          заявки и не только.
        </p>
      )}

      <DeleteModal
        onClose={() => setDeletingObject(null)}
        open={!!deletingObject}
        onSubmit={onDelete}
        text="Вы уверены, что хотите удалить объект со всеми счетами, аналитикой и заявками? Данное действие нельзя будет отменить."
      />

      <RenameModal
        defaultValue={renamingObject?.name}
        onSubmit={onRename}
        open={Boolean(renamingObject)}
        onClose={() => setRenamingObject(null)}
      />

      <AddModal
        onSubmit={onCreate}
        open={isAddModalOpened}
        onClose={() => setAddModalOpened(false)}
      />
    </Layout>
  );
};
