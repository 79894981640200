import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';

interface IExcludedPosition {
  positionId: string;
}

export const useBudgetReport = (id: string) => {
  const api = useApi();
  return useQuery<{ name: string; id: string; count: number; price: number }[]>(
    ['budgetReport'],
    () => api.getBudgetReport(id),
    {
      keepPreviousData: true,
      enabled: !!id,
    },
  );
};
