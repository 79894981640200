import { useState } from 'react';
import { Layout } from '../../components/Layout';
import { BudgetItem } from './components/BudgetItem';
import { Button } from 'react-daisyui';
import { PageHeader } from '../../components/PageHeader';
import { LoadingScreen } from '../../components/LoadingScreen';
import { useApi } from '../../hooks/useApi';
import { useBudgets } from '../../hooks/useBudgets';
import { AddModal } from '../../components/AddModal';
import moment from 'moment/moment';

export const Budgets = () => {
  const budgets = useBudgets();
  const api = useApi();
  const [isAddModalOpened, setAddModalOpened] = useState(false);

  const onClick = (id: string) => {
    const budget = budgets.data?.find(b => b.id === id);

    window.location.replace(
      `/object/${budget?.objectId}/budget/${id}/analytics`,
    );
  };

  const onDelete = async (id: string) => {
    await api.deleteBudget(id);
    await budgets.refetch();
  };

  const onCreate = async ({ name }: { name: string }) => {
    const objects = await api.getObjects();

    let newObject = objects.find(({ name }: any) => name === 'Без объекта')?.id;

    if (!newObject) {
      const found = objects.find(({ name }: any) => name === 'Без объекта');
      if (found) {
        newObject = found.id;
      } else {
        const { id } = await api.createObject('Без объекта');
        newObject = id;
      }
    }

    const created = await api.createBudget(newObject, name);

    window.location.replace(`/object/${newObject}/budget/${created?.id}`);
  };

  if (!budgets.data) return <LoadingScreen />;

  return (
    <>
      <Layout>
        <div className="flex flex-col gap-3 w-full">
          <PageHeader>
            <div className="flex flex-row justify-between items-center">
              <p className="text-3xl font-bold">Ваши сметы</p>
              <Button
                onClick={() =>
                  onCreate({
                    name: `от ${moment(new Date()).format('DD.MM.YYYY HH:mm')}`,
                  })
                }
              >
                Cоздать смету
              </Button>
            </div>
          </PageHeader>
          {budgets.data
            ?.filter(({ view }) => view === 'ANALYTICS')
            ?.map(budget => (
              <BudgetItem
                key={budget.id}
                onDelete={() => onDelete(budget.id)}
                onClick={() => onClick(budget.id)}
                {...budget}
              />
            ))}
        </div>
      </Layout>

      <AddModal
        onClose={() => setAddModalOpened(false)}
        open={isAddModalOpened}
        onSubmit={onCreate}
      />
    </>
  );
};
