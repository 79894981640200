import { Button, Dropdown } from 'react-daisyui';
import { type IBudget } from '../../../types';

interface Props extends IBudget {
  onClick: VoidFunction;
  onDelete: VoidFunction;
  onRename: VoidFunction;
}

export const BudgetItem = ({ name, onClick, onDelete, onRename }: Props) => {
  return (
    <div className="px-5 flex shadow flex-row justify-between w-full items-center rounded-lg">
      <div
        onClick={onClick}
        className="h-full py-5 w-full flex justify-center items-center"
      >
        <p className="w-full text-left">{name}</p>
      </div>

      <Dropdown>
        <Button variant="outline">...</Button>

        <Dropdown.Menu>
          <Dropdown.Item onClick={onRename}>Переименовать</Dropdown.Item>
          <Dropdown.Item className="text-red-500" onClick={onDelete}>
            Удалить
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
