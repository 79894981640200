import { PriceBadge } from '../pages/Analytics/components/PriceBadge';
import { type IPricesHistoryItem } from '../types';
import { TinyArea } from '@ant-design/plots';

export const PricesHistoryPopover = ({
  pricesHistory,
  unit,
}: {
  pricesHistory: IPricesHistoryItem[];
  unit: string;
}) => {
  const minPrice = Math.min(...pricesHistory.map(v => v.price));

  const data = pricesHistory.map(v => v.price - minPrice).reverse();
  const config = {
    height: 60,
    autoFit: true,
    data,
    smooth: true,
  };

  return (
    <div className="flex flex-col gap-7 w-52">
      <div className="flex flex-col gap-3">
        <p className="opacity-50">Последние закупки</p>

        {pricesHistory?.length > 1 && <TinyArea {...config} />}

        {pricesHistory?.map(({ price, date }) => (
          <div className="flex flex-row justify-between">
            <p>{date}</p>
            <PriceBadge unit={unit} price={price} type="default" />
          </div>
        ))}
      </div>
    </div>
  );
};
