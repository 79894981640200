import { type FC } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { Header } from './Header';
import { useApi } from '../hooks/useApi';
import { Analytics } from '../pages/Analytics';
import { Budgets } from '../pages/Budgets';
import { DemoSearch } from '../pages/DemoSearch/DemoSearch';
import { Documents } from '../pages/Documents';
import { Login } from '../pages/Login';
import { ObjectDetails } from '../pages/ObjectDetails';
import { Objects } from '../pages/Objects';
import { UpdateToastContextProvider } from '../pages/Order/hooks/useUpdateToast';
import { Order } from '../pages/Order/Order';
import { Orders } from '../pages/Orders';
import { Picking } from '../pages/Picking';
import { Reports } from '../pages/Reports';
import { Settings } from '../pages/Settings';
import { SupplyReport } from '../pages/SupplyReport';

export const Router: FC = () => {
  const { token } = useApi();

  if (!token) {
    return (
      <Routes>
        <Route
          element={<Analytics />}
          path="/object/:objectId/budget/:budgetId/analytics"
        />
        <Route
          element={
            <UpdateToastContextProvider>
              <Order />
            </UpdateToastContextProvider>
          }
          path="/order/:orderId"
        />
        <Route element={<Login />} path="/*" />
      </Routes>
    );
  }

  return (
    <>
      <Header />

      <Routes>
        <Route element={<Navigate to="/orders" replace />} path="/" />
        <Route element={<DemoSearch />} path="/demo-search" />
        <Route element={<Objects />} path="/objects" />
        <Route element={<Budgets />} path="/budgets" />
        <Route
          element={
            <UpdateToastContextProvider>
              <Order />
            </UpdateToastContextProvider>
          }
          path="/order/:orderId"
        />
        <Route element={<Orders />} path="/orders" />
        <Route element={<Settings />} path="/settings" />
        <Route element={<Reports />} path="/reports" />
        <Route element={<SupplyReport />} path="/reports/supply" />
        <Route element={<ObjectDetails />} path="/object/:objectId" />
        <Route element={<Documents />} path="/documents" />
        <Route
          element={<Analytics />}
          path="/object/:objectId/budget/:budgetId/analytics"
        />
        <Route
          element={<Picking />}
          path="/object/:objectId/budget/:budgetId"
        />
      </Routes>
    </>
  );
};
