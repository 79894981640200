import { useQuery } from '@tanstack/react-query';
import { IMarketProduct } from 'types';
import { useApi } from './useApi';

export const useSearch = (query: string, categoryId: string) => {
  const api = useApi();

  return useQuery<IMarketProduct[]>(['search', query, categoryId], () =>
    api.search(query, categoryId),
  );
};
