import { type FC, useEffect, useState, useCallback, memo } from 'react';
import {
  type DatePickerProps,
  Divider,
  Input,
  AutoComplete,
  Flex,
  Typography,
} from 'antd';
import { Button } from 'react-daisyui';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { Cart } from './components/Cart';
import { CheckoutModal } from './components/CheckoutModal';
import { CountModal } from './components/CountModal';
import { type CountModalResponse } from './components/CountModal';
import { DocumentDateModal } from './components/DocumentDateModal';
import { PickingRenameModal } from './components/PickingRenameModal';
import { Search } from './components/Search';
import DatePicker from '../../components/DatePicker';
import { Modal } from '../../components/Modal';
// import { useAnalytics } from '../../hooks/useAnalytics';
import { useAddress } from '../../hooks/useAddress';
import { useApi } from '../../hooks/useApi';
import { useBudgetDetails } from '../../hooks/useBudgetDetails';
import { useObject } from '../../hooks/useObject';
import { useFileMatching } from '../../hooks/useUpload';
import { type IAddress, type IMarketProduct, type IProduct } from '../../types';
import { useAnalyticsView } from '../Analytics/hooks/useAnalyticsView';

const { Title } = Typography;

const DATE_FORMAT = 'DD.MM.YYYY';

export const Picking: FC = memo(() => {
  const navigate = useNavigate();
  const view = useAnalyticsView();
  const api = useApi();
  const [lastMatchingStatus, setLastMatchingStatus] = useState('DONE');
  const { budgetId, objectId } = useParams();

  const object = useObject(objectId);

  const file = useFileMatching();
  const [isFileUploading, setFileUploading] = useState(false);
  const [searchLoaded, setSearchLoaded] = useState(false);
  // const analytics = useAnalytics(budgetId || '');
  const budgetDetails = useBudgetDetails(budgetId || '');
  const [selectedMarketProduct, setSelectedMarketProduct] =
    useState<IMarketProduct | null>();
  const [productToReplace, setProductToReplace] =
    useState<IMarketProduct | null>(null);
  const [renameModalOpened, setRenameModalOpened] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>();
  const [checkoutModalOpened, setCheckoutModalOpened] = useState(false);
  const [documentDateModalOpened, setDocumentDateOpened] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [address, setAddress] = useState<string>(
    object?.data?.AddressInObject?.name ?? '',
  );
  useEffect(() => {
    setAddress(object?.data?.AddressInObject?.name ?? '');
  }, [object?.data?.AddressInObject]);

  const [addressData, setAddressData] = useState<IAddress>();
  const [recipientName, setRecipientName] = useState<string>('');
  const [deliveryDate, setDeliveryDate] = useState();
  const [matchedProducts, setMatchedProducts] = useState([]);

  const { data: addressOptions, isLoading } = useAddress(address || '');

  const onChange: DatePickerProps['onChange'] = value => {
    setDeliveryDate(value);
  };

  const onItemAdd = (product: IMarketProduct) => {
    setSelectedMarketProduct(product);
  };

  const onItemEdit = (product: IProduct) => {
    setSelectedProduct(product);
  };

  const onAddProduct = async (data: CountModalResponse) => {
    await api.addProduct(budgetId, data);
    await budgetDetails.refetch();
    setSelectedMarketProduct(null);
  };

  const onEditProduct = async (itemId: string, data: CountModalResponse) => {
    await api.updateProduct(budgetId, itemId, data);
    await budgetDetails.refetch();
    setSelectedProduct(null);
  };

  const onReplaceProduct = async (itemId: string, data: CountModalResponse) => {
    await api.updateProduct(budgetId, itemId, data);
    await budgetDetails.refetch();
    setSelectedProduct(null);
    setProductToReplace(null);
  };

  const onDeleteProduct = async (itemId: string) => {
    await api.deleteProduct(budgetId, itemId);
    await budgetDetails.refetch();
    setSelectedProduct(null);
  };

  const onCheckout = useCallback(async () => {
    if (budgetDetails.data) {
      setSubmitLoading(true);

      if (view !== 'order') {
        api.optimizeBudget(budgetId!, budgetDetails.data);
        navigate(`analytics?view=${view}`);
      } else {
        const { id } = await api.createOrder({
          name: budgetDetails.data?.name,
          deliveryDate,
          recipientName,
          budgetId,
          objectId: objectId ?? '',
          products: budgetDetails.data?.products.map(p => ({
            itemId: p.itemId,
            id: p.productId,
            query: p.searchQuery ?? '',
            count: p.count,
            unit: p.unit,
            koef: p.koef,
            excludedPositions: p.excludedPositions,
          })),
          queries:
            budgetDetails.data?.queries.map(q => ({
              query: q.query,
              count: q.count,
            })) ?? [],
        });

        if (addressData) {
          await api.updateOrderAddress(id, addressData);
        }

        await api.replaceMatchingHistoryBudgetId(budgetId, id);
        api.optimizeOrder(id, 'wholesale', matchedProducts);
        await new Promise(res => setTimeout(res, 2500));
        navigate(`/order/${id}`);
      }
    }
  }, [
    matchedProducts,
    addressData,
    api,
    budgetDetails.data,
    budgetId,
    deliveryDate,
    navigate,
    objectId,
    recipientName,
    view,
  ]);

  const onChooseFile = async () => {
    if (!budgetDetails.data?.products?.length) {
      const currentFile = (await file.choose())[0];

      if (currentFile) {
        setFileUploading(true);
        setDocumentDateOpened(view === 'report');

        try {
          await file.upload(currentFile);
          await budgetDetails.refetch();
        } catch (e) {
          throw new Error(`Error uploading the file: ${e}`);
        } finally {
          setFileUploading(false);
        }
      }
    }
  };

  // TODO
  useEffect(() => {
    const handler = () => budgetDetails.refetch();

    const interval = setInterval(handler, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (
      view !== 'report' &&
      budgetDetails.data?.createdAt === budgetDetails.data?.updatedAt &&
      budgetDetails.data?.products.length === 0
    ) {
      setRenameModalOpened(true);
    }
  }, [
    budgetDetails.data?.createdAt,
    budgetDetails.data?.products.length,
    budgetDetails.data?.updatedAt,
    view,
  ]);

  useEffect(() => {
    setLastMatchingStatus(budgetDetails.data?.matchingStatus || 'DONE');

    if (
      budgetDetails.data?.matchingStatus === 'DONE' &&
      lastMatchingStatus === 'MATCHING' &&
      !budgetDetails.data?.queries?.length &&
      !budgetDetails.data?.products?.length
    ) {
      toast.error('Не удалось распознать документ');
    }
  }, [budgetDetails.data]);

  const onAddressChange = text => {
    setAddress(text);
  };

  const onAddressSelect = useCallback(async (_, value) => {
    setAddressData(value);
  }, []);

  if (!budgetId) return null;

  return (
    <>
      <div
        className="w-full bg-gray-100 flex flex-row"
        style={{ height: 'calc(100vh - 70px)' }}
      >
        <div className="flex flex-1 p-5 flex-col">
          <Flex
            vertical
            style={{
              width: 500,
              gap: 15,
            }}
          >
            <Flex vertical>
              <Title level={5} style={{ marginBottom: 5 }}>
                Адрес доставки
              </Title>

              <AutoComplete
                value={address}
                options={addressOptions}
                onSelect={onAddressSelect}
                onChange={onAddressChange}
                placeholder="Укажите адрес доставки"
                loading={isLoading}
              />
            </Flex>

            <Flex vertical>
              <Title level={5} style={{ marginBottom: 5 }}>
                Дата доставки
              </Title>

              <DatePicker
                value={deliveryDate}
                placeholder="Выберите дату доставки"
                onChange={onChange}
                format={value => value.format(DATE_FORMAT)}
              />
            </Flex>

            <Flex vertical>
              <Title level={5} style={{ marginBottom: 5 }}>
                ФИО получателя
              </Title>

              <Input
                value={recipientName}
                onChange={e => setRecipientName(e.target.value)}
                placeholder="Доверенное лицо (получатель)"
              />
            </Flex>
          </Flex>

          <Divider />

          <Search onAdd={onItemAdd} onLoaded={() => setSearchLoaded(true)} />
        </div>

        {budgetDetails.data?.queries?.length ||
        budgetDetails.data?.products?.length ? (
          <Cart
            submitLoading={submitLoading}
            onSubmit={onCheckout}
            onEdit={onItemEdit}
            setMatchedProducts={setMatchedProducts}
          />
        ) : (
          <div className="flex-1 flex justify-center items-center h-full flex-col gap-5">
            {budgetDetails.data?.matchingStatus !== 'MATCHING' &&
            !isFileUploading ? (
              <>
                <p>Добавьте материалы из окна слева или загрузите смету</p>

                <Button disabled={isFileUploading} onClick={onChooseFile}>
                  Загрузить PDF или EXCEL
                </Button>

                <Button disabled={isFileUploading} onClick={onChooseFile}>
                  Загрузить картинку
                </Button>
              </>
            ) : (
              <>
                <BeatLoader />

                <p className="text-center px-10">
                  Происходит распознавание файла, пожалуйста подождите. Загрузка
                  может длиться до двух минут
                </p>
              </>
            )}
          </div>
        )}
      </div>

      {(budgetDetails?.isLoading || !searchLoaded) && (
        <Modal>
          <BeatLoader />
        </Modal>
      )}

      {Boolean(selectedMarketProduct) && (
        <CountModal
          onClose={() => setSelectedMarketProduct(null)}
          productId={selectedMarketProduct.productId}
          productName={selectedMarketProduct.productName}
          units={selectedMarketProduct.units}
          defaultUnit={selectedMarketProduct.units?.[0]}
          onSubmit={onAddProduct}
        />
      )}

      {Boolean(selectedProduct) && (
        <CountModal
          onClose={() => setSelectedProduct(null)}
          defaultCount={Math.round(selectedProduct.count)}
          defaultUnit={selectedProduct.units.find(
            unitData => unitData.koef === selectedProduct.koef,
          )}
          productId={selectedProduct.productId}
          productName={
            selectedProduct.searchQuery ? (
              <p className="opacity-50">{selectedProduct.searchQuery}</p>
            ) : (
              selectedProduct.productName
            )
          }
          units={selectedProduct.units}
          onSubmit={data => onEditProduct(selectedProduct.itemId, data)}
          onDelete={() => onDeleteProduct(selectedProduct.itemId)}
          isEditing
          renderChangePosition={() => (
            <>
              <Divider>или изменить позицию</Divider>
              <Search
                onAdd={product => setProductToReplace(product)}
                onLoaded={() => setSearchLoaded(true)}
              />
            </>
          )}
        />
      )}

      {Boolean(productToReplace) && Boolean(selectedProduct) && (
        <CountModal
          onClose={() => setProductToReplace(null)}
          productId={productToReplace.productId}
          productName={`${selectedProduct.productName} → ${productToReplace.productName}`}
          units={productToReplace.units}
          defaultUnit={productToReplace.units[0]}
          onSubmit={p => {
            onReplaceProduct(selectedProduct.itemId, p);
          }}
        />
      )}

      {checkoutModalOpened && (
        <CheckoutModal onClose={() => setCheckoutModalOpened(false)} />
      )}

      {documentDateModalOpened && (
        <DocumentDateModal onClose={() => setDocumentDateOpened(false)} />
      )}

      {renameModalOpened && (
        <PickingRenameModal
          budgetId={budgetId}
          title={
            view === 'order'
              ? 'Укажите название заявки'
              : 'Укажите название сметы'
          }
          defaultName={budgetDetails.data?.name}
          onClose={() => setRenameModalOpened(false)}
        />
      )}
    </>
  );
});
