import { type IMarketProduct } from '../../../types';
import { usePositionSearch } from '../hooks/usePositionSearch';
import { Spin } from 'antd';
import { makeBold } from '../../../utils/makeBold';

interface Props extends IMarketProduct {
  onClick?: VoidFunction;
  query: string;
}
export const SearchItem = (props: Props) => {
  const positionSearch = usePositionSearch(props.productId, props.query);

  return (
    <div
      onClick={props.onClick}
      className="bg-white rounded p-5 shadow hover:bg-gray-50 cursor-pointer flex flex-col gap-1"
    >
      <div
        className="mb-3"
        dangerouslySetInnerHTML={{
          __html: makeBold(props.productName, props.query.split(' ')),
        }}
      />

      {positionSearch.data ? (
        positionSearch.data.map((text: string) => (
          <div className="bg-gray-50 rounded border-2 border-gray-200 bolder-solid px-2">
            <div
              dangerouslySetInnerHTML={{
                __html: makeBold(text, props.query.split(' ')),
              }}
            />
          </div>
        ))
      ) : (
        <Spin />
      )}
    </div>
  );
};
