import { type FC, useState } from 'react';
import { useBudgets } from '../../hooks/useBudgets';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { useApi } from '../../hooks/useApi';
import { useParams } from 'react-router';
import { useObject } from '../../hooks/useObject';
import { Block } from './components/Block';
import { BudgetItem } from './components/BudgetItem';
import { AddModal } from '../../components/AddModal';
import { DeleteModal } from '../../components/DeleteModal';
import { LoadingScreen } from '../../components/LoadingScreen';
import { type IBudget } from '../../types';
import { RenameModal } from '../../components/RenameModal';
import { Tabs, Button } from 'antd';
import { useOrdersInObject } from '../../hooks/useOrdersInObject';
import { SettingsTabPanel } from './components/SettingsTabPanel';
import { OrdersTabPanel } from './components/OrdersTabPanel';
import { PlusOutlined } from '@ant-design/icons';

export const ObjectDetails: FC = () => {
  const { objectId } = useParams();
  const object = useObject(objectId || '');
  const budgets = useBudgets(objectId || '');
  const orders = useOrdersInObject(objectId || '');
  const navigate = useNavigate();
  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [deletingBudget, setDeletingBudget] = useState<null | IBudget>(null);
  const [renamingBudget, setRenamingBudget] = useState<null | IBudget>(null);
  const [page, setPage] = useState('budgets');
  const api = useApi();

  console.log({
    object,
    objectId,
  });

  // if (page === 'report') navigate('/reports');

  const onBudgetClick = (budget: IBudget) => {
    navigate(`./budget/${budget.id}`);
  };

  const onOrderClick = (order: IBudget) => {
    navigate(`/order/${order.id}`);
  };

  const onBack = () => {
    navigate('/objects');
  };

  const onDelete = async () => {
    if (deletingBudget?.id) {
      await api.deleteBudget(deletingBudget.id);
      await budgets.refetch();
      setDeletingBudget(null);
    }
  };

  const onRename = async (name: string) => {
    if (renamingBudget?.id) {
      await api.updateBudget(renamingBudget?.id, { name });
      await budgets.refetch();
      setRenamingBudget(null);
    }
  };

  const onCreate = async ({ name }: { name: string }) => {
    const created = await api.createBudget(objectId || '', name);
    await budgets.refetch();
    setAddModalOpened(false);
    navigate(`./budget/${created.id}`);
  };

  const tabs = [
    {
      key: 'budgets',
      label: 'Сметы',
      children: (
        <Block
          items={[
            <Button
              onClick={() => setAddModalOpened(true)}
              icon={<PlusOutlined />}
            >
              Добавить смету
            </Button>,
            ...(budgets.data?.map(item => (
              <BudgetItem
                onClick={() => onBudgetClick(item)}
                onDelete={() => setDeletingBudget(item)}
                onRename={() => setRenamingBudget(item)}
                {...item}
              />
            )) ?? []),
          ]}
        />
      ),
    },
    {
      key: 'orders',
      label: 'Заявки',
      children: (
        <OrdersTabPanel
          objectId={objectId}
          onOrderClick={onOrderClick}
          setDeletingBudget={setDeletingBudget}
          setRenamingBudget={setRenamingBudget}
          orders={orders.data}
        />
      ),
    },
    {
      key: 'report',
      label: 'Отчет',
    },
    {
      key: 'settings',
      label: 'Настройки',
      children: (
        <SettingsTabPanel
          id={objectId}
          AddressInObject={object.data?.AddressInObject?.name ?? ''}
        />
      ),
    },
  ];

  if (object.isLoading || budgets.isLoading) return <LoadingScreen />;

  return (
    <Layout>
      <div className="flex flex-col gap-3 w-full">
        <div className="bg-white p-5 pt-4 rounded-xl shadow flex flex-col mt-5">
          <p onClick={onBack} className="opacity-50 cursor-pointer mb-5">
            {'<'} к объектам
          </p>

          <div className="flex flex-row mt-2">
            <p className="opacity-50 mr-1 text-2xl">
              #
              {object.data?.id
                .slice(object.data?.id.length - 4, object.data?.id.length)
                .toUpperCase()}{' '}
              -
            </p>
            <p className="text-2xl">{object.data?.name}</p>
          </div>

          <Tabs onChange={setPage} items={tabs} />
        </div>
      </div>

      <AddModal
        onClose={() => setAddModalOpened(false)}
        open={isAddModalOpened}
        onSubmit={onCreate}
      />

      <DeleteModal
        onClose={() => setDeletingBudget(null)}
        open={!!deletingBudget}
        onSubmit={onDelete}
      />

      <RenameModal
        defaultValue={renamingBudget?.name}
        onClose={() => setRenamingBudget(null)}
        open={!!renamingBudget}
        onSubmit={onRename}
      />
    </Layout>
  );
};
