import React, { useState } from 'react';
import { Button, Input, Modal } from 'react-daisyui';
import { useApi } from '../../../hooks/useApi';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import moment from 'moment';

interface Props {
  onClose: VoidFunction;
}

export const DocumentDateModal = ({ onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm<{ date: string }>();
  const api = useApi();
  const { budgetId } = useParams();
  const onSubmit = async ({ date }: { date: string }) => {
    setLoading(true);
    const documentDate = new Date(date);
    await api.updateBudget(budgetId || '', { documentDate });
    setLoading(false);
    onClose();
  };

  const defaultDate = new Date();

  return (
    <Modal open>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <p className={'text-lg mb-2'}>Укажите дату документа</p>
          <Input
            autoFocus
            className={'w-full'}
            placeholder={moment(defaultDate.getDate()).format('DD.MM.YYYY')}
            {...register('date', {
              required: true,
              pattern: /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/,
            })}
          />
        </Modal.Body>
        <Modal.Actions>
          {/*<Button*/}
          {/*  className={'flex-1'}*/}
          {/*  type={'button'}*/}
          {/*  variant={'outline'}*/}
          {/*  onClick={onClose}*/}
          {/*>*/}
          {/*  Оставить текущую дату*/}
          {/*</Button>*/}
          <Button className={'flex-1'} type={'submit'} loading={loading}>
            Сохранить
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
