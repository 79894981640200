import { type IAnalyticsPosition } from '../../../types';
import _ from 'lodash';

export const useSortPositions = (excludedPositions?: string[]) => {
  return (positions: IAnalyticsPosition[]) => {
    const pos = positions.map(a => ({
      ...a,
      excluded: excludedPositions?.includes(a.id),
      sortPrice: a.count * a.minPrice,
    }));

    const result = _.orderBy(pos, ['excluded', 'sortPrice'], ['asc', 'asc']);
    return result
      .map(o => _.omit(o, ['excluded', 'sortPrice']))
      .filter(o => o?.minPrice || o?.maxPrice);
  };
};
