import type { IAnalyticsPosition, IProvider } from 'types';

type FlatPosition = {
  price: number;
  positionId: string;
  providerId: string;
};

export function getBestPositionPrices(
  positions: IAnalyticsPosition[],
  providers: IProvider[],
  isRetailPrice: boolean,
): FlatPosition {
  const flatPositions: FlatPosition[] = [];

  positions.forEach(position => {
    const prices = isRetailPrice ? position.retailPrices : position.prices;
    prices.forEach((price, providerIndex) => {
      flatPositions.push({
        positionId: position.id,
        providerId: providers[providerIndex].id,
        price: price * position.koef,
      });
    });
  });

  function compare(a: FlatPosition, b: FlatPosition) {
    if (a.price < b.price) {
      return -1;
    }
    if (a.price > b.price) {
      return 1;
    }
    return 0;
  }

  flatPositions.sort(compare);
  const validPositions = flatPositions.filter(position => position.price > 0);

  return validPositions[0];
}
