import { useState } from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Checkbox, Input } from 'antd';
import { Categories } from './Categories';
import { Layout } from '../../components/Layout';
import { useApi } from '../../hooks/useApi';

export type SearchResultProduct = {
  productName: string;
  positionName: string;
  productId: string;
  positionId: string;
  favorite: boolean;
  name: string;
};

export type SearchResults = {
  hints: string[];
  suggests: string[];
  products: SearchResultProduct[];
  total: number;
};

export const DemoSearch = () => {
  const api = useApi();
  const [value, setValue] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  const { data, isLoading, refetch } = useQuery<SearchResults>({
    queryKey: ['search', value, categories, onlyFavorites],
    queryFn: async () => {
      return api.searchText(value, categories, onlyFavorites);
    },
  });

  // TODO: Use for favorite products
  useQuery<SearchResults>({
    queryKey: ['getFavoriteProducts'],
    queryFn: async () => {
      return api.getFavoriteProducts();
    },
  });

  const { mutateAsync: removeFavoriteProduct } = useMutation({
    mutationKey: ['favorites'],
    mutationFn: async (positionId: string) => {
      return api.removeFavoriteProduct(positionId);
    },
  });

  const { mutateAsync: addFavoriteProduct } = useMutation({
    mutationKey: ['favorites'],
    mutationFn: async (positionId: string) => {
      return api.addFavoriteProduct(positionId);
    },
  });

  const suggests = data ? data.suggests : [];

  return (
    <Layout>
      <div className="pt-10 w-full">
        <div className="flex justify-end mb-2">
          <Checkbox
            value={onlyFavorites}
            onChange={event => setOnlyFavorites(event.target.checked)}
          >
            Только избранные
          </Checkbox>
        </div>
        <Input
          size="large"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <Categories onSelect={values => setCategories(values)} />
        {isLoading && 'Загрузка...'}
        {suggests.length > 0 && (
          <div className="flex gap-1 mt-3 flex-wrap">
            {suggests.map(suggest => (
              <div
                className="bg-blue-100 text-blue-500 p-1 px-2 rounded-lg cursor-pointer"
                onClick={() => setValue(suggest + ' ')}
              >
                {suggest.split(' ').at(-1)}
              </div>
            ))}
          </div>
        )}

        <div className="text-sm text-gray-500 flex justify-end mt-1">
          всего: {data && data.total}
        </div>
        {data &&
          data.products.map(product => (
            <div className="p-1 border-0 border-b border-gray-300 flex justify-between">
              <div className="w-[600px]">
                <div>{product.positionName}</div>
                <div className="text-xs text-gray-500">
                  {product.productName}
                </div>
              </div>
              <div className="cursor-pointer">
                {product.favorite ? (
                  <StarFilled
                    style={{
                      color: '#fadb14',
                    }}
                    onClick={async () => {
                      await removeFavoriteProduct(product.positionId);
                      refetch();
                    }}
                  />
                ) : (
                  <StarOutlined
                    style={{
                      color: 'rgba(0, 0, 0, 0.06)',
                    }}
                    onClick={async () => {
                      await addFavoriteProduct(product.positionId);
                      refetch();
                    }}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
    </Layout>
  );
};
