import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '../../../components/Modal';
import { Button, Checkbox, Input } from 'react-daisyui';
import { IAddress, IPayActor } from '../../../types';
import { useApi } from '../../../hooks/useApi';
import BeatLoader from 'react-spinners/BeatLoader';
import { useBudgetDetails } from '../../../hooks/useBudgetDetails';
import { useParams } from 'react-router';
import { useAccount } from '../../../hooks/useAccount';
import { useNavigate } from 'react-router-dom';

interface Props {
  onClose: VoidFunction;
}

export const CheckoutModal = ({ onClose }: Props) => {
  const [paymentMethod, setPaymentMethod] = useState(IPayActor.INDIVIDUAL);
  const [suggestedAddresses, setSuggestedAddresses] = useState<IAddress[]>([]);
  const [address, setAddress] = useState<Partial<IAddress>>(null!);
  const api = useApi();
  const [valid, setValid] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [step, setStep] = useState(2);
  const [mapLink, setMapLink] = useState('');
  const { budgetId } = useParams();
  const budgetDetails = useBudgetDetails(budgetId!);
  const buttonRef = useRef<any>();
  const account = useAccount();
  const navigate = useNavigate();

  useEffect(() => {
    api.autoCompleteCity(address?.name || '').then(setSuggestedAddresses);
  }, [address]);

  const onChooseAddress = (address: IAddress) => {
    setAddress(address);
    if (address.isFull) {
      setValid(true);
    }
  };

  const onSubmit = async () => {
    if (budgetDetails.data) {
      api.optimizeBudget(budgetId!, budgetDetails.data);
      navigate('analytics');
    }
    // setStep(2);
    // if (address?.name) {
    //   // @ts-ignore
    //   await calculateAddress(address);
    // }
  };

  const onMapDone = () => alert('ok');

  // @ts-ignore
  // useEffect(() => {
  //   if (budgetDetails.data) {
  //     api.optimizeWeb(budgetDetails.data.products).then(data => {
  //       const win = window.open("", "_blank", "");
  //       win!.document.body.innerHTML = data;
  //       onClose()
  //     })
  //   }
  // }, [budgetDetails.data])

  buttonRef.current?.click();

  if (step === 2)
    return (
      <Modal>
        <BeatLoader />
        <form
          style={{ display: 'none' }}
          method="POST"
          id="test_data"
          action={
            'https://ai-tech.app/SS/hs/supply_web/prices/estimate?id=' +
            account?.data?.id
          }
          encType="text/plain"
        >
          <input
            type={'hidden'}
            name="data"
            value={JSON.stringify({
              data: [
                ...budgetDetails!.data!.products.map(p => ({
                  productId: p.productId,
                  count: p.count * p.koef,
                })),
              ],
            })}
          />
          <button ref={buttonRef} id="test">
            test
          </button>
        </form>
      </Modal>
    );

  return (
    <Modal>
      <div className={'flex flex-col gap-5 w-80'}>
        <p>Доставка</p>
        <Input
          placeholder={'Куда доставить?'}
          value={address?.name}
          onInput={e => {
            setAddress({ name: e.currentTarget.value });
            setValid(false);
          }}
        />
        <div>
          {!valid &&
            suggestedAddresses.map(address => (
              <Button
                variant={'link'}
                className={'text-black text-sm text-center w-full'}
                onClick={() => onChooseAddress(address)}
              >
                {address.name}
              </Button>
            ))}
        </div>
        <Input
          onInput={e => setDeliveryDate(e.currentTarget.value)}
          placeholder={'Когда доставить?'}
        />
        <hr />
        <p>Способ оплаты</p>
        <div className={'flex flex-row gap-5'}>
          <Checkbox
            checked={paymentMethod === IPayActor.INDIVIDUAL}
            onClick={() => setPaymentMethod(IPayActor.INDIVIDUAL)}
          />
          <p>Оплата картой</p>
        </div>
        <div className={'flex flex-row gap-5'}>
          <Checkbox
            checked={paymentMethod === IPayActor.LEGAL}
            onClick={() => setPaymentMethod(IPayActor.LEGAL)}
          />
          <p>Оплата как юр лицо</p>
        </div>
        <hr />
        <Button disabled={!(deliveryDate && valid)} onClick={onSubmit}>
          Подтвердить
        </Button>
        <Button variant={'outline'} onClick={onClose}>
          Отмена
        </Button>
      </div>
    </Modal>
  );
};
