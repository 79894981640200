import { type FC, useMemo } from 'react';
import cx from 'classnames';
import _truncate from 'lodash/truncate';
import { Button } from 'react-daisyui';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useAccount } from '../hooks/useAccount';
import { useApi } from '../hooks/useApi';
import { useRemoteConfig } from '../utils/firebase';
import { appVersion } from '../version';

const items = [
  {
    key: '/orders',
    label: 'Заявки',
  },
  {
    key: '/budgets',
    label: 'Сметы',
  },
  {
    key: '/reports',
    label: 'Отчеты',
  },
  {
    key: '/objects',
    label: 'Объекты',
  },
  {
    key: '/settings',
    label: 'Организация',
  },
  {
    key: '/documents',
    label: 'Реестр счетов',
  },
];

export const Header: FC = () => {
  const { data } = useAccount();
  const api = useApi();
  const remoteConfig = useRemoteConfig();

  const location = useLocation();

  const selectedTab = useMemo(
    () =>
      items.find(item =>
        location.pathname.startsWith(item.key.slice(0, item.key.length - 2)),
      ),
    [location.pathname],
  );

  return (
    <div className="w-full p-3 flex flex-row justify-between items-center bg-white">
      <NavLink className="flex flex-row gap-2 w-[15rem]" to="/">
        <p className="text-2xl">StroySet</p>
        <p className="text-sm opacity-50">
          v{appVersion}, config v{remoteConfig?.config_version?.asString()}
        </p>
      </NavLink>

      <div className="h-full flex flex-row gap-5 items-center">
        {items.map(({ key, label }) => (
          <Link key={key} to={key}>
            <p
              className={cx('cursor-pointer hover:text-blue-500 transition', {
                'text-blue-500': key === selectedTab?.key,
              })}
            >
              {label}
            </p>
          </Link>
        ))}
      </div>

      {data?.name && (
        <div className="flex flex-row justify-end gap-5 items-center w-[20rem]">
          <div className="flex flex-col justify-center items-center">
            {/* TODO */}
            <p>{_truncate(data?.name, { length: 25 })}</p>
            <p className="opacity-50">{data?.id}</p>
          </div>

          <Button onClick={api.logout} variant="outline">
            Выйти
          </Button>
        </div>
      )}
    </div>
  );
};
