import { useState } from 'react';
import { useSearch } from '../../../hooks/useSearch';
import { SearchItem } from './SearchItem';
import { type IMarketProduct } from '../../../types';
import { Input, Flex } from 'antd';

interface Props {
  onAdd: (product: IMarketProduct) => void;
  onLoaded: VoidFunction;
}

export const Search = ({ onAdd, onLoaded }: Props) => {
  const [query, setQuery] = useState('');
  const search = useSearch(query, '');

  const renderSearchList = () => {
    if (!query) return null;

    return search?.data?.map(item => (
      <SearchItem query={query} onClick={() => onAdd(item)} {...item} />
    ));
  };

  if (search?.data?.length) onLoaded();

  return (
    <Flex
      vertical
      style={{
        marginTop: 10,
        gap: 10,
      }}
    >
      <Input.Search
        size="large"
        placeholder="Начните вводить для поиска материалов"
        loading={search.isLoading}
        value={query}
        onInput={e => setQuery(e.currentTarget.value)}
        enterButton="Искать"
      />

      {!!query?.length && !search.isLoading && !search.data?.length && (
        <div>
          <p className="text-center opacity-50">
            По вашему запросу ничего не нашлось
          </p>
        </div>
      )}

      {renderSearchList()}
    </Flex>
  );
};
