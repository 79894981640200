import { notification, Progress } from 'antd';
import { useFileMatching } from '../../../hooks/useUpload';
import { useEffect, useState } from 'react';
import FakeProgress from 'fake-progress';

const NOTIF_KEY = 'loadingNotifications';

export const useUploadFileWithoutMatching = ({
  reload,
}: {
  reload: VoidFunction;
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadedAll, setUploadedAll] = useState(0);
  const [uploaded, setUploaded] = useState(0);
  const upload = useFileMatching();
  const [fakeProgress, setFakeProgress] = useState(
    new FakeProgress({
      timeConstant: 20000,
    }),
  );

  const resetProgress = () => {
    setUploadedAll(0);
    setUploaded(0);
    setFakeProgress(
      new FakeProgress({
        timeConstant: 20000,
      }),
    );
  };

  useEffect(() => {
    let intervalId;

    if (isLoading) {
      fakeProgress.start();

      intervalId = setInterval(() => {
        setProgress(Number((fakeProgress.progress * 100).toFixed(1)));
      }, 1000);

      notification.open({
        key: NOTIF_KEY,
        message: 'Идет загрузка счетов...',
        placement: 'bottomRight',
        type: 'info',
        duration: null,
        closeIcon: null,
        role: 'status',
        description: (
          <Progress key="progress" status="active" percent={progress} />
        ),
      });

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }

    if (!isLoading && uploadedAll > 0 && uploaded === uploadedAll) {
      setTimeout(() => {
        reload();

        notification.open({
          key: NOTIF_KEY,
          message: 'Все счета загружены!',
          placement: 'bottomRight',
          type: 'success',
          duration: 5,
          closeIcon: null,
          role: 'status',
          description: (
            <Progress key="progress" status="active" percent={100} />
          ),
        });
      }, 1500);

      resetProgress();
    }
  }, [fakeProgress, isLoading, progress, reload, uploaded, uploadedAll]);

  useEffect(() => {
    if (uploaded === uploadedAll && isLoading && uploadedAll > 0) {
      setIsLoading(false);
    }
  }, [isLoading, uploaded, uploadedAll]);

  async function uploadFiles(files: any[]) {
    setUploadedAll(files.length);
    setIsLoading(true);

    const calc = async (file: any) => {
      const status = await upload.upload(file, true);

      if (
        status?.status === 201 ||
        status?.status === 204 ||
        status?.status === 200
      ) {
        setUploaded(p => p + 1);
        return;
      }

      notification.open({
        key: NOTIF_KEY,
        message: 'При загрузке произошла ошибка.',
        placement: 'bottomRight',
        type: 'error',
        duration: 15,
        closeIcon: null,
        role: 'status',
      });

      setIsLoading(false);
      resetProgress();
      reload();
    };

    Array.from(files).forEach(async file => {
      await calc(file);
    });
  }

  return {
    uploadFiles,
  };
};
