import { useEffect, useState } from 'react';
import { Button, Input, Select } from 'react-daisyui';
import { useForm } from 'react-hook-form';
import type { IBudget, IObject } from '../../../types';
import { useBudgets } from '../../../hooks/useBudgets';
import { useObjects } from '../../../hooks/useObjects';
import { useParams } from 'react-router';

interface Props {
  onSubmit: (data: { name: string; budget: string; object: string }) => void;
}

export const Step1 = ({ onSubmit }: Props) => {
  const { budgetId: defaultBudget, objectId: defaultObject } = useParams();

  const { handleSubmit, register, setValue } = useForm<{
    name: string;
    budget: string;
    object: string;
  }>();
  const objects = useObjects();
  const [object, setObject] = useState<string | undefined>();
  const [budget, setBudget] = useState<string | undefined>();
  const budgets = useBudgets(object || '');

  useEffect(() => {
    setBudget(defaultBudget);
    setObject(defaultObject);
    if (defaultObject) setValue('object', defaultObject);
    if (defaultBudget) setValue('budget', defaultBudget);
  }, []);

  return (
    <form className="flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
      {!defaultObject && (
        <>
          <p>Объект</p>
          <Select
            {...register('object')}
            autoFocus
            onChange={e => setObject(e.currentTarget.value)}
          >
            <option value="">Без объекта</option>
            <>
              {objects.data
                ?.filter(({ name }) => name !== 'Без объекта')
                .map((o: IObject) => <option value={o.id}>{o.name}</option>) ||
                []}
            </>
          </Select>
        </>
      )}

      {!defaultBudget && (
        <>
          <p>Смета</p>
          <Select
            {...register('budget')}
            autoFocus
            onChange={e => setBudget(e.currentTarget.value)}
          >
            <option value="">Без сметы</option>
            <>
              {budgets.data?.map((b: IBudget) => (
                <option value={b.id}>{b.name}</option>
              )) || []}
            </>
          </Select>
        </>
      )}

      <p>Название</p>

      <Input
        {...register('name', { required: true })}
        placeholder="Название"
        autoFocus
      />

      <Button type="submit">Далее</Button>
    </form>
  );
};
