import {
  type FC,
  type ReactNode,
  useState,
  useCallback,
  useEffect,
  memo,
} from 'react';
import { Button, Input, Modal } from 'antd';

export interface CountModalResponse {
  productId: string;
  count: number;
  unit: string;
  koef: number;
}

interface UnitData {
  koef: number;
  name: string;
}
interface Props {
  onClose: VoidFunction;
  productId: string;
  productName: ReactNode;
  defaultCount?: number;
  defaultUnit: UnitData;
  units: UnitData[];
  onSubmit: (data: CountModalResponse) => void;
  isEditing?: boolean;
  renderChangePosition?: () => ReactNode;
  onDelete?: VoidFunction;
}

export const CountModal: FC<Props> = memo(
  ({
    onClose,
    productId,
    productName,
    defaultCount,
    defaultUnit,
    units,
    onSubmit,
    isEditing,
    renderChangePosition = () => <></>,
    onDelete,
  }) => {
    const [unitData, setUnitData] = useState<UnitData>(defaultUnit);
    const [count, setCount] = useState(defaultCount ?? '1');

    useEffect(() => {
      if (defaultUnit) {
        setUnitData(defaultUnit);
      }
    }, [defaultUnit]);

    useEffect(() => {
      if (defaultCount && defaultUnit) {
        setCount(Math.round(Number(defaultCount) * defaultUnit.koef));
      }
    }, [defaultCount, defaultUnit]);

    const onSave = useCallback(() => {
      onSubmit({
        productId,
        count: Number(count) / unitData.koef,
        koef: unitData.koef,
        unit: unitData.name,
      });
    }, [unitData.name, unitData.koef, onSubmit, productId, count]);

    return (
      <Modal open title={productName} onCancel={onClose} footer={false}>
        <div className="flex flex-col gap-5 mt-5">
          <Input
            value={count}
            onInput={e => setCount(e.currentTarget.value)}
            placeholder="Количество"
          />

          <div className="flex flex-row gap-1 flex-wrap">
            {units.map(data => (
              <Button
                key={data.name}
                onClick={() => setUnitData(data)}
                type={unitData?.name === data.name ? 'primary' : undefined}
                style={{
                  background:
                    unitData?.name === data.name ? '#000 !important' : '',
                }}
              >
                {data.name}
              </Button>
            ))}
          </div>

          <div className="flex flex-col gap-2">
            <Button onClick={onSave}>
              {isEditing ? 'Изменить' : 'Добавить'}
            </Button>

            {isEditing && (
              <Button
                danger
                type="primary"
                style={{ backgroundColor: 'red !important' }}
                onClick={onDelete}
              >
                Удалить
              </Button>
            )}
          </div>
          {renderChangePosition()}
        </div>
      </Modal>
    );
  },
);
