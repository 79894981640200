import { useState, type FC, useCallback } from 'react';
import { Typography, Flex, AutoComplete } from 'antd';
import { useAddress } from '../../../../hooks/useAddress';
import { useApi } from 'hooks/useApi';

import styles from './styles.module.css';

const { Title } = Typography;

interface Props {
  id: string;
  AddressInObject?: string;
}

export const SettingsTabPanel: FC<Props> = ({ id, AddressInObject }) => {
  const api = useApi();
  const [address, setAddress] = useState<string>(AddressInObject);
  const { data: addressOptions, isLoading } = useAddress(address || '');

  const onAddressChange = useCallback(value => {
    setAddress(value);
  }, []);

  const onAddressSelect = useCallback(
    async (_, value) => {
      if (!id) {
        throw new Error('There is no object id!');
      }

      await api.updateObjectAddress(id, value);
    },
    [api, id],
  );

  return (
    <div className={styles.tabPanelWrapper}>
      <Flex vertical>
        <Title level={5} style={{ marginBottom: 5 }}>
          Адрес доставки
        </Title>

        <AutoComplete
          value={address}
          options={addressOptions}
          onSelect={onAddressSelect}
          onChange={onAddressChange}
          placeholder="Укажите адрес доставки"
          loading={isLoading}
        />
      </Flex>
    </div>
  );
};
