import { useQuery } from '@tanstack/react-query';
import { IAccount } from 'types';
import { useApi } from './useApi';
import { setUser } from '../utils/firebase';

export const useAccount = () => {
  const api = useApi();
  return useQuery<IAccount>(['account'], async () => {
    const data = await api.getUser();
    setUser(data);
    return data;
  });
};
