import { money } from '../../../utils/moneyTool';
import { IAnalytics, IAnalyticsPosition } from '../../../types';

export const useAnalyticsSettingsTable = (
  productId: string,
  analytics?: IAnalytics,
): IAnalyticsPosition[] => {
  if (!analytics?.providers || !analytics.products) return [];

  // @ts-ignore
  return analytics.products
    .find(product => product.id === productId)
    ?.positions.map((position, index) => {
      const providers = {} as Record<
        string,
        {
          defaultPrice: number;
          currentPrice: number;
          retailPrice: number;
          personalPrice: number;
          count: number;
          koef: number;
          unit: string;
          baseUnit: string;
          personalPricesHistory: { date: string; price: number }[];
        }
      >;
      const product = analytics.products.find(
        product => product.id === productId,
      );

      position.prices.forEach((price, index) => {
        providers[analytics.providers[index].id] = {
          defaultPrice: price,
          currentPrice: position.currentPrices[index],
          personalPrice: position.personalPrices[index],
          retailPrice: position.retailPrices[index],
          personalPricesHistory: position.personalPricesHistory[index],
          koef: position.koef,
          baseUnit: product?.unit || 'кг',
          unit: position?.unit || 'шт',
          count: position.count,
        };
      });

      return {
        name: position.name,
        count: position.count,
        id: position.id,
        total: `${money(position.minPrice * 1)} - ${money(
          position.maxPrice * 1,
        )}`,
        unit: position?.unit || 'шт',
        price: position.prices[index],
        minPrice: position?.minPrice * 1,
        maxPrice: position?.maxPrice * 1,
        ...providers,
      };
    });
};
