import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';

export const usePositionProperties = (positionId: string, open: boolean) => {
  const api = useApi();
  return useQuery<{ name: string; value: string }[]>(
    ['position-property', positionId],
    () => api.getPositionProperties(positionId),
    {
      enabled: open && !!positionId,
    },
  );
};
