import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';
import type { IAnalyticsProduct } from '../types';

type Paginated<T> = {
  items: T[];
  meta: {
    page: number;
    totalPages: number;
    totalItems: number;
    pageSize: number;
  };
};

export const useProducts = (query: string, page: number) => {
  const api = useApi();
  return useQuery<Paginated<IAnalyticsProduct>>(
    ['products', query, page],
    () => api.getProducts(query, page),
    {
      keepPreviousData: true,
    },
  );
};
