import { type FC, memo } from 'react';
import { Table } from 'antd';
import { money } from '../../../../utils/moneyTool';
import { useParams } from 'react-router';
import { useOrderProducts } from '../../hooks/useOrderProducts';

export const ProductsTable: FC = memo(() => {
  const { orderId } = useParams();
  const report = useOrderProducts(orderId || '');

  const data = report.data!.map(p => ({
    ...p,
    productName: p.productName || '-',
    count: !p.count ? '-' : `${p.count} ${p.unit}`,
    minTotalPrice: p.minTotalPrice ? money(p.minTotalPrice) : '-',
    maxTotalPrice: p.maxTotalPrice ? money(p.maxTotalPrice) : '-',
  }));

  const columns = [
    {
      title: 'Наименование в документе',
      dataIndex: 'query',
      key: 'query',
    },
    {
      title: 'Товар в системе',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Минимальная сумма',
      dataIndex: 'minTotalPrice',
      key: 'minTotalPrice',
    },
    {
      title: 'Максимальная сумма',
      dataIndex: 'maxTotalPrice',
      key: 'maxTotalPrice',
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      locale={{ emptyText: 'Нет данных' }}
    />
  );
});
