import { SettingsTable } from './SettingsTable';
import { useParams } from 'react-router';
import { useAnalytics } from '../../../hooks/useAnalytics';
import BeatLoader from 'react-spinners/BeatLoader';
import { Button, Modal } from 'react-daisyui';
import { useState } from 'react';
import { ReportTable } from './ReportTable';
import { useAnalyticsSettingsTable } from '../hooks/useAnalyticsSettingsTable';
import React from 'react';

export const ReportPage = () => {
  const { budgetId } = useParams();
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');

  const analytics = useAnalytics(budgetId || '');
  const analyticsSettingsTable = useAnalyticsSettingsTable(
    selectedProductId,
    analytics.data,
  );

  const onDetails = (productId: string) => {
    const index = analytics.data?.products.findIndex(p => p?.id === productId);
    setModalOpened(true);
    setSelectedProductId(productId);
  };

  if (!analytics.data?.products?.length) return <BeatLoader />;

  return (
    <div>
      <ReportTable onDetails={onDetails} products={analytics.data.market} />
      {modalOpened && (
        <Modal open style={{ minWidth: '80vw' }}>
          <div className={'flex flex-col gap-5'}>
            <SettingsTable
              positions={analyticsSettingsTable}
              providers={analytics.data.providers}
            />
            <Button onClick={() => setModalOpened(false)}>
              Сохранить и закрыть
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};
