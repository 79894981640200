import { useQuery } from '@tanstack/react-query';
import { IBudget } from 'types';
import { useApi } from './useApi';

export const useBudgets = (objectId?: string) => {
  const api = useApi();
  return useQuery<IBudget[]>(
    ['budgets', objectId],
    () => api.getBudgets(objectId),
    {},
  );
};
