import { useQuery } from '@tanstack/react-query';
import { type IObject } from 'types';
import { useApi } from './useApi';
import { useErrorBoundary } from 'react-error-boundary';

export const useObject = (id: string) => {
  const boundary = useErrorBoundary();
  const api = useApi();

  return useQuery<IObject>(['object', id], () => api.getObject(id), {
    onError: () => boundary.showBoundary('object 404'),
    enabled: !!id,
  });
};
