import { createContext, PropsWithChildren, useContext, useState } from 'react';

type IContext = {
  currentPrices: boolean;
  personalPrices: boolean;
};

const Context = createContext<{
  value: IContext;
  toggle: any;
}>(
  {} as {
    value: IContext;
    toggle: any;
  },
);

export const AnalyticsTableFiltersProvider = ({
  children,
}: PropsWithChildren) => {
  const [value, setValue] = useState<IContext>({
    currentPrices: true,
    personalPrices: true,
  });

  const toggle = (name: keyof typeof value) =>
    setValue(prev => ({ ...prev, [name]: !prev[name] }));

  return (
    <Context.Provider value={{ value, toggle }}>{children}</Context.Provider>
  );
};

export const useAnalyticsTableFilters = () => useContext(Context);
