import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

type IContext = Record<string, 'default' | 'retail'>;

const OptPricesContext = createContext<{ value: IContext; setValue: any }>({
  value: {},
  setValue: () => null,
});
export const OptPricesProvider = ({ children }: PropsWithChildren) => {
  const [value, setValue] = useState<IContext>(
    JSON.parse(localStorage.getItem('stroyset-ban-views-v2') || '{}'),
  );

  useEffect(() => {
    if (Object.keys(value))
      localStorage.setItem('stroyset-ban-views-v2', JSON.stringify(value));
  }, [value]);

  return (
    <OptPricesContext.Provider value={{ value, setValue }}>
      {children}
    </OptPricesContext.Provider>
  );
};

export const useOptPrices = (budgetId: string) => {
  const { value, setValue } = useContext(OptPricesContext);

  const setOptPrice = (val: string) => {
    setValue((prev: any) => ({ ...prev, [budgetId]: val }));
  };

  return { optPrice: value?.[budgetId] || 'default', setOptPrice };
};
