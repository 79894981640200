import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';
import { IProvider } from '../types';

interface IExcludedPosition {
  positionId: string;
}

const mockProviders = [
  {
    id: '66b732f1-32a9-11ed-b46c-a8a159a29aa0',
    name: 'Теплит',
  },
  {
    id: 'ea4bd31d-32a8-11ed-b46c-a8a159a29aa0',
    name: 'Первый Стройцентр Сатурн-Р',
  },
  {
    id: '95c3d9e5-bd93-11ed-b479-a8a159a29aa0',
    name: '47.РУ',
  },
  {
    id: '4f035c8e-a7e7-11ed-b479-a8a159a29aa0',
    name: 'Электропартнер',
  },
  {
    id: '6cceb5fe-2c71-11ed-b46c-a8a159a29aa0',
    name: 'Лидер-Сити ООО',
  },
  {
    id: '203d38ec-f3f6-11ed-b47c-a8a159a29aa0',
    name: 'ООО Торговая Компания "ИВДИС',
  },
  {
    id: 'ef1e340e-bb2e-11ed-b479-a8a159a29aa0',
    name: 'САНТЕХКОМПЛЕКТ',
  },
  {
    id: '4c80f037-925d-11ec-9117-d6c30619e916',
    name: 'Леруа Мерлен',
  },
  {
    id: '4ad9e333-342c-11ed-b46c-a8a159a29aa0',
    name: 'ГЛАВКОМПЛЕКТ ООО',
  },
  {
    id: '1d6fd9e6-9705-11e9-90c4-a2ca425b3a8b',
    name: 'УралИнтерьер',
  },
  {
    id: 'daf0239f-b68b-11ed-b479-a8a159a29aa0',
    name: 'Ампер ООО',
  },
  {
    id: 'cd5c07ff-85d2-11ed-b476-a8a159a29aa0',
    name: 'ТД «Пораблок»',
  },
  {
    id: '3132513b-358d-11ed-b46d-a8a159a29aa0',
    name: 'Строй Микс ГК ООО',
  },
  {
    id: '108e507b-2c53-11e9-90ab-8178ee69f586',
    name: 'СТРОИТЕЛЬНЫЙ ДВОР',
  },
  {
    id: '30e2bcd9-be5f-11ed-b479-a8a159a29aa0',
    name: 'Некрасова Татьяна Васильевна ИП',
  },
  {
    id: '26d0a5c1-32a8-11ed-b46c-a8a159a29aa0',
    name: 'УВМ-СТАЛЬ',
  },
  {
    id: '05d3d753-6a8b-11ed-b475-a8a159a29aa0',
    name: 'Тихо74 ООО',
  },
  {
    id: 'ad51b120-337d-11ed-b46c-a8a159a29aa0',
    name: 'ТСК Альянс ООО',
  },
  {
    id: 'cfc423f5-a0a7-11ed-b479-a8a159a29aa0',
    name: '«Моттекс» ООО',
  },
  {
    id: '98959b00-44ac-11ed-b470-a8a159a29aa0',
    name: 'ТД Квартал',
  },
  {
    id: '108e507e-2c53-11e9-90ab-8178ee69f586',
    name: 'СТРОЙБАЗА №1',
  },
  {
    id: 'e2a3edb0-bf49-11ed-b479-a8a159a29aa0',
    name: '«Интеллект» ООО',
  },
  {
    id: '8a1abb72-bf48-11ed-b479-a8a159a29aa0',
    name: 'ИП Производитель [песок]',
  },
  {
    id: '4d94a360-33eb-11ed-b46c-a8a159a29aa0',
    name: 'ЭНЕРГОПРОМ ООО',
  },
  {
    id: 'a645b399-f287-11ed-b47b-a8a159a29aa0',
    name: 'Мапэй',
  },
  {
    id: '223c93c7-a7e6-11ed-b479-a8a159a29aa0',
    name: 'Теплоотдача',
  },
  {
    id: 'cd6a824b-3433-11ed-b46c-a8a159a29aa0',
    name: 'СтройПлатформа ООО',
  },
  {
    id: '482dc3ef-ece6-11ed-b47a-a8a159a29aa0',
    name: 'КАЛИНА МАСТЕР',
  },
  {
    id: '21a23270-618f-11ed-b475-a8a159a29aa0',
    name: 'от Производителя',
  },
  {
    id: '5d51b805-339b-11ed-b46c-a8a159a29aa0',
    name: 'РОС ЭЛЕКТРО ',
  },
  {
    id: '749af4c6-dd87-11eb-91cc-d8d385af589e',
    name: 'САТУРН УРАЛ ООО ЧЛБ',
  },
];

export const useProviders = () => {
  const api = useApi();
  return useQuery<IProvider[]>(['providers'], () => mockProviders, {
    keepPreviousData: true,
  });
};
