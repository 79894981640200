import { Button } from 'antd';
import { type FC } from 'react';
import { PriceBadge } from '../../../Analytics/components/PriceBadge';

interface Props {
  total: number;
  onCart: VoidFunction;
}

export const Total: FC<Props> = ({
  total,
  onCart,
}: {
  total: number;
  onCart: VoidFunction;
}) => {
  return (
    <div className="flex flex-col justify-center gap-5">
      <PriceBadge price={total} />

      <Button onClick={onCart}>Корзина</Button>
    </div>
  );
};
