import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

interface ContextType {
  settings: any;
  setSettings: any;
}
export const SettingsContext = createContext<ContextType>({} as ContextType);

export const SettingsProvider = ({ children }: PropsWithChildren) => {
  const cache = localStorage.getItem('stroyset-settings');
  const [settings, setSettings] = useState(
    cache
      ? JSON.parse(cache)
      : {
          personalPrices: true,
        },
  );

  useEffect(() => {
    localStorage.setItem('stroyset-settings', JSON.stringify(settings));
  }, [settings]);

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
