import { useCallback, useEffect, useState } from 'react';
import { Modal, Input, Flex } from 'antd';

interface Props {
  title: string;
  onClose: VoidFunction;
  open: boolean;
  onSubmit: (name: string) => void;
  defaultValue?: string;
}

export const RenameModal = ({
  title,
  onClose,
  open,
  onSubmit,
  defaultValue,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>(defaultValue ?? '');

  useEffect(() => {
    if (open) {
      setLoading(false);
      setName(defaultValue);
    } else {
      setLoading(false);
    }
  }, [open, defaultValue]);

  const onChange = useCallback(e => {
    setName(e.target.value);
  }, []);

  const onSave = useCallback(() => {
    onSubmit(name);
    setLoading(true);
  }, [name, onSubmit]);

  return (
    <Modal
      title={title}
      okText="Сохранить"
      confirmLoading={loading}
      open={open}
      onOk={onSave}
      onCancel={onClose}
    >
      <form
        style={{
          padding: '15px 0px',
        }}
      >
        <Flex vertical gap={5}>
          <Input
            placeholder="Введите новое имя"
            value={name}
            onChange={onChange}
          />
        </Flex>
      </form>
    </Modal>
  );
};
