import React, { PropsWithChildren } from 'react';

interface Props {
  onClose?: VoidFunction;
}

export const Modal = ({ children, onClose }: PropsWithChildren<Props>) => {
  return (
    <div
      className={
        'absolute top-0 w-full h-full flex flex-1 justify-center items-center'
      }
      style={{ background: 'rgba(0,0,0,0.5)' }}
    >
      <div className={'p-5 rounded-2xl bg-white shadow-2xl'}>{children}</div>
    </div>
  );
};
