import { useState } from 'react';
import { Button } from 'react-daisyui';
import { useParams } from 'react-router';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { ProvidersModal } from '../../../components/ProvidersModal';
import { CreateOrderModal } from '../../../components/CreateOrderModal';
import { useAnalyticsView } from '../hooks/useAnalyticsView';
import { BudgetReportModal } from '../../../components/BudgetReportModal';
import { useSearchParams } from 'react-router-dom';
import { PersonalProductItem } from './PersonalProductItem';

export const SettingsTab = () => {
  const [_, setSearchParams] = useSearchParams();
  const view = useAnalyticsView();
  const [reportModalOpened, setReportModalOpened] = useState(false);
  const [createOrderModalOpened, setCreateOrderModalOpened] = useState(false);
  const [providersModalOpened, setProvidersModalOpened] = useState(false);
  const { budgetId } = useParams();
  const { data } = useAnalytics(budgetId || '');

  return (
    <div>
      <div className={'flex flex-row justify-between mb-5 items-center'}>
        <p className={'text-lg'}>Прогноз</p>
        <div className={'flex flex-row gap-5'}>
          <Button
            onClick={() => setProvidersModalOpened(true)}
            variant={'outline'}
          >
            Изменить список поставщиков
          </Button>
          <Button onClick={() => setCreateOrderModalOpened(true)}>
            Создать заявку
          </Button>
        </div>
      </div>
      <div className={'flex flex-col gap-5'}>
        {data?.products.map(product => (
          <PersonalProductItem
            selectedPosition={data.market.find(e => e.productId === product.id)}
            providers={data?.providers}
            {...product}
          />
        ))}
      </div>
      <CreateOrderModal
        open={createOrderModalOpened}
        onClose={() => setCreateOrderModalOpened(false)}
      />
      <ProvidersModal
        onClose={() => setProvidersModalOpened(false)}
        open={providersModalOpened}
      />
      <BudgetReportModal
        onClose={() => setReportModalOpened(false)}
        open={reportModalOpened}
      />
    </div>
  );
};
