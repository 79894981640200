import { Button } from 'react-daisyui';
import { useState } from 'react';
import { useHealthcheck } from '../hooks/useHealthcheck';

export const Healthcheck = () => {
  const { isError } = useHealthcheck();
  const [hidden, setHidden] = useState(false);

  if (hidden || !isError) return null;

  return (
    <div className="w-full p-5 bg-yellow-100 flex flex-row justify-between items-center">
      <p className="text-yellow-600 font-bold">
        Ведутся технические работы - сервис недоступен
      </p>

      <Button onClick={() => setHidden(true)} variant="outline">
        Закрыть
      </Button>
    </div>
  );
};
