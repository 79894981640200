import { useCallback } from 'react';
import { useOrder } from '../../../hooks/useOrder';

export const useOrderPositions = (orderId: string) => {
  const order = useOrder(orderId);

  const getExcludedPositions = useCallback(
    (itemId: string) => {
      if (!order.data) return [];

      const product = order.data.ProductInOrder.find(
        item => item.itemId === itemId,
      );

      return product ? product.excludedPositions : [];
    },
    [order.data],
  );

  return {
    getExcludedPositions,
  };
};
