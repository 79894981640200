import type { ReactNode } from 'react';
import { memo } from 'react';
import { Popover } from 'antd';
import { money } from '../../../utils/moneyTool';
import type { IAnalytics, IAnalyticsRow } from '~/types';

type StroySetProductPopoverProps = {
  children: ReactNode;
  itemId: string;
  marketProduct: IAnalyticsRow;
  orderDetails: IAnalytics;
};

export const StroySetProductPopover = memo(
  ({
    children,
    itemId,
    marketProduct,
    orderDetails,
  }: StroySetProductPopoverProps) => {
    const product = orderDetails.products.find(p => p.itemId === itemId);
    const position = product.positions.find(
      p => p.id === marketProduct?.positionId,
    );

    return (
      <Popover
        content={
          <div className="p-2 flex flex-col gap-2">
            <div className="flex flex-row">
              <p className="bg-gray-100 rounded">Цена платформы</p>
            </div>

            <p className="opacity-50 text-xs">Поставщик номенклатуры</p>
            <p>{marketProduct.providerName}</p>
            <p className="opacity-50 text-xs">Отобранная номенклатура</p>
            <p>{marketProduct.positionName}</p>
            <p className="opacity-50 text-s">Количество</p>
            <p>{marketProduct.positionCount}</p>
            <p className="opacity-50 text-xs">Детализация цены</p>
            <div className="flex flex-row gap-2">
              <p>
                {money(marketProduct?.price || 0, marketProduct?.positionUnit)}
              </p>
              {marketProduct?.positionUnit === product.unit ? null : (
                <>
                  <p>или</p>
                  <p>
                    {money(
                      (marketProduct?.price ?? 0) * (position?.koef ?? 1),
                      marketProduct?.productUnit,
                    )}
                  </p>
                </>
              )}
            </div>
          </div>
        }
      >
        {children}
      </Popover>
    );
  },
);
