import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../hooks/useApi';

export const useAllUsers = () => {
  const api = useApi();
  return useQuery<{ name: string; id: string; phone: string }[]>(
    ['all-users'],
    () => api.getAllUsers(),
  );
};
