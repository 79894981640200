import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ru_RU';
import { Theme } from 'react-daisyui';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { AppPreloader } from './components/AppPreloader';
import { ErrorProvider } from './components/ErrorProvider';
import { Healthcheck } from './components/Healthcheck';
import { Router } from './components/Navigator';
import { YandexMetrika } from './components/YandexMetrika';
import { ApiContextProvider } from './hooks/useApi';
import { SettingsProvider } from './hooks/useSettings';
import { AnalyticsTableFiltersProvider } from './pages/Analytics/hooks/useAnalyticsTableFilters';
import { OptPricesProvider } from './pages/Analytics/hooks/useOptPrices';
import { FirebaseProvider } from './utils/firebase';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <ErrorProvider>
      <QueryClientProvider client={client}>
        <ConfigProvider locale={locale}>
          <ApiContextProvider>
            <FirebaseProvider>
              <OptPricesProvider>
                <SettingsProvider>
                  <AnalyticsTableFiltersProvider>
                    <Theme dataTheme="light">
                      <div
                        className="flex flex-col bg-gray-100 h-full"
                        style={{ height: '100vh' }}
                      >
                        <BrowserRouter>
                          <Healthcheck />
                          <Router />
                          <AppPreloader />
                          <YandexMetrika />
                          <Toaster position="top-right" reverseOrder={false} />
                        </BrowserRouter>
                      </div>
                    </Theme>
                  </AnalyticsTableFiltersProvider>
                </SettingsProvider>
              </OptPricesProvider>
            </FirebaseProvider>
          </ApiContextProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </ErrorProvider>
  </React.StrictMode>,
);
