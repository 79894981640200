import { Drawer, Table } from 'antd';
import { useState } from 'react';
import { usePositionProperties } from '../hooks/usePositionProperties';
import { LoadingScreen } from './LoadingScreen';

export const PositionTitle = ({ id, title }: { id: string; title: string }) => {
  const [open, setOpen] = useState(false);
  const properties = usePositionProperties(id, open);

  const columns = [
    {
      title: 'Свойство',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Значение',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <>
      <p onClick={() => setOpen(true)} className="cursor-pointer text-blue-500">
        {title}
      </p>

      <Drawer open={open} onClose={() => setOpen(false)} title={title}>
        {properties.data ? (
          <Table
            dataSource={properties.data}
            columns={columns}
            pagination={false}
            locale={{ emptyText: 'Нет данных' }}
          />
        ) : properties.isError ? (
          <p className="text-center">Ошибка при загрузке данных</p>
        ) : (
          <LoadingScreen />
        )}
      </Drawer>
    </>
  );
};
