import { useEffect, useRef, useState } from 'react';
import { DefaultVerifiedPositions } from 'pages/Settings/components/DefaultVerifiedPositions/DefaultVerifiedPositions';

interface Props {
  productId: string;
  name: string;
  positions: {
    id: string;
    name: string;
    verified: boolean;
  }[];
}

export const MarketProductItem = ({ productId, name, positions }: Props) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (opened)
      ref.current?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }, [opened]);

  return (
    <div ref={ref} className={'rounded-2xl border-2 border-gray-100'}>
      <div
        className={'flex flex-col cursor-pointer'}
        onClick={() => setOpened(prev => !prev)}
      >
        <div className={'flex flex-row items-center'}>
          <div className={'p-8 pr-5'}>
            <img
              alt={'toggle'}
              src={'/images/toggleArrow.svg'}
              style={{
                transform: opened ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: '0.5s',
              }}
            />
          </div>
          <div
            className={
              'flex flex-row justify-between items-center w-full pr-10'
            }
          >
            <p>{name}</p>
          </div>
        </div>
      </div>
      {opened && positions && (
        <DefaultVerifiedPositions productId={productId} positions={positions} />
      )}
    </div>
  );
};
