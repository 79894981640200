import React from 'react';
import { Table } from 'antd';
import { money } from '../../../utils/moneyTool';
import { IAnalyticsRow } from '../../../types';

interface Props {
  positions: IAnalyticsRow[];
  selectable?: boolean;
  onChange?: (rows: string[]) => void;
}

export const PriceTable = ({ positions, selectable, onChange }: Props) => {
  const data = positions.map(p => ({
    ...p,
    count: `${p.positionCount} ${p.positionUnit}`,
    price: money(p.price),
    totalPrice: money(p.totalPrice),
  }));

  const totalPrice = positions.reduce((acc, val) => acc + val.totalPrice, 0);

  const columns = [
    {
      title: 'Наименование материала',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Позиция',
      dataIndex: 'positionName',
      key: 'positionName',
    },
    {
      title: 'Поставщик',
      dataIndex: 'providerName',
      key: 'providerName',
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      width: 100,
    },
    {
      title: 'Сумма',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      width: 150,
    },
  ];

  return (
    <Table
      footer={() => (
        <div className={'flex flex-row justify-end gap-5'}>
          <p>ИТОГО</p>
          <p>{money(totalPrice)}</p>
        </div>
      )}
      rowKey={'positionId'}
      dataSource={data}
      columns={columns}
      pagination={false}
      locale={{ emptyText: 'Нет данных' }}
      rowSelection={
        selectable
          ? {
              defaultSelectedRowKeys: [],
              checkStrictly: true,
              onChange: rows =>
                onChange && onChange(rows.map(e => e.toString())),
            }
          : undefined
      }
    />
  );
};
