import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { useApi } from '../../hooks/useApi';

type CategoriesProps = {
  onSelect: (id: string[]) => void;
};

export const Categories = ({ onSelect }: CategoriesProps) => {
  const api = useApi();

  const { data } = useQuery<
    {
      id: string;
      name: string;
    }[]
  >({
    queryKey: ['categories'],
    queryFn: async () => {
      return api.getCategories();
    },
  });

  if (!data) {
    return null;
  }

  return (
    <div className="mt-2">
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Выбрать категорию"
        onChange={onSelect}
        options={data.map(option => ({
          label: option.name,
          value: option.id,
        }))}
      />
    </div>
  );
};
