import { useState } from 'react';
import { Button, Modal, Select } from 'react-daisyui';
import { useApi } from '../../../hooks/useApi';
import { useParams } from 'react-router';
import { useAllUsers } from '../hooks/useAllUsers';
import BeatLoader from 'react-spinners/BeatLoader';
import { useForm } from 'react-hook-form';

interface Props {
  onClose: VoidFunction;
}

export const AppExportModal = ({ onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm<{ userId: string }>();
  const users = useAllUsers();
  const api = useApi();
  const { budgetId } = useParams();
  const onSubmit = async ({ userId }: { userId: string }) => {
    try {
      setLoading(true);
      if (userId) {
        const { data, user } = await api.exportBudgetToApp(
          budgetId || '',
          userId,
        );
        setLoading(false);
        onClose();
        alert(
          `Заявка номер ${data.id
            .slice(data.id.length - 4, data.id.length)
            .toUpperCase()} был перенесен в аккаунт ${user.name}`,
        );
      }
    } catch {
      alert('Пользователь с таким id не найден');
      setLoading(false);
    }
  };

  const sortedUsers = users.data
    ?.map(
      ({ name, id, phone }) => `${name} ${!!phone ? '+7' : ''}${phone} (${id})`,
    )
    .sort();

  return (
    <Modal open>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {sortedUsers?.length ? (
            <Select {...register('userId', { required: true })}>
              {sortedUsers.map(text => (
                <option value={text.split('(')[1].split(')')[0]}>{text}</option>
              ))}
            </Select>
          ) : (
            <BeatLoader />
          )}
        </Modal.Body>
        <Modal.Actions>
          <Button loading={loading} disabled={!sortedUsers?.length}>
            Выгрузить в приложение
          </Button>
          <Button type={'button'} variant={'outline'} onClick={onClose}>
            Закрыть
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
