/* eslint-disable @typescript-eslint/return-await */
import { type AxiosResponse } from 'axios';
import { useApi } from './useApi';
import { useParams } from 'react-router';

export const useFileMatching = () => {
  const { budgetId } = useParams();
  const api = useApi();

  return {
    async choose(): Promise<any> {
      return new Promise((res, rej) => {
        const input = document.createElement('input');
        input.style.display = 'hidden';
        input.type = 'file';
        input.multiple = true;
        input.click();
        input.onchange = async e => {
          if (input.files![0]) {
            const files = input.files;
            res(files);
          } else {
            rej();
          }
        };
      });
    },
    async upload(file: any, withoutMatching?: boolean): Promise<AxiosResponse> {
      if (!file) {
        return;
      }

      if (withoutMatching) {
        return await api.uploadFileWithoutMatching({
          extension: file.name.split('.')[file.name.split('.').length - 1],
          file,
        });
      }

      return await api.uploadFile(budgetId!, {
        extension: file.name.split('.')[file.name.split('.').length - 1],
        file,
      });
    },
  };
};
