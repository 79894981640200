import { Button } from 'antd';
import React, { useState } from 'react';

export const UpdateNotificationBody = ({
  onClick,
}: {
  onClick: VoidFunction;
}) => {
  const [loading, setLoading] = useState(false);

  const onOptimize = async () => {
    setLoading(true);
    onClick();
  };

  return (
    <Button onClick={onOptimize} loading={loading}>
      Пересчитать
    </Button>
  );
};
