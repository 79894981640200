import type { IAnalyticsPosition, IAnalyticsProduct, IProvider } from 'types';

type BestPrices = Record<
  string,
  {
    productId: string;
    prices: number[];
    bestProvider: IProvider;
    bestProviderIndex: number;
  }
>;

export function getBestPrices(
  products: IAnalyticsProduct[],
  providers: IProvider[],
  isRetailPrice: boolean,
  excludedPositions: string[],
): BestPrices {
  const output = {};

  const mapPriceFn = (index: number) => (position: IAnalyticsPosition) =>
    isRetailPrice
      ? position.retailPrices[index] * position.count * position.koef
      : position.prices[index] * position.count * position.koef;

  products.forEach(product => {
    const bestPrices = providers.map((_, index) => {
      const validPrices = product.positions
        .filter(p => !excludedPositions.includes(p.id))
        .map(mapPriceFn(index))
        .filter(price => price > 0);

      return validPrices.length > 0 ? Math.min(...validPrices) : -1;
    });

    const validBestPrices = bestPrices.filter(price => price !== -1);

    const bestProviderIndex =
      validBestPrices.length > 0
        ? bestPrices.indexOf(Math.min(...validBestPrices))
        : -1;

    const bestProvider =
      bestProviderIndex !== -1 ? providers[bestProviderIndex] : null;

    output[product.itemId] = {
      itemId: product.itemId,
      productId: product.id,
      prices: bestPrices,
      bestProvider: bestProvider,
      bestProviderIndex: bestProviderIndex,
    };
  });

  return output;
}
