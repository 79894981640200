import { type FC } from 'react';
import { Button } from 'react-daisyui';
import { useParams } from 'react-router';
import { ProductsTable } from './ProductsTable';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../../hooks/useApi';
import BeatLoader from 'react-spinners/BeatLoader';
// import { useOrderDetails } from '../../../hooks/useOrderDetails';
import { useOrder } from '../../../../hooks/useOrder';
import { useOrderProducts } from '../../hooks/useOrderProducts';

export const ProductsTab: FC = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const order = useOrder(orderId || '');
  const report = useOrderProducts(orderId || '');
  const api = useApi();

  const onAddProduct = async () => {
    await api.deleteOrder(orderId || '');
    navigate(
      `/object/${order.data?.objectId}/budget/${order.data?.budgetId}?view=order`,
    );
  };

  const onDownloadReport = () =>
    api.downloadOrderReport(orderId || '', order.data?.name || '');

  return (
    <div>
      <div className="flex flex-row justify-between mb-5">
        <div className="flex flex-row gap-5">
          <Button variant="outline" onClick={onAddProduct}>
            Добавить позицию
          </Button>

          <Button onClick={onDownloadReport}>Выгрузить смету</Button>
        </div>
      </div>

      {report.data?.length ? <ProductsTable /> : <BeatLoader />}
    </div>
  );
};
