import React, { useState } from 'react';
import { Button } from 'react-daisyui';
import { Search } from './Search';
import { Modal } from '../../../components/Modal';
import { useApi } from '../../../hooks/useApi';
import type { IMarketProduct, IQuery } from '../../../types';

interface Props {
  onClose: VoidFunction;
  currentQuery: IQuery;
  onDeleteQuery: (q: string) => void;
  onResolve: (q: IQuery, p: IMarketProduct, method: 'MANUAL' | 'NEW') => void;
}

export const ManualMatchingModal = ({
  onClose,
  currentQuery,
  onDeleteQuery,
  onResolve,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const createNewProduct = async () => {
    setLoading(true);
    const p = await api.createNewProduct(
      currentQuery.query,
      currentQuery.queryPrice || 0,
      currentQuery?.queryProviderId,
    );
    onResolve(currentQuery, p, 'NEW');
    setLoading(false);
    onClose();
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full">
      <Modal>
        <p className="text-lg text-center mb-10">{currentQuery.query}</p>
        <div className="h-[30rem] w-[40rem] rounded-2xl overflow-y-auto bg-gray-100">
          <Search
            onAdd={p => {
              onResolve(currentQuery, p, 'MANUAL');
              onClose();
            }}
            onLoaded={() => null}
          />
        </div>
        <div className="flex flex-row gap-5 mt-10">
          <Button onClick={onClose} className="flex flex-1" variant="outline">
            Отмена
          </Button>
          <Button
            loading={loading}
            onClick={createNewProduct}
            variant="outline"
          >
            Условие отбора не найдено
          </Button>
          <Button
            onClick={() => {
              onDeleteQuery(currentQuery.query);
              onClose();
            }}
            variant="outline"
            className="text-red-500"
          >
            Удалить позицию
          </Button>
        </div>
      </Modal>
    </div>
  );
};
