import React, { useState } from 'react';
import { Button } from 'react-daisyui';
import { useAccount } from '../../hooks/useAccount';
import { useApi } from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';

export const Reports = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const account = useAccount();
  const api = useApi();

  const isSecondFunctionAvailable = account?.data?.id === '888012';

  const onCreateReport = async () => {
    setLoading(true);
    const objects = await api.getObjects();

    let newObject = objects.find(({ name }: any) => name === 'Без объекта')?.id;

    if (!newObject) {
      const found = objects.find(({ name }: any) => name === 'Без объекта');
      if (found) {
        newObject = found.id;
      } else {
        const { id } = await api.createObject('Без объекта');
        newObject = id;
      }
    }
    const created = await api.createBudget(newObject, 'Отчет', 'REPORT');
    navigate(
      '/object/' + newObject + '/budget/' + created?.id + '?view=report',
    );
  };

  return (
    <div className={'w-full flex flex-row justify-center'}>
      <div className={'flex flex-col p-5 w-96 gap-5 text-center'}>
        <p>Выберите вид отчета</p>
        <Button
          onClick={onCreateReport}
          loading={loading}
          variant={'outline'}
          className={'flex-1 h-96'}
        >
          Проверка отчета
        </Button>
        <Button
          disabled={!isSecondFunctionAvailable}
          onClick={() => navigate('supply')}
          variant={'outline'}
          className={'flex-1 h-96'}
        >
          Отчет по снабжению
        </Button>
      </div>
    </div>
  );
};
