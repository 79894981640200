import { Button, Input, Modal } from 'react-daisyui';
import { useOrderDetails } from '../../../../hooks/useOrderDetails';
import { useParams } from 'react-router';
import { Table } from 'antd';
import { useProviderEmails } from '../../../../hooks/useProviderEmails';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useApi } from '../../../../hooks/useApi';
import toast from 'react-hot-toast';

interface Props {
  onClose: VoidFunction;
}

export const SendToProvidersModal = ({ onClose }: Props) => {
  const { orderId } = useParams();
  const orderDetails = useOrderDetails(orderId!);
  const [sendLoading, setSendLoadinh] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const emails = useProviderEmails();
  const api = useApi();

  const { register, handleSubmit, formState, reset } = useForm<{
    email: string;
  }>();

  const emailText = orderDetails.data?.products
    .map(p => `${p.name} ${p.count} ${p.unit}`)
    .join('\n');

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      key: 'count',
      render: (count: number, d: any) => `${count} ${d?.unit}`,
    },
  ];

  const addEmail = async ({ email }: { email: string }) => {
    setButtonLoading(true);
    try {
      await api.saveProviderEmail(email);
      await emails.refetch();
    } catch {
      toast.success('Такой email уже добавлен. Выберите его из списка ниже');
    }
    reset();
    setButtonLoading(false);
  };

  const onSend = async () => {
    try {
      setSendLoadinh(true);
      await api.sendProviderEmails(selectedEmails, emailText!);
      onClose();
      selectedEmails.forEach(e =>
        toast.success(`Отправлено ${e}!`, { duration: 5000 }),
      );
    } catch {
      toast.error(
        'Невозможно отправить: для вашего аккаунта не настроена отправка почты',
      );
    }
    setSendLoadinh(false);
  };

  return (
    <Modal open style={{ width: '50vw' }}>
      <div className="flex flex-row w-full mb-5 gap-5">
        <div className="flex-1">
          <p className="text-center font-bold pb-5">Поставщики</p>
          <form
            onSubmit={handleSubmit(addEmail)}
            className="flex flex-row gap-5 mb-5"
          >
            <Input
              {...register('email', { required: true, pattern: /^.+@.+\..+$/ })}
              className="flex-1"
              color={formState.errors.email ? 'error' : undefined}
              placeholder="Email поставщика"
              disabled={buttonLoading}
            />
            <Button loading={buttonLoading} type="submit">
              Добавить
            </Button>
          </form>
          <Table
            columns={[
              {
                title: 'Электронная почта',
                dataIndex: 'email',
                key: 'email',
              },
            ]}
            dataSource={emails.data}
            pagination={false}
            locale={{ emptyText: 'Еще нет сохраненных емейлов' }}
            rowKey="email"
            rowSelection={{
              onChange: v => setSelectedEmails(v),
            }}
            scroll={{
              y: 500,
            }}
          />
        </div>
        {/* <div className={'flex-1'}> */}
        {/*  <p className={'text-center font-bold pb-5'}>Материалы</p> */}
        {/*  <Table */}
        {/*    columns={columns} */}
        {/*    dataSource={orderDetails.data?.products} */}
        {/*    pagination={false} */}
        {/*    scroll={{ */}
        {/*      y: 500, */}
        {/*    }} */}
        {/*  /> */}
        {/* </div> */}
      </div>
      <hr />
      <div className="flex flex-row gap-5 mt-5">
        <Button className="flex-1" variant="outline" onClick={onClose}>
          Отменить
        </Button>
        <Button
          className="flex-1"
          disabled={!selectedEmails.length || !emailText}
          onClick={onSend}
          loading={sendLoading}
        >
          Отправить
        </Button>
      </div>
    </Modal>
  );
};
