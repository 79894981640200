import {
  type PropsWithChildren,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { Button, Popover, Modal, Switch, Divider } from 'antd';
import { useParams } from 'react-router';
import { PositionsTable } from './ReasonModal/PositionsTable';
import { LoadingScreen } from '../../../../components/LoadingScreen';
import { useApi } from '../../../../hooks/useApi';
import { useOrderDetails } from '../../../../hooks/useOrderDetails';
import { useOrderStore } from '../../../../store/useOrderStore';
import { money } from '../../../../utils/moneyTool';
import { useOrderPrices } from '../../hooks/useOrderPrices';

interface Props {
  onClose: VoidFunction;
  selectedProvider: string;
}

interface Reason {
  isStroyset: boolean;
  providerId: string;
  productId: string;
  positionId: string;
  providerName: string;
  positionName: string;
  itemId: string;
}

export const CartModal = ({
  onClose,
  selectedProvider,
}: PropsWithChildren<Props>) => {
  const { orderId } = useParams();
  const orderDetails = useOrderDetails(orderId!);
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState<Reason>();

  const provider = useMemo(() => {
    if (!orderDetails.data) return undefined;
    return orderDetails.data.providers.find(p => p.id === selectedProvider);
  }, [orderDetails.data, selectedProvider]);

  const hasDelivery =
    orderDetails.data.deliveryPrice > 0 || provider?.deliveryPrice > 0;
  const [isDeliveryIncluded, setIsDeliveryIncluded] = useState(hasDelivery);

  const api = useApi();

  const onReason = (data: Reason) => {
    setReason(data);
  };

  const { getBestPosition } = useOrderPrices(orderId);

  const columns = [
    {
      title: 'Номенклатура контрагента',
      dataIndex: 'positionName',
      key: 'positionName',
      width: 400,
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      key: 'count',
    },
    selectedProvider === 'stroyset' && {
      title: 'Поставщик',
      dataIndex: 'providerName',
      key: 'providerName',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      render: value => money(value),
    },
    {
      title: 'Сумма',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: value => money(value),
    },
    {
      dataIndex: 'action',
      key: 'id',
      render: (_: any, row: any) => (
        <Button
          onClick={() =>
            onReason({
              isStroyset: selectedProvider === 'stroyset',
              providerId: row.providerId,
              productId: row.id,
              positionId: row.positionId,
              providerName: row.providerName,
              positionName: row.positionName,
              itemId: row.itemId,
            })
          }
        >
          Обоснование
        </Button>
      ),
    },
  ].filter(Boolean);

  const data = useMemo(() => {
    if (!orderDetails.data) return [];
    return orderDetails.data.products
      .map(product => {
        if (selectedProvider === 'stroyset') {
          const optimalPosition = orderDetails.data.market.find(
            m => m.productId === product.id,
          );

          if (!optimalPosition) return null;

          return {
            id: product.id,
            positionName: optimalPosition.positionName,
            count: `${optimalPosition.positionCount} ${optimalPosition.positionUnit}`,
            providerName: optimalPosition.providerName,
            price: optimalPosition.price,
            totalPrice: optimalPosition.totalPrice,
            providerId: optimalPosition.providerId,
            positionId: optimalPosition.positionId,
          };
        }

        const position = getBestPosition(product.itemId, selectedProvider);

        if (!position) return null;

        const providerIndex = orderDetails.data.providers.findIndex(
          p => p.id === selectedProvider,
        );

        const price = position?.prices[providerIndex];

        return {
          id: product.id,
          positionName: position.name,
          count: `${position.count} ${position.productUnit}`,
          providerName: provider.name,
          price,
          totalPrice: price * position.count,
          providerId: selectedProvider,
          positionId: position.id,
        };
      })
      .filter(Boolean);
  }, [getBestPosition, orderDetails.data, selectedProvider, provider]);

  useEffect(() => {
    if (data.length > 0) {
      setSelected(data.map(({ id }) => id));
    }
  }, [data]);

  const totalPrice = useMemo(() => {
    if (!orderDetails.data || (!provider && selectedProvider !== 'stroyset'))
      return 0;

    return selectedProvider === 'stroyset'
      ? isDeliveryIncluded
        ? orderDetails.data.totalPrice
        : orderDetails.data.orderPrice
      : isDeliveryIncluded
        ? provider.totalPrice
        : provider.orderPrice;
  }, [isDeliveryIncluded, orderDetails.data, provider, selectedProvider]);

  const renderDeliveryDetails = () => {
    return (
      <div className="flex flex-col items-end">
        {hasDelivery ? (
          <div className="flex gap-8 mb-2">
            <Switch
              defaultChecked={isDeliveryIncluded}
              onChange={checked => setIsDeliveryIncluded(checked)}
            />
            <p>Доставка</p>
          </div>
        ) : (
          <p>Самовывоз</p>
        )}

        {hasDelivery &&
          (selectedProvider === 'stroyset'
            ? orderDetails.data.deliveryPrice > 0 && (
                <>
                  <p>Цена: {money(orderDetails.data.deliveryPrice)}</p>
                  <p>{orderDetails.data.time}</p>
                </>
              )
            : provider.deliveryPrice > 0 && (
                <>
                  <p>Цена: {money(provider.deliveryPrice)}</p>
                  <p>{provider.time}</p>
                </>
              ))}

        {selectedProvider === 'stroyset' ? (
          <p>{orderDetails.data.ts_info}</p>
        ) : (
          <p>{provider.ts_info}</p>
        )}
      </div>
    );
  };

  const renderTotalPrice = () => {
    return (
      <div className="flex flex-col items-end font-bold">
        <p className="text-sm">ИТОГО</p>
        <p className="text-4xl">{money(totalPrice)}</p>
      </div>
    );
  };

  const providerName =
    orderDetails?.data?.providers?.find(p => p?.id === selectedProvider)
      ?.name ?? 'СтройСеть';

  const onReport = async () => {
    setLoading(true);

    const products = data.map(p => ({
      'Номенклатура контрагента': p.positionName,
      Количество: `${p.count}`,
      Цена: `${p.price}`,
      Сумма: p.totalPrice,
    }));

    products.push({
      'Номенклатура контрагента': 'ИТОГО',
      Количество: '',
      Цена: '',
      Сумма: totalPrice,
    });

    await api.downloadExcel(products, `Счет ${providerName}.xlsx`);
    setLoading(false);
  };

  const { priceType } = useOrderStore();

  const handleCheckout = useCallback(async () => {
    setLoading(true);
    const response = await api.orderPayment(orderId, {
      isDeliveryIncluded,
      selectedItems: selected,
      priceType,
      payAs: 'INDIVIDUAL',
    });

    if (response.url) {
      window.open(response.url, '_blank');
    }

    setLoading(false);
    onClose();
  }, [api, orderId, isDeliveryIncluded, selected, priceType, onClose]);

  if (!orderDetails.data) return <LoadingScreen />;

  return (
    <Modal
      open
      width={1200}
      title={`Корзина ${`"${providerName ?? 'СтройСеть'}`}"`}
      onCancel={onClose}
      footer={false}
      style={{
        width: '100%',
      }}
    >
      {reason && (
        <div className="flex flex-col gap-5">
          <p className="font-bold">Обоснование</p>
          <div className="flex flex-row gap-5">
            <div className="bg-gray-50 rounded p-3">
              <p className="text-sm opacity-50">Выбранная номенклатура</p>
              <p>{reason.positionName}</p>
            </div>
            <div className="bg-gray-50 rounded p-3">
              <p className="text-sm opacity-50">Выбранный поставщик</p>
              <p>{reason.providerName}</p>
            </div>
            <div className="bg-gray-50 rounded p-3">
              <p className="text-sm opacity-50">Причина выбора</p>
              <p>
                {reason.isStroyset
                  ? 'Оптимальная позиция с учётом доставки'
                  : 'Лучшая цена у поставщика'}
              </p>
            </div>
          </div>

          <PositionsTable
            itemId={reason.itemId}
            selectedProvider={reason.providerId}
            productId={reason.productId}
            selectedPosition={reason.positionId}
            providers={orderDetails.data.providers}
          />

          <Button onClick={() => setReason(undefined)}>Закрыть</Button>
        </div>
      )}

      {!reason && (
        <>
          <ProTable
            columns={columns}
            scroll={{
              y: 400,
            }}
            pagination={false}
            className="w-[100%] cartModalTable"
            style={{ maxWidth: '100%' }}
            rowSelection={{
              selectedRowKeys: selected,
              onChange: s => setSelected(s as unknown as string[]),
            }}
            rowKey={record => `${record.id}`}
            search={false}
            headerTitle={
              <>
                <Popover
                  content={
                    !selected.length &&
                    'Чтобы выгрузить смету - выберите галочками нужные позиции'
                  }
                >
                  <Button
                    disabled={!selected.length}
                    key="acc"
                    onClick={onReport}
                    icon={<DownloadOutlined />}
                  >
                    Выгрузить смету
                  </Button>
                </Popover>
              </>
            }
            dataSource={data}
            options={{
              reload: false,
              setting: false,
              density: false,
            }}
            footer={() => (
              <>
                {renderDeliveryDetails()}
                <Divider />
                {renderTotalPrice()}
              </>
            )}
          />

          <div className="flex flex-row gap-2 justify-end">
            <Button onClick={onClose}>Закрыть</Button>

            <Button
              disabled={
                selectedProvider !== 'stroyset' || selected.length === 0
              }
              loading={loading}
              className="bg-blue-500"
              type="primary"
              onClick={handleCheckout}
            >
              Оформить заявку
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
