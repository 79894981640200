import { Button, Dropdown, Badge } from 'react-daisyui';
import type { IObject } from '../../../types';
import { dateTool } from '../../../utils/dateTool';

interface Props extends IObject {
  onClick: VoidFunction;
  onRename: VoidFunction;
  onDelete: VoidFunction;
  object: {
    name: string;
  };
  budget: {
    name: string;
  };
  address: string | null;
  status: 'calculated' | 'outdated';
}

export const OrderItem = ({
  onClick,
  name,
  createdAt,
  onDelete,
  address,
  status,
}: Props) => {
  return (
    <div
      className={
        'cursor-pointer w-full rounded-xl bg-white p-5 flex flex-row justify-between shadow hover:bg-gray-50 transition'
      }
    >
      <div className={'flex flex-col w-full'} onClick={onClick}>
        <div className={'flex flex-row'}>
          <p>{name}</p>
        </div>
        <p className={'opacity-50'}>{dateTool.display(createdAt)}</p>
        <p className={'opacity-50'}>{address || 'Без объекта'}</p>

        {status === 'calculated' ? (
          <Badge color="info" size="sm">
            Рассчитана
          </Badge>
        ) : (
          <Badge color="warning" size="sm">
            Нужен пересчет
          </Badge>
        )}
      </div>
      <div className={'flex flex-row gap-2'}>
        <Dropdown>
          <Button variant={'outline'}>...</Button>
          <Dropdown.Menu>
            {/*<Dropdown.Item onClick={onRename}>Редактировать</Dropdown.Item>*/}
            <Dropdown.Item onClick={onDelete} className={'text-red-500'}>
              Удалить
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
