import React from 'react';
import { Button, Modal } from 'react-daisyui';
import { Table } from 'antd';
import { useParams } from 'react-router';
import { useBudgetReport } from '../hooks/useBudgetReport';
import { money } from '../utils/moneyTool';
import { useApi } from '../hooks/useApi';

interface Props {
  onClose: VoidFunction;
  open: boolean;
  fallbackBudgetId?: string;
}

export const BudgetReportModal = ({
  onClose,
  open,
  fallbackBudgetId,
}: Props) => {
  const { budgetId } = useParams();
  const api = useApi();
  const report = useBudgetReport(budgetId || fallbackBudgetId || '');

  const onDownload = (providerId: string, providerName: string) =>
    api.downloadBudgetReport(
      budgetId || fallbackBudgetId || '',
      providerId,
      providerName,
    );

  const data = report.data?.map(p => ({
    ...p,
    price: money(p.price),
    action: (
      <Button onClick={() => onDownload(p.id, p.name)} variant={'outline'}>
        Скачать
      </Button>
    ),
  }));

  const columns = [
    {
      title: 'Поставщик',
      dataIndex: 'name',
      key: 'id',
    },
    {
      title: 'Итоговая сумма',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'but',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  if (!report.data) return null;
  return (
    <Modal open={open}>
      <Modal.Header>Выгрузить счета для поставщиков</Modal.Header>
      <Table
        showHeader={false}
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey={'id'}
        scroll={{ y: 500 }}
      />
      <Button className={'mt-5 w-full'} onClick={onClose}>
        Готово
      </Button>
    </Modal>
  );
};
