import currency from 'currency.js';

const options = {
  pattern: '# ₽',
  negativePattern: '- # ₽',
  separator: ' ',
  decimal: ',',
};

export function money(cash?: number, unit?: string) {
  if (!cash && cash !== 0) {
    return '---';
  }

  const formattedCash = currency(cash).format(options);

  if (unit) {
    return `${formattedCash} / ${unit}`;
  }

  return formattedCash;
}

export function calculateUnitPrice(itemId: string, details: any) {
  const marketProduct = details.data?.market.find(
    (p: any) => p.itemId === itemId,
  );
  const product = details.data?.products.find((p: any) => p.itemId === itemId);
  const position = product?.positions.find(
    (p: any) => p.id === marketProduct?.positionId,
  );

  return (marketProduct?.price || 0) * (position?.koef || 1);
}

export function findMarketKoef(itemId: string, details: any) {
  const marketProduct = details.data?.market.find(
    (p: any) => p.itemId === itemId,
  );
  const product = details.data?.products.find((p: any) => p.itemId === itemId);
  const position = product?.positions.find(
    (p: any) => p.id === marketProduct?.positionId,
  );

  return position?.koef;
}
