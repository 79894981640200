import { type FC } from 'react';
import { useParams } from 'react-router';
import { Table } from 'antd';

import { money } from '../../../utils/moneyTool';
import { useOrderDetails } from '../../../hooks/useOrderDetails';
import { LoadingScreen } from '../../../components/LoadingScreen';

export const PaymentTable: FC = () => {
  const { orderId } = useParams();

  const details = useOrderDetails(orderId!);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'positionName',
      key: 'positionName',
    },
    {
      title: 'Поставщик',
      dataIndex: 'providerName',
      key: 'providerName',
    },
    {
      title: 'Количество',
      dataIndex: 'positionCount',
      key: 'positionCount',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Сумма',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
    },
  ];

  if (!details?.data?.market) return <LoadingScreen />;

  const tableRows = details.data?.market?.map(m => ({
    ...m,
    price: money(m.price),
    totalPrice: money(m.totalPrice),
  }));

  return <Table dataSource={tableRows} columns={columns} pagination={false} />;
};
