import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';
import { IAnalytics } from '../types';

export const useAnalytics = (budgetId: string) => {
  const api = useApi();
  return useQuery<IAnalytics>(
    ['analytics', budgetId],
    () => {
      if (!budgetId) throw new Error('no budgetId to fetch');
      return api.getAnalytics(budgetId);
    },
    {
      refetchInterval: 10000,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!budgetId,
    },
  );
};
