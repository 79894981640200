import { type FC, memo, useState, useCallback, useContext } from 'react';
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import {
  type DatePickerProps,
  Flex,
  AutoComplete,
  Input,
  Tooltip,
  Typography,
  Spin,
} from 'antd';
import moment from 'moment';
import { useParams } from 'react-router';
import DatePicker from '../../../../components/DatePicker';
import { RenameModal } from '../../../../components/RenameModal';
import { useAddress } from '../../../../hooks/useAddress';
import { useApi } from '../../../../hooks/useApi';
import { useOrder } from '../../../../hooks/useOrder';
import { UpdateToastContext } from '../../hooks/useUpdateToast';

const { Title } = Typography;

const DATE_FORMAT = 'DD.MM.YYYY';

interface Props {
  orderName: string;
  deliveryDate?: string;
  recipientName?: string;
  deliveryAddress?: string;
}

export const Settings: FC<Props> = memo(
  ({ orderName, deliveryDate, recipientName, deliveryAddress }) => {
    const api = useApi();
    const { orderId } = useParams();
    const { setUpdated } = useContext(UpdateToastContext);
    const order = useOrder(orderId || '');

    const [date, setDate] = useState(() =>
      deliveryDate ? moment(deliveryDate) : undefined,
    );
    const [recipient, setRecipient] = useState<string>(recipientName);
    const [address, setAddress] = useState<string>(deliveryAddress);
    const [renamingObject, setRenamingObject] = useState<string | null>(null);

    const { data: addressOptions, isLoading } = useAddress(address || '');

    const onChange: DatePickerProps['onChange'] = async value => {
      setDate(value);
      await api.updateOrder(orderId, { deliveryDate: value.toISOString() });
    };

    const onRename = useCallback(
      async (name: string) => {
        if (renamingObject) {
          setRenamingObject(null);
          await api.updateOrder(orderId, { name });
          await order.refetch();
        }
      },
      [api, order, orderId, renamingObject],
    );

    const onRecipientChange = useCallback(e => {
      setRecipient(e.target.value);
    }, []);

    const onRecipientBlur = useCallback(async () => {
      await api.updateOrder(orderId, { recipientName: recipient });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, recipient]);

    const onAddressChange = useCallback(value => {
      setAddress(value);
    }, []);

    const onAddressSelect = useCallback(
      async (_, value) => {
        if (!orderId) {
          throw new Error('There is no orderId!');
        }
        await api.updateOrderAddress(orderId, value);
        setUpdated(true);
      },
      [api, orderId, setUpdated],
    );

    const onRenameIconClick = useCallback(() => {
      setRenamingObject(orderId);
    }, [orderId]);

    return (
      <div className="flex flex-row justify-between items-center mb-2">
        <div
          style={{
            display: 'flex',
            gap: 20,
            width: '100%',
            marginBottom: 10,
          }}
        >
          <Flex gap={10} style={{ alignItems: 'end', flexGrow: 1 }}>
            <h1
              className="text-3xl"
              style={{
                overflow: 'hidden',
                whiteSpace: 'pre',
                textOverflow: 'ellipsis',
              }}
            >
              Заявка {orderName}
            </h1>

            <Tooltip title="Переименовать заявку">
              <EditOutlined
                style={{ cursor: 'pointer', width: 35, marginBottom: 8 }}
                onClick={onRenameIconClick}
              />
            </Tooltip>
          </Flex>

          <Flex
            style={{
              width: '100%',
            }}
          >
            <Flex
              style={{
                width: '100%',
                gap: 20,
              }}
            >
              <Flex
                vertical
                style={{
                  minWidth: 145,
                }}
              >
                <Title level={5} style={{ marginBottom: 5 }}>
                  Дата доставки
                </Title>

                <DatePicker
                  value={date}
                  placeholder="Дата доставки"
                  onChange={onChange}
                  format={value => value.format(DATE_FORMAT)}
                  style={{
                    minWidth: 145,
                  }}
                />
              </Flex>

              <Flex
                vertical
                style={{
                  flexGrow: 1,
                }}
              >
                <Title level={5} style={{ marginBottom: 5 }}>
                  Адрес доставки
                </Title>

                <Spin spinning={isLoading}>
                  <AutoComplete
                    className="w-full"
                    value={address}
                    options={addressOptions}
                    onSelect={onAddressSelect}
                    onChange={onAddressChange}
                    placeholder="Aдрес доставки"
                  />
                </Spin>
              </Flex>

              <Flex
                vertical
                style={{
                  flexGrow: 1,
                }}
              >
                <Title level={5} style={{ marginBottom: 5 }}>
                  Контактные данные
                </Title>

                <Input
                  value={recipient}
                  onChange={onRecipientChange}
                  onBlur={onRecipientBlur}
                  placeholder="Контактные данные"
                  suffix={
                    <Tooltip title="Информация для связи с Вами">
                      <InfoCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                />
              </Flex>
            </Flex>
          </Flex>
        </div>

        <RenameModal
          title="Переименовать заявку"
          defaultValue={orderName}
          onSubmit={onRename}
          open={Boolean(renamingObject)}
          onClose={() => setRenamingObject(null)}
        />
      </div>
    );
  },
);
