import { useQuery } from '@tanstack/react-query';
import { type IAddress } from 'types';
import { useApi } from './useApi';

export const useAddress = (query: string) => {
  const api = useApi();

  return useQuery<IAddress[]>(['address', query], () =>
    api.autoCompleteCity(query),
  );
};
