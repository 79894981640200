import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useObject } from '../../hooks/useObject';
import { useParams } from 'react-router';
import { Tabs } from 'antd';
import { Button } from 'react-daisyui';
import { SettingsTab } from './components/SettingsTab';
import { useAnalytics } from '../../hooks/useAnalytics';
import { LoadingScreen } from '../../components/LoadingScreen';
import { useBudgetDetails } from '../../hooks/useBudgetDetails';
import { useApi } from '../../hooks/useApi';
import { dateTool } from '../../utils/dateTool';
import { ProductsTab } from './components/ProductsTab';
import { useAnalyticsView } from './hooks/useAnalyticsView';
import { useOptPrices } from './hooks/useOptPrices';
import { OfferTab } from '../Order/components/OfferTab';
import { useAnalyticsTableFilters } from './hooks/useAnalyticsTableFilters';
import { ReportPage } from './components/ReportPage';
import { ExpiredPricesModal } from '../../components/ExpiredPricesModal';

const tabs = [
  {
    key: 'estimate',
    label: 'Прогноз',
  },
  {
    key: 'products',
    label: 'Смета',
  },
];

const priceModes = [
  {
    key: 'default',
    label: 'Оптовые цены',
  },
  {
    key: 'retail',
    label: 'Розничные цены',
  },
  {
    key: 'updatePosition',
    label: 'Обновить цены',
  },
];

export const Analytics = () => {
  const navigate = useNavigate();
  const view = useAnalyticsView();
  const [isExportLoading, setExportLoading] = useState(false);
  const api = useApi();
  const { objectId, budgetId } = useParams();
  const { optPrice, setOptPrice } = useOptPrices(budgetId || '');
  const budgetDetails = useBudgetDetails(budgetId || '');
  const { data, isLoading, refetch } = useAnalytics(budgetId || '');
  const object = useObject(objectId || '');
  const [page, setPage] = useState(view === 'report' ? 'report' : 'estimate');
  const filters = useAnalyticsTableFilters();
  const [invalidTimeStamp, setInvalidTimeStamp] = useState<Date | null>(
    data?.updatedAt || null,
  );
  const [isNotFound, setNotFound] = useState(false);

  useEffect(() => {
    api.getOptimizationStatus(budgetId || '').then(data => {
      if (data === 'notFound') setNotFound(true);
    });
  }, []);

  const onBack = () => {
    navigate('/');
  };

  const onExport = async () => {
    setExportLoading(true);
    await refetch();

    if (!data?.outdated) {
      alert('тут страница выгрузки');
    }
    setExportLoading(false);
  };

  const onReOptimize = async () => {
    if (budgetDetails.data) {
      setInvalidTimeStamp(data?.updatedAt || null);
      await api.optimizeBudget(budgetId!, budgetDetails.data);
      window.location.reload();
    }
  };

  if (isNotFound) return <ExpiredPricesModal onReOptimize={onReOptimize} />;

  if (isLoading || invalidTimeStamp === data?.updatedAt) {
    return (
      <LoadingScreen
        description={
          view === 'analytics'
            ? 'Подбираем лучшие позиции\nПожалуйста подождите'
            : 'Готовим отчет\nПожалуйста подождите'
        }
      />
    );
  }

  return (
    <div className="w-full h-full flex flex-col gap-5 px-10">
      <div className="bg-white p-5 pb-0 rounded-xl shadow w-full mt-10">
        <p onClick={onBack} className="opacity-50 cursor-pointer">
          {'<'} к объектам
        </p>

        <div className="flex flex-row justify-between items-center mb-2">
          <div>
            <p className="text-3xl my-2">
              {view === 'analytics' ? 'Аналитика' : 'Отчет'}
            </p>
            <p className="opacity-50 text-sm">
              Обновлено{' '}
              {data?.updatedAt &&
                dateTool.display(data?.updatedAt).toLowerCase()}
              {', '}
              показаны цены на{' '}
              {
                dateTool
                  .display(budgetDetails?.data?.documentDate || new Date())
                  .toLowerCase()
                  .split(',')[0]
              }
            </p>
          </div>

          <div className="p-5 flex flex-col gap-5">
            {view !== 'report' && (
              <Tabs
                className="mb-0 pb-0"
                activeKey={optPrice}
                onChange={setOptPrice}
                items={priceModes}
              />
            )}
          </div>

          {/* <Button>Обновить цены</Button> */}
        </div>

        {view !== 'report' && (
          <Tabs
            activeKey={page}
            onChange={setPage}
            className="mb-0 pb-0"
            items={tabs}
          />
        )}
      </div>

      {data?.outdated && (
        <div className="bg-yellow-50 bbudget-2 bbudget-yellow-100 rounded-xl p-5 flex flex-row justify-between">
          <div className="flex flex-col gap-1">
            <p className="font-bold">В отчёте неактуальные данные</p>
            <p className="text-sm">
              Вы внесли изменения в отчёт, которые нужно применить, чтобы
              получить актуальную информацию.
            </p>
          </div>

          <Button onClick={onReOptimize} color="warning">
            Обновить отчет
          </Button>
        </div>
      )}

      <div className="bg-white rounded-2xl shadow p-5">
        {page === 'estimate' && <SettingsTab />}
        {page === 'products' && <ProductsTab />}
        {page === 'active' && <OfferTab />}
        {page === 'report' && <ReportPage />}
      </div>
    </div>
  );
};
