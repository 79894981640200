import { useState } from 'react';
import { Tabs } from 'antd';
import { Checkbox } from 'react-daisyui';
import { FactoriesTab } from './components/FactoriesTab';
import { ProductsTab } from './components/ProductsTab';
import { ProvidersTab } from './components/ProvidersTab';
import { useSettings } from '../../hooks/useSettings';

export const Settings = () => {
  const appSettings = useSettings();
  const [page, setPage] = useState('products');

  const items = [
    {
      key: 'products',
      label: 'Условия отбора',
    },
    {
      key: 'providers',
      label: 'Поставщики',
    },
    {
      key: 'factories',
      label: 'Производители',
    },
  ];

  return (
    <div className={'w-full h-full flex flex-col gap-5 p-10'}>
      <div className={'bg-white p-5 pb-0 rounded-xl shadow w-full mt-10'}>
        <div className={'flex flex-row justify-between items-center mb-2'}>
          <div>
            <p className={'text-3xl my-2'}>Настройка проверенных</p>
          </div>
          <div className={'mr-5 flex flex-row gap-3'}>
            <Checkbox
              checked={appSettings.settings?.personalPrices}
              onChange={() => {
                appSettings.setSettings((v: any) => ({
                  ...v,
                  personalPrices: !v?.personalPrices,
                }));
              }}
            />
            <p>Показывать прогнозные цены</p>
          </div>
        </div>

        <Tabs
          activeKey={page}
          onChange={setPage}
          className={'mb-0 pb-0'}
          items={items}
        />
      </div>

      <div className={'bg-white rounded-2xl shadow p-5'}>
        {page === 'products' && <ProductsTab />}
        {page === 'providers' && <ProvidersTab />}
        {page === 'factories' && <FactoriesTab />}
      </div>
    </div>
  );
};
