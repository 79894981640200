import { useState } from 'react';
import { Pagination } from 'antd';
import { LoadingScreen } from 'components/LoadingScreen';
import { useBatchUpdateVerifiedPositions } from 'hooks/useBatchUpdateVerifiedPositions';
import { usePositions } from 'hooks/usePositions';
import { Button, Input } from 'react-daisyui';
import { useVerifiedPositionsStore } from 'store/useVerifiedPositionsStore';
import { useProducts } from '../../../hooks/useProducts';
import { MarketProductItem } from '../../Analytics/components/MarketProductItem';

export const ProductsTab = () => {
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);

  const { data: excludedPositions = [], isLoading, refetch } = usePositions();

  const { data } = useProducts(query, page);
  const { mutateAsync, isLoading: isSaveLoading } =
    useBatchUpdateVerifiedPositions();
  const { positions, clear } = useVerifiedPositionsStore();

  const handleSave = async () => {
    await mutateAsync(Object.values(positions));
    refetch();
    clear();
  };

  return (
    <div>
      <div className="flex flex-col gap-5">
        {Object.values(positions).length > 0 && (
          <div className="bg-yellow-400 bbudget-2 bbudget-yellow-100 rounded-xl p-5 flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <p className="font-bold">Настройки изменены</p>
              <p className="text-sm">
                Вы внесли изменения в список проверенных товаров
              </p>
            </div>

            <Button onClick={handleSave} loading={isSaveLoading}>
              {isSaveLoading ? 'Сохранение...' : 'Сохранить'}
            </Button>
          </div>
        )}
        <Input
          onInput={e => setQuery(e.currentTarget.value)}
          placeholder="Поиск"
        />
        {isLoading || !data?.items.length ? (
          <LoadingScreen />
        ) : (
          <>
            {data.items.map(product => (
              <MarketProductItem
                key={product.id}
                productId={product.id}
                positions={product.positions.map(position => ({
                  ...position,
                  verified: !excludedPositions.includes(position.id),
                }))}
                name={product.name}
              />
            ))}
            <Pagination
              defaultCurrent={1}
              current={page}
              pageSize={20}
              total={data.meta.totalItems}
              showSizeChanger={false}
              onChange={value => setPage(value)}
            />
          </>
        )}
      </div>
    </div>
  );
};
