import React, { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Modal } from 'react-daisyui';

const ErrorFallback = ({ children }: PropsWithChildren) => (
  <Modal open={true}>
    <Modal.Body>
      <p className={'text-xl text-center'}>
        Приложение остановлено из-за внутренней ошибки, попробуйте обновить
        страницу
      </p>
      <p className={'opacity-50 text-center my-5'}>Код ошибки: {children}</p>
    </Modal.Body>
    <Modal.Actions>
      <Button className={'w-full'} onClick={() => window.location.reload()}>
        Обновить
      </Button>
    </Modal.Actions>
  </Modal>
);

export const ErrorProvider = ({ children }: PropsWithChildren) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <ErrorFallback>{error}</ErrorFallback>}
    >
      {children}
    </ErrorBoundary>
  );
};
