import { type FC } from 'react';
import { TagFilled } from '@ant-design/icons';
import { TinyArea } from '@ant-design/plots';
import { Tag, Popover } from 'antd';
import type { IAnalyticsPosition } from 'types';
import styles from './styles.module.css';
import { money } from '../../../../../utils/moneyTool';

const DEFAULT_POSITION_UNIT = 'шт';

interface PriceBadgeProps {
  price?: number;
  count?: number;
  unitPrice?: number;
  isBestPrice?: boolean;
  isTotalShown?: boolean;
  positionUnit?: string;
  productUnit?: string;
  koef?: number;
  type?: string;
}

export const PriceBadge: FC<PriceBadgeProps> = ({
  type,
  price,
  unitPrice,
  positionUnit,
  productUnit,
  koef,
  count,
  isTotalShown,
  isBestPrice,
}) => {
  if (type === 'personal') {
    return (
      <div className="flex justify-center items-center" title={money(price)}>
        <Tag
          color="green"
          bordered={false}
          style={{
            marginTop: 5,
          }}
        >
          <div className="text-center">
            {isTotalShown ? (
              <>{money(price * count)}</>
            ) : (
              <>
                <p>{money(price, productUnit)}</p>
                {productUnit !== positionUnit && (
                  <p>
                    {money(unitPrice, positionUnit ?? DEFAULT_POSITION_UNIT)}
                  </p>
                )}
              </>
            )}
          </div>
        </Tag>
      </div>
    );
  }

  if (price === 0) {
    return null;
  }

  if (!(unitPrice && price)) {
    return (
      <div className="px-1 rounded-lg flex justify-center items-center">
        <p className="cursor-default">
          {money(price, positionUnit ? positionUnit : DEFAULT_POSITION_UNIT)}
        </p>
      </div>
    );
  }

  if (isBestPrice) {
    return (
      <div className="px-1 rounded-lg flex justify-center items-center">
        <Tag
          bordered={false}
          color="#f4ffb8"
          className={styles.tag}
          style={{ margin: 0, position: 'relative', color: '#000 !important' }}
        >
          <div>
            <TagFilled
              rotate={270}
              style={{
                fill: 'red',
                color: 'red',
                position: 'absolute',
                bottom: -7.5,
                right: -7.5,
                padding: 0,
              }}
            />

            <div className="cursor-default text-center">
              {!isTotalShown ? (
                !positionUnit || productUnit === positionUnit || koef === 1 ? (
                  <p>{money(price, productUnit)}</p>
                ) : (
                  <>
                    <p>{money(price, productUnit)}</p>

                    <p className="text-xs text-slate-500">
                      {money(unitPrice, positionUnit ?? DEFAULT_POSITION_UNIT)}
                    </p>
                  </>
                )
              ) : (
                <p>{!price ? '-' : money(price * (count ?? 1))}</p>
              )}
            </div>
          </div>
        </Tag>
      </div>
    );
  }

  return (
    <div className="px-1 rounded-lg flex justify-center items-center">
      <Tag bordered={false}>
        <div className="cursor-default text-center">
          {isTotalShown ? (
            <p>{!price ? '-' : money(price * (count ?? 1))}</p>
          ) : !positionUnit || productUnit === positionUnit || koef === 1 ? (
            <p>{money(price, productUnit)}</p>
          ) : (
            <>
              <p>{money(price, productUnit)}</p>

              <p className="text-xs text-slate-500">
                {money(unitPrice, positionUnit ?? DEFAULT_POSITION_UNIT)}
              </p>
            </>
          )}
        </div>
      </Tag>
    </div>
  );
};

interface PricesHistoryContentProps {
  pricesHistory: {
    date: string;
    price: number;
  }[];
  unit: string;
}

const PricesHistoryContent: FC<PricesHistoryContentProps> = ({
  pricesHistory,
  unit,
}) => {
  const minPrice = Math.min(...pricesHistory.map(v => v.price));

  const data = pricesHistory.map(v => v.price - minPrice).reverse();
  const config = {
    height: 70,
    autoFit: true,
    data,
    smooth: true,
    padding: 0,
  };

  if (pricesHistory?.length > 1) {
    return (
      <div className="flex flex-col gap-3 w-100">
        {pricesHistory?.length > 1 && <TinyArea {...config} />}

        {pricesHistory?.map(({ price, date }) => (
          <div className="flex flex-row justify-between" key={date}>
            <p>{date}</p>
            <p>{money(price, unit)}</p>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

interface PricePopoverProps {
  providerIndex: number;
  providerName: string;
  isBestPrice?: boolean;
  isRetailPrice: boolean;
  isTotalShown?: boolean;
  position: IAnalyticsPosition;
  positionByPersonalPrice: IAnalyticsPosition | null;
}

export const PricePopover: FC<PricePopoverProps> = ({
  providerIndex,
  providerName,
  isBestPrice,
  isRetailPrice,
  isTotalShown,
  position,
  positionByPersonalPrice,
}) => {
  if (providerIndex === undefined || providerIndex === -1) {
    throw new Error('Провайдер не найден!');
  }

  const personalPricesHistory = position.personalPricesHistory[providerIndex];

  // TODO: For now personalPrices are coming from currentPrices (it is defined on 1C side)
  // We have to repalce them so personalPrices should come from personalPrices
  // currentPrices will be as predictionPrices
  const price = isRetailPrice
    ? position.retailPrices[providerIndex]
    : position.prices[providerIndex];

  const calculatedPrice = money(
    price,
    position.productUnit || DEFAULT_POSITION_UNIT,
  );

  const renderProviderName = () => {
    return (
      <>
        <p className="opacity-50 text-s">Поставщик номенклатуры</p>
        <p>{providerName}</p>
      </>
    );
  };

  const renderPositionName = (p: IAnalyticsPosition) => {
    return (
      <>
        <p className="opacity-50 text-s">Отобранная номенклатура</p>
        <p>{p.name}</p>
      </>
    );
  };

  const renderAmount = (p: IAnalyticsPosition) => {
    return (
      <>
        <p className="opacity-50 text-s">Количество</p>
        <p>{p.count}</p>
      </>
    );
  };

  const renderDetailedPrice = (p: IAnalyticsPosition) => {
    return (
      <>
        <p className="opacity-50 text-s">Детализация цены</p>
        <div className="flex flex-row gap-2">
          <div>{calculatedPrice}</div>
          {p.productUnit === p.positionUnit ? null : (
            <>
              <p>или</p>
              <p>{money(price * p.koef, p.positionUnit)}</p>
            </>
          )}
        </div>
      </>
    );
  };

  const renderDetailedPriceByPersonalPrice = (p: IAnalyticsPosition) => {
    return (
      <>
        <p className="opacity-50 text-s">Детализация цены</p>
        <div className="flex flex-row gap-2">
          <div>
            {money(
              positionByPersonalPrice.currentPrices[providerIndex],
              positionByPersonalPrice.productUnit || DEFAULT_POSITION_UNIT,
            )}
          </div>
          {p.productUnit === p.positionUnit ? null : (
            <>
              <p>или</p>
              <p>
                {money(p.currentPrices[providerIndex] * p.koef, p.positionUnit)}
              </p>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Popover
        content={
          <div className="p-1 flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <Tag
                bordered={false}
                style={{ fontSize: 14, padding: '2px 6px' }}
              >
                Цена платформы
              </Tag>
            </div>
            {renderProviderName()}
            {renderPositionName(position)}
            {renderAmount(position)}
            {renderDetailedPrice(position)}
          </div>
        }
      >
        <>
          <PriceBadge
            isTotalShown={isTotalShown}
            koef={position.koef}
            price={price}
            count={position.count}
            productUnit={position.productUnit}
            positionUnit={position.positionUnit}
            isBestPrice={isBestPrice}
            unitPrice={position.koef * price}
          />
        </>
      </Popover>

      {positionByPersonalPrice && (
        <Popover
          content={
            <div className="p-1 flex flex-col gap-1">
              <div className="flex flex-row">
                <Tag
                  color="green"
                  bordered={false}
                  style={{ fontSize: 14, padding: '2px 6px' }}
                >
                  Цена из прямых счетов
                </Tag>
              </div>
              {renderProviderName()}
              {renderPositionName(positionByPersonalPrice)}
              {renderAmount(positionByPersonalPrice)}
              {renderDetailedPriceByPersonalPrice(positionByPersonalPrice)}
              <p className="opacity-50 text-s" style={{ textAlign: 'center' }}>
                Последние закупки
              </p>
              <PricesHistoryContent
                unit={position.productUnit}
                pricesHistory={personalPricesHistory}
              />
            </div>
          }
        >
          <>
            <PriceBadge
              type="personal"
              isTotalShown={isTotalShown}
              koef={positionByPersonalPrice.koef}
              price={positionByPersonalPrice.currentPrices[providerIndex]}
              count={positionByPersonalPrice.count}
              productUnit={positionByPersonalPrice.productUnit}
              positionUnit={positionByPersonalPrice.positionUnit}
              isBestPrice={isBestPrice}
              unitPrice={
                positionByPersonalPrice.koef *
                positionByPersonalPrice.currentPrices[providerIndex]
              }
            />
          </>
        </Popover>
      )}
    </>
  );
};
