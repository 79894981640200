import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

export const YandexMetrika = () => {
  return (
    <>
      {!!process.env.REACT_APP_YANDEX_METRIKA_ID && (
        <YMInitializer
          accounts={[+process.env.REACT_APP_YANDEX_METRIKA_ID]}
          options={{ webvisor: true, clickmap: true }}
          version={'2'}
        />
      )}
    </>
  );
};
