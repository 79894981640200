import { type FC, useEffect, useState, useCallback, memo } from 'react';
import moment from 'moment';
import { ProTable } from '@ant-design/pro-components';
import { Button, Layout, Tag } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { useApi } from 'hooks/useApi';
import { DocumentsSearch } from './components/Search';
import { useFileMatching } from '../../hooks/useUpload';
import { useUploadFileWithoutMatching } from '../Order/hooks/useUploadFileWithoutMatching';

import { type IDocumentEntity } from 'types';

import styles from './styles.module.css';

// eslint-disable-next-line @typescript-eslint/naming-convention
enum DOCUMENT_STATUSES {
  done = 'Обработан',
  pending = 'В обработке',
  canceled = 'Отменен',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
enum DOCUMENT_SOURCES {
  api = 'API',
  mail = 'Почта',
  web = 'WEB',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
enum STATUSES_COLORS {
  done = 'success',
  pending = 'processing',
  canceled = 'error',
}

const STATUSES_ICONS = {
  done: <CheckCircleOutlined />,
  pending: <SyncOutlined />,
  canceled: <CloseCircleOutlined />,
};

const renderStatus = value => {
  return (
    <Tag color={STATUSES_COLORS[value]} icon={STATUSES_ICONS[value]}>
      {DOCUMENT_STATUSES[value]}
    </Tag>
  );
};

const renderSource = value => {
  return DOCUMENT_SOURCES?.[value?.trim()] ?? '-';
};

const columns: ColumnsType<IDocumentEntity> = [
  {
    title: 'Дата загрузки',
    dataIndex: 'createdAt',
    renderText: value => {
      const formattedDate = moment(value).format('DD.MM.YYYY HH:mm');

      return formattedDate;
    },
    sorter: (a, b) => moment(a.createdAt).isAfter(moment(b.createdAt)),
    sortDirections: ['descend', 'ascend'],
    showSorterTooltip: false,
    defaultSortOrder: 'descend',
  },
  {
    title: 'Название документа',
    dataIndex: 'documentName',
    ellipsis: true,
    width: 300,
    render: (_, record) => {
      if (record.status === 'canceled') {
        return record.documentName;
      }

      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={record.documentLink}
          className={styles.documentNameLink}
        >
          {record.documentName}
        </a>
      );
    },
    sorter: (a, b) => {
      return a.documentName.localeCompare(b.documentName);
    },
    sortDirections: ['descend', 'ascend'],
    showSorterTooltip: false,
  },
  {
    title: 'Поставщик',
    dataIndex: 'providerName',
    sorter: (a, b) => {
      return a.status.localeCompare(b.status);
    },
    showSorterTooltip: false,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: renderStatus,
    sorter: (a, b) => {
      return a.status.localeCompare(b.status);
    },
    showSorterTooltip: false,
  },
  {
    title: 'Обработано',
    dataIndex: 'processedPositions',
    hideInSearch: true,
    renderText: (_, record) => {
      return `${record.processedPositions}/${record.totalPositions}`;
    },
  },
  {
    title: 'Источник',
    dataIndex: 'source',
    hideInSearch: true,
    renderText: renderSource,
  },
];

export const Documents: FC = memo(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();
  const upload = useFileMatching();

  const [data, setData] = useState<IDocumentEntity[]>([]);

  const reloadTableData = useCallback((): Promise<IDocumentEntity[]> => {
    setIsLoading(true);

    return new Promise((res, rej) => {
      api.getUser().then(({ id }) => {
        api.getAllUserDocuments(id).then(docsData => {
          setData(docsData?.data ?? []);
          setIsLoading(false);
          res(docsData?.data ?? []);
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFileWithoutMatching = useUploadFileWithoutMatching({
    reload: reloadTableData,
  });

  useEffect(() => {
    reloadTableData();
  }, [reloadTableData]);

  const onUploadWithoutMatching = useCallback(async () => {
    const files = await upload.choose();
    uploadFileWithoutMatching.uploadFiles(files);
  }, [upload, uploadFileWithoutMatching]);

  return (
    <Layout>
      <div className="flex flex-col gap-3 w-full p-5">
        <div
          style={{
            height: '86px',
            borderRadius: '8px',
            background: '#fff',
            padding: '20px 24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '30px',
            lineHeight: '38px',
            fontWeight: 500,
          }}
        >
          Реестр счетов
          <Button
            onClick={onUploadWithoutMatching}
            icon={<PaperClipOutlined />}
          >
            Загрузить новый счет
          </Button>
        </div>

        <DocumentsSearch
          onReload={reloadTableData}
          setData={setData}
          data={data}
        />

        <ProTable
          rowKey={record => `${record.createdAt}/${record.documentName}`}
          loading={isLoading}
          dataSource={data}
          columns={columns}
          search={false}
          pagination={{
            position: ['bottomRight'],
            defaultPageSize: 16,
            pageSizeOptions: [8, 16, 32, 64],
            showTotal: (total, range) => `${range.join('-')} из ${total}`,
          }}
          options={{
            reload: reloadTableData,
            setting: false,
            density: false,
          }}
          headerTitle="Cчета"
        />
      </div>
    </Layout>
  );
});
