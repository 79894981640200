import { type FC, memo, useCallback, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Flex, Spin, Typography } from 'antd';
import { useParams } from 'react-router';
import { PositionsTable } from './PositionsTable';
import { useApi } from '../../../../hooks/useApi';
import { useOrderDetails } from '../../../../hooks/useOrderDetails';
import { useOrderStore } from '../../../../store/useOrderStore';
import { useOrderContext } from '../OrderContext';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  providers: any[];
  productId: string;
  itemId: string;
}

export const SelectPositionsModal: FC<Props> = memo(
  ({ isOpen, onClose, providers, productId, itemId }) => {
    const api = useApi();
    const { orderId } = useParams();
    const details = useOrderDetails(orderId);
    const { priceType } = useOrderStore();
    const { selectedRowKeys } = useOrderContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const saveExcludedPositions = useCallback(() => {
      const excludedPositions = details.data.products
        .find(product => product.itemId === itemId)
        .positions.filter(position => !selectedRowKeys.includes(position.id))
        .map(position => position.id);

      if (excludedPositions.length) {
        api.updateOrderProduct(itemId, {
          excludedPositions,
        });
      }
    }, [api, details.data, itemId, selectedRowKeys]);

    const onSave = useCallback(async () => {
      try {
        setIsLoading(true);

        saveExcludedPositions();
        await api.optimizeOrder(orderId, priceType);

        setIsLoading(false);

        window.location.reload();
      } catch (e) {
        setIsLoading(false);
      }
    }, [saveExcludedPositions, api, orderId, priceType]);

    const handleClose = useCallback(() => {
      onClose();
      saveExcludedPositions();
    }, [onClose, saveExcludedPositions]);

    return (
      <Modal
        okText="Сохранить и пересчитать цены"
        cancelText="Закрыть"
        open={isOpen}
        onOk={onSave}
        onCancel={handleClose}
        className="!max-w-[90%] !w-5/6"
      >
        <Flex className="p-6 px-5" style={{ minHeight: 200 }}>
          <PositionsTable
            productId={productId}
            itemId={itemId}
            providers={providers}
          />
        </Flex>

        {isLoading && (
          <Flex
            style={{
              position: 'fixed',
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              color: '#fff !important',
              alignItems: 'center',
              justifyContent: 'center',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 3000,
              backgroundColor: 'rgb(0, 0 , 0, 0.5)',
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: 60, color: '#fff' }} spin />
              }
            />

            <Typography.Text style={{ color: '#fff', fontSize: 18 }}>
              Пересчитываем цены...
            </Typography.Text>
          </Flex>
        )}
      </Modal>
    );
  },
);
