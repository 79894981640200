export type PriceType = 'wholesale' | 'retail';

export type MatchedProduct = {
  query: string;
  productId: string;
};

export interface IDocumentEntity {
  providerName: string;
  source: any;
  documentName: string;
  createdAt: string;
  updatedAt: Date;
  processedPositions: number;
  totalPositions: number;
  status: string;
  documentLink: string;
}

export interface IBudget {
  id: string;
  name: string;
  matchingStatus?: 'DONE' | 'MATCHING';
  userId: string;
  view: 'ANALYTICS' | 'REPORT';
  objectId: string;
  createdAt: Date;
  updatedAt: Date;
  documentDate?: Date;
  optimizedAt: Date;
  totalPrice?: number;
  products: IProduct[];
  queries: IQuery[];
}

export interface IObject {
  id: string;
  name: string;
  AddressInObject?: IAddress;

  createdAt: Date;
  updatedAt: Date;
}

export interface IFileOptions {
  file: any;
  extension: string;
}

export interface IQuery {
  query: string;
  queryPrice?: number;
  queryProviderId?: string;
  count: number;
  budgetId: string;
  product: IMarketProduct[];
  matchingId: string;
}

export interface ISearchAutocomplete {
  keywords: string[];
  results: string[];
}

export interface IAddress {
  name: string;
  lat: number;
  lng: number;
  isFull?: boolean;
}

export interface IAccount {
  name: string;
  region: string;
  id: string;
}
export interface IMarketProduct {
  categoryId: string;
  categoryName: string;
  productName: string;
  productId: string;
  properties: {
    name: string;
    value: string;
  }[];
  units: {
    name: string;
    koef: number;
  }[];
}

export interface IProduct extends IMarketProduct {
  itemId: string;
  unit: string;
  count: number;
  koef: number;
  searchQuery?: string;
  queryPrice?: number;
  createdAt: Date;
  updatedAt: Date;
  excludedPositions: string[];
}

export interface ICategory {
  id: string;
  name: string;
  count: number;
}

export interface IProvider {
  id: string;
  name: string;
  type?: 'market' | 'personal';
  hidden?: boolean;
  deliveryPrice?: number;
  orderPrice?: number;
  totalPrice?: number;
  time: string;
  ts_info: string;
}

export interface IOffer {
  offerId: string;
  budgetId: string;
  offerStatus: 'active' | 'accepted';
  positionId: string;
  positionName: string;
  positionPrice: number;
  productId: string;
  productName: string;
  productPrice: number;
  storeId: string;
}

export interface IAnalyticsPosition {
  name: string;
  count: number;
  id: string;
  total: string;
  unit: string;
  koef: number;
  price: number;
  maxPrice: number;
  minPrice: number;
  avgPrice: number;
  prices: number[];
  productUnit: string;
  positionUnit: string;
  currentPrices: number[];
  personalPrices: number[];
  retailPrices: number[];
  personalPricesHistory: { date: string; price: number }[][];
}

export interface IPricesHistoryItem {
  date: string;
  price: number;
}

export interface IAnalyticsRow {
  itemId: string;
  productName: string;
  productId: string;
  positionName: string;
  positionId: string;
  providerName: string;
  providerId: string;
  positionUnit: string;
  productUnit: string;
  positionCount: number;
  productCount: number;
  price: number;
  totalPrice: number;
}

export interface IAnalyticsProduct {
  itemId: string;
  id: string;
  name: string;
  count: number;
  isFake?: boolean;
  documentPositionId?: string;
  unit: string;
  orderPrice?: number;
  positionCount?: number;
  productUnit?: string;
  positions: IAnalyticsPosition[];
}

export interface IDocument {
  name: string;
  url: string;
}

export interface IAnalytics {
  offers: IOffer[];
  updatedAt: Date;
  createdAt: Date;
  region: string;
  market: IAnalyticsRow[];
  personal: IAnalyticsRow[];
  providers: IProvider[];
  outdated: boolean;
  orderPrice: number;
  totalPrice: number;
  deliveryPrice: number;
  products: IAnalyticsProduct[];
  priceType: PriceType;
  time: string;
  ts_info: string;
}

export interface IOrder {
  status: 'ACTIVE' | 'DELETED' | 'PAYMENT';
  id: string;
  userId: string;
  name: string;
  budgetId: string;
  objectId: string;
  deliveryDate?: string;
  recipientName?: string;
}

export interface IOrderResponse extends IOrder {
  AddressInOrder?: {
    name: string;
  };
  ProductInOrder?: IProduct[];
  Document: IDocument[];
}

export interface IPosition {
  id: string;
  name: string;
  providers: {
    id: string;
    name: string;
    pricePerUnit: number;
  }[];
}

export interface IOptimizationItem {
  id: string;
  name: string;
  providerName: string;
  count: number;
  unit: string;
  pricePerUnit: number;
  link: string;
  sum: number;
  positions: IPosition[];
}

export interface IOptimizationError {
  id: string;
  name: string;
}

export interface IOptimization {
  totalPrice: number;
  deliveryPrice: number;
  servicePrice: number;
  finalPrice: number;
  cargo: number;
  length: number;
  time: number;
  ts_info: string;
  budget: IOptimizationItem[];
  errors: IOptimizationError[];
}

export enum IPayActor {
  INDIVIDUAL = 'INDIVIDUAL',
  LEGAL = 'LEGAL',
}

export enum IStep {
  PRODUCT = 'PRODUCT',
  PROVIDER = 'PROVIDER',
  DELIVERY = 'DELIVERY',
  RESULT = 'RESULT',
}

export enum IStatus {
  PICKING = 'PICKING',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  PAYMENT = 'PAYMENT',
  SHIPMENT = 'SHIPMENT',
  DONE = 'DONE',
  DELETED = 'DELETED',
}
