import { type FC, useCallback, useState } from 'react';
// import * as Moment from 'moment';

import { type IDocumentEntity } from 'types';

// import { extendMoment } from 'moment-range';

// const moment = extendMoment(Moment);

import { Button, Input, Select, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const DATE_FORMAT = 'DD.MM.YYYY';

// eslint-disable-next-line @typescript-eslint/naming-convention
enum DOCUMENT_STATUSES {
  done = 'Обработан',
  pending = 'В обработке',
  canceled = 'Отменен',
}

interface DocumentsSearchProps {
  onReload: () => Promise<IDocumentEntity[]>;
  setData;
}

export const DocumentsSearch: FC<DocumentsSearchProps> = ({
  onReload,
  setData: setDataFromProps,
  data: dataFromProps,
}) => {
  const [documentName, setDocumentName] = useState<string>('');
  const [date, setDate] = useState<string>();
  const [status, setStatus] = useState<DOCUMENT_STATUSES>();
  const [providerName, setProviderName] = useState<string>();
  const providers = Array.from(
    new Set([
      ...dataFromProps
        .filter(item => Boolean(item.providerName))
        .map(record => ({
          value: record.providerName,
          label: record.providerName,
        }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(t => t.providerName === value.providerName),
        ),
    ]),
  );

  const onDocumentNameChange = e => {
    setDocumentName(e.target.value);
  };

  const handleClearFilters = () => {
    setDocumentName('');
    setProviderName(undefined);
    setStatus(undefined);
    setDate(undefined);

    onReload();
  };

  const onStatusSelectChange = (value: string) => {
    setStatus(value);
  };

  const onDateChange = (value, formatString) => {
    setDate(value);
  };

  const onProviderChange = useCallback(value => {
    setProviderName(value);
  }, []);

  const onSearch = useCallback(() => {
    onReload().then(freshData => {
      setDataFromProps(() => {
        let filteredByQueryString = [
          ...freshData.filter(item =>
            item.documentName
              .toUpperCase()
              .includes(documentName.toUpperCase()),
          ),
        ];

        if (status) {
          filteredByQueryString = filteredByQueryString.filter(
            item => item.status === status,
          );
        }

        // if (date) {
        //   const second = moment(date[0], DATE_FORMAT);
        //   const third = moment(date[1], DATE_FORMAT);

        //   const range = moment().range(second, third);

        //   filteredByQueryString = [...freshData.filter((item) => {
        //     const first = moment(moment(item.createdAt).format(DATE_FORMAT));

        //     // return range.contains(first);
        //     // return first.within(range);
        //     return true;
        //   })];
        // }

        if (providerName) {
          filteredByQueryString = filteredByQueryString.filter(
            item => item.providerName === providerName,
          );
        }

        return filteredByQueryString;
      });
    });
  }, [onReload, setDataFromProps, status, documentName, providerName]);

  return (
    <div
      style={{
        height: '80px',
        borderRadius: '8px',
        background: '#fff',
        padding: '24px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '30px',
        lineHeight: '38px',
        gap: 24,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 24,
          flexGrow: 2,
        }}
      >
        <Input
          placeholder="Поиск по названию счета"
          value={documentName}
          onChange={onDocumentNameChange}
          suffix={<SearchOutlined />}
          style={{
            minWidth: 150,
            flexGrow: 1,
          }}
        />

        <DatePicker.RangePicker
          format={value => value.format(DATE_FORMAT)}
          style={{
            minWidth: 250,
          }}
          onChange={onDateChange}
          value={date}
        />

        <Select
          placeholder="Статус"
          optionFilterProp="children"
          onChange={onStatusSelectChange}
          style={{
            flexGrow: 2,
            minWidth: 150,
          }}
          options={[
            {
              value: 'done',
              label: DOCUMENT_STATUSES.done,
            },
            {
              value: 'pending',
              label: DOCUMENT_STATUSES.pending,
            },
            {
              value: 'canceled',
              label: DOCUMENT_STATUSES.canceled,
            },
          ]}
          value={status}
        />

        <Select
          placeholder="Поставщик"
          optionFilterProp="children"
          onChange={onProviderChange}
          style={{
            flexGrow: 2,
            minWidth: 150,
          }}
          options={providers}
          value={providerName}
        />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <Button onClick={handleClearFilters}>Сбросить</Button>

        <Button
          onClick={onSearch}
          type="primary"
          style={{
            backgroundColor: '#1677ff',
          }}
        >
          Применить
        </Button>
      </div>
    </div>
  );
};
