import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  logEvent as log,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { IAccount } from '../types';
import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
} from 'firebase/remote-config';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { LoadingScreen } from '../components/LoadingScreen';
import * as amplitude from '@amplitude/analytics-browser';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(app);
const firebaseRemoteConfig = getRemoteConfig(app);

firebaseRemoteConfig.settings.minimumFetchIntervalMillis =
  +(process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_FETCH_MINUTES || 1) *
  60 *
  1000;

export const logEvent = (name: string, data?: any) => {
  log(firebaseAnalytics, name, data);
  amplitude.track(name, data);
};

export const setUser = (account: IAccount) => {
  setUserId(firebaseAnalytics, account.id);
  setUserProperties(firebaseAnalytics, { ...account });
  amplitude.init(process.env.REACT_APP_AMPLITUDE_TOKEN || '', account.id, {
    userId: account.id,
  });
};

export const remoteConfig = getAll(firebaseRemoteConfig);

const RemoteConfigContext = createContext<typeof remoteConfig>({});

export const FirebaseProvider = ({ children }: PropsWithChildren) => {
  const [config, setConfig] = useState<typeof remoteConfig | null>(null);

  useEffect(() => {
    fetchAndActivate(firebaseRemoteConfig).then(d => {
      console.log('remote config updated', getAll(firebaseRemoteConfig));
      setConfig(getAll(firebaseRemoteConfig));
    });
  }, []);

  return (
    <RemoteConfigContext.Provider value={config || {}}>
      {config ? children : <LoadingScreen />}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfig = () => useContext(RemoteConfigContext);
