import { useCallback } from 'react';
import { useOrderDetails } from 'hooks/useOrderDetails';
import { useOrderStore } from 'store/useOrderStore';
import { useOrderPositions } from './useOrderPositions';
import { findBestPosition } from '../utils/findBestPosition';
import { findBestPositionByPersonalPrice } from '../utils/findBestPositionByPersonalPrice';
import { getBestPositionPrices } from '../utils/getBestPositionPrices';
import { getBestPrices } from '../utils/getBestPrices';

type GetIsBestPriceParams = {
  providerId: string;
  itemId: string;
};

type GetIsBestPositionPriceParams = {
  providerId: string;
  positionId: string;
  itemId: string;
};

export const useOrderPrices = (orderId: string) => {
  const orderDetails = useOrderDetails(orderId);
  const { getExcludedPositions } = useOrderPositions(orderId);
  const { priceType } = useOrderStore();

  const getIsBestPrice = useCallback(
    ({ itemId, providerId }: GetIsBestPriceParams) => {
      if (!orderDetails.data) return false;

      const bestPrices = getBestPrices(
        orderDetails.data.products,
        orderDetails.data.providers,
        priceType === 'retail',
        getExcludedPositions(itemId),
      );

      const productBestPrices = bestPrices[itemId];

      if (!productBestPrices.bestProvider) return false;

      return productBestPrices.bestProvider.id === providerId;
    },
    [getExcludedPositions, orderDetails.data, priceType],
  );

  const getBestPosition = useCallback(
    (itemId: string, providerId: string) => {
      if (!orderDetails.data) return null;

      const product = orderDetails.data.products.find(p => p.itemId === itemId);
      const providerIndex = orderDetails.data.providers.findIndex(
        p => p.id === providerId,
      );

      if (!product || providerIndex === -1) {
        return null;
      }

      const excludedPositions = getExcludedPositions(itemId);

      const positions = product.positions.filter(
        p => !excludedPositions.includes(p.id),
      );

      return findBestPosition({
        providerIndex,
        positions,
      });
    },
    [getExcludedPositions, orderDetails.data],
  );

  const getBestPositionByPersonalPrice = useCallback(
    (productId: string, providerId: string) => {
      if (!orderDetails.data) return null;

      const product = orderDetails.data.products.find(p => p.id === productId);
      const providerIndex = orderDetails.data.providers.findIndex(
        p => p.id === providerId,
      );

      if (!product || providerIndex === -1) {
        return null;
      }

      const excludedPositions = getExcludedPositions(product.itemId);

      const positions = product.positions.filter(
        p => !excludedPositions.includes(p.id),
      );

      return findBestPositionByPersonalPrice({
        providerIndex,
        positions,
      });
    },
    [getExcludedPositions, orderDetails.data],
  );

  const getIsBestPositionPrice = useCallback(
    ({ itemId, positionId, providerId }: GetIsBestPositionPriceParams) => {
      if (!orderDetails.data) return false;

      const product = orderDetails.data.products.find(p => p.itemId === itemId);
      const providerIndex = orderDetails.data.providers.findIndex(
        p => p.id === providerId,
      );

      if (!product || providerIndex === -1) {
        return null;
      }

      const position = getBestPositionPrices(
        product.positions,
        orderDetails.data.providers,
        priceType === 'retail',
      );

      if (!position) return false;

      return (
        position.positionId === positionId && position.providerId === providerId
      );
    },
    [orderDetails.data, priceType],
  );

  const getTotalPersonalPriceByProvider = useCallback(
    (providerId: string) => {
      if (!orderDetails.data) return 0;

      const providerIndex = orderDetails.data.providers.findIndex(
        p => p.id === providerId,
      );

      let totalPersonalPrice = 0;

      orderDetails.data.products.forEach(product => {
        const position = getBestPosition(product.itemId, providerId);
        if (position) {
          // TODO: Same as src/pages/Order/components/OrderTab/PricePopover/index.tsx:214
          const personalPrice = position.currentPrices[providerIndex];
          const personalPriceSum = personalPrice * position.count;
          totalPersonalPrice += personalPriceSum;
        }
      });

      return totalPersonalPrice;
    },
    [getBestPosition, orderDetails.data],
  );

  return {
    getIsBestPrice,
    getIsBestPositionPrice,
    getBestPosition,
    getBestPositionByPersonalPrice,
    getTotalPersonalPriceByProvider,
  };
};
