import React from 'react';
import { Button, Modal } from 'react-daisyui';
import { useAnalytics } from '../hooks/useAnalytics';
import { useParams } from 'react-router';
import { ProvidersList } from './ProvidersList';
import { useOrderDetails } from '../hooks/useOrderDetails';

interface Props {
  onClose: VoidFunction;
  open: boolean;
}

export const ProvidersModal = ({ onClose, open }: Props) => {
  const { budgetId, orderId } = useParams();
  const analytics = useAnalytics(budgetId || '');
  const orderDetails = useOrderDetails(orderId || '');

  if (!analytics.data?.providers && !orderDetails.data?.providers) return null;
  return (
    <Modal open={open}>
      <Modal.Header>Выбрать поставщиков</Modal.Header>
      <ProvidersList
        providers={
          analytics.data?.providers || orderDetails.data?.providers || []
        }
      />
      <Button className={'mt-5 w-full'} onClick={onClose}>
        Готово
      </Button>
    </Modal>
  );
};
