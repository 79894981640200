import type { IAnalyticsPosition } from 'types';

type FindBestPositionParams = {
  providerIndex: number;
  positions: IAnalyticsPosition[];
};

export const findBestPositionByPersonalPrice = ({
  providerIndex,
  positions,
}: FindBestPositionParams): IAnalyticsPosition => {
  let minPrice = Infinity;
  let bestPosition: IAnalyticsPosition | null = null;

  positions.forEach(position => {
    const price = position.currentPrices[providerIndex] * position.count;
    if (price > 0 && price < minPrice) {
      minPrice = price;
      bestPosition = position;
    }
  });

  return bestPosition;
};
