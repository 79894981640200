import { useSearchParams } from 'react-router-dom';

export const useAnalyticsView = (): 'analytics' | 'order' | 'report' => {
  const [searchParams] = useSearchParams();

  const view = searchParams.getAll('view')[0];

  switch (view) {
    case 'analytics':
      return view;

    case 'order':
      return view;

    case 'report':
      return view;

    default:
      return 'analytics';
  }
};
