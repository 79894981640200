import { useState } from 'react';
import { PriceTable } from '../../../pages/Analytics/components/PriceTable';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { Button } from 'react-daisyui';
import { type IAnalyticsRow } from '../../../types';

interface Props {
  budgetId: string;
  loading: boolean;
  onSubmit: (positions: IAnalyticsRow[]) => void;
}

export const Step2 = ({ budgetId, onSubmit, loading }: Props) => {
  const { data } = useAnalytics(budgetId);
  const [selected, setSelected] = useState<string[]>([]);

  return (
    <>
      <PriceTable
        onChange={setSelected}
        selectable
        positions={data?.market || []}
      />

      <Button
        loading={loading}
        onClick={() =>
          onSubmit(
            selected.map(
              id => data?.market.find(({ positionId }) => positionId === id)!,
            ),
          )
        }
        disabled={!selected.length}
      >
        Создать заявку
      </Button>
    </>
  );
};
