import { useQuery } from '@tanstack/react-query';
import { type IBudget } from 'types';
import { useApi } from './useApi';

export const useBudgetDetails = (id: string) => {
  const api = useApi();

  return useQuery<IBudget>(['budget', id], () => api.getBudget(id), {
    enabled: !!id,
  });
};
