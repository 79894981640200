import { type FC, type PropsWithChildren, type ReactNode } from 'react';

interface Props {
  name?: string;
  items: ReactNode[];
}

const Item: FC<PropsWithChildren> = ({ children }) => (
  <div className="flex bbudget-b-2 bbudget-b-gray-100">{children}</div>
);

export const Block: FC<Props> = ({ name, items }) => {
  return (
    <div className="flex flex-col gap-2">
      {name && <p className="text-xl">{name}</p>}

      <div className="bg-white flex flex-col">
        {items.map(i => (
          <Item>{i}</Item>
        ))}
      </div>
    </div>
  );
};
