import { type FC, useMemo } from 'react';
import { Button } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { Block } from '../../components/Block';
import { BudgetItem } from '../../components/BudgetItem';
import { useApi } from 'hooks/useApi';

interface OrdersTabPanelProps {
  objectId: string;
  orders?: any[];
  onOrderClick: any;
  setDeletingBudget: any;
  setRenamingBudget: any;
}

export const OrdersTabPanel: FC<OrdersTabPanelProps> = ({
  objectId,
  orders,
  onOrderClick,
  setDeletingBudget,
  setRenamingBudget,
}) => {
  const navigate = useNavigate();
  const api = useApi();

  const ordersMemoized = useMemo(() => {
    if (!orders ?? orders?.length === 0) return [];

    return orders.map(item => (
      <BudgetItem
        onClick={() => onOrderClick(item)}
        onDelete={() => setDeletingBudget(item)}
        onRename={() => setRenamingBudget(item)}
        {...item}
      />
    ));
  }, [onOrderClick, orders, setDeletingBudget, setRenamingBudget]);

  const onCreateOrder = async () => {
    const name = `от ${moment(new Date()).format('DD.MM.YYYY HH:mm')}`;

    const { id } = await api.createBudget(objectId, name);
    await api.deleteBudget(id);

    navigate(`/object/${objectId}/budget/${id}?view=order`);
  };

  return (
    <>
      <Button
        onClick={onCreateOrder}
        icon={<PlusOutlined />}
        style={{
          marginBottom: orders.length ? 15 : 0,
        }}
      >
        Создать заявку
      </Button>

      <Block items={ordersMemoized} />
    </>
  );
};
