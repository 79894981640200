import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';

export const useExcludedProviders = () => {
  const api = useApi();
  return useQuery<string[]>(
    ['providers-excluded'],
    () => api.getExcludedProviders(),
    {
      keepPreviousData: true,
    },
  );
};
