import { useState } from 'react';
import { Button } from 'react-daisyui';
import { type IMarketProduct, type IQuery } from '../../../types';
import { money } from '../../../utils/moneyTool';

interface Props {
  onClick?: VoidFunction;
  onManualMatch: () => void;
  onResolve: (product: IMarketProduct) => void;
  queryItem: IQuery;
}
export const QueryItem = ({
  onResolve,
  onClick,
  onManualMatch,
  queryItem,
}: Props) => {
  const [hidden, setHidden] = useState(false);

  if (hidden) return null;

  return (
    <div
      onClick={onClick}
      className="bg-white rounded p-5 shadow hover:bg-gray-50 cursor-pointer justify-between"
    >
      <div className="flex flex-row mb-5 justify-between">
        <p className="opacity-50">{queryItem.query}</p>
        <p className="opacity-50">
          {!!queryItem.queryPrice && money(queryItem.queryPrice)} x{' '}
          {queryItem.count}
        </p>
      </div>

      <div className="flex flex-row gap-5 flex-wrap">
        {queryItem.product
          ?.filter(p => p)
          .map(p => (
            <Button
              onClick={() => {
                onResolve(p);
                setHidden(true);
              }}
              variant="outline"
            >
              {p.productName}
            </Button>
          ))}

        <Button
          onClick={onManualMatch}
          variant="link"
          className="text-blue-500"
        >
          Подобрать вручную
        </Button>
      </div>
    </div>
  );
};
