import { useState } from 'react';
import moment from 'moment';
import { Button } from 'react-daisyui';
import { Link, useNavigate } from 'react-router-dom';
import { OrderItem } from './components/OrderItem';
import { CreateOrderModal } from '../../components/CreateOrderModal';
import { Layout } from '../../components/Layout';
import { LoadingScreen } from '../../components/LoadingScreen';
import { PageHeader } from '../../components/PageHeader';
import { useApi } from '../../hooks/useApi';
import { useOrders } from '../../hooks/useOrders';

export const Orders = () => {
  const orders = useOrders();
  const api = useApi();
  const [createOrderModalOpened, setCreateOrderModalOpened] = useState(false);
  const navigate = useNavigate();

  const onDeleteOrder = async (id: string) => {
    await api.deleteOrder(id);
    await orders.refetch();
  };

  const onCreateInstantOrder = async () => {
    const name = `от ${moment(new Date()).format('DD.MM.YYYY HH:mm')}`;
    let newObject: string;
    const objects = await api.getObjects();
    const found = objects.find(object => object.name === 'Без объекта');

    if (found) {
      newObject = found.id;
    } else {
      const { id } = await api.createObject('Без объекта');
      newObject = id;
    }

    const { id } = await api.createBudget(newObject, name);
    await api.deleteBudget(id);

    navigate(`/object/${newObject}/budget/${id}?view=order`);
  };

  if (!orders.data) return <LoadingScreen />;

  return (
    <>
      <Layout>
        <div className="flex flex-col gap-3 w-full">
          <PageHeader>
            <div className="flex flex-row justify-between items-center">
              <p className="text-3xl font-bold">Ваши заявки</p>
              <Button onClick={onCreateInstantOrder}>Cоздать заявку</Button>
            </div>
          </PageHeader>

          {orders.data?.map(order => (
            <Link to={`/order/${order.id}`} key={order.id}>
              {/* @ts-ignore */}
              <OrderItem
                onDelete={() => onDeleteOrder(order.id)}
                onRename={() => null}
                {...order}
              />
            </Link>
          ))}
        </div>
      </Layout>

      <CreateOrderModal
        open={createOrderModalOpened}
        onClose={() => setCreateOrderModalOpened(false)}
      />
    </>
  );
};
