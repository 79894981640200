import { useState } from 'react';
import { Button } from 'react-daisyui';
import { useParams } from 'react-router';
import { AppExportModal } from './AppExportModal';
import { BudgetItem } from './BudgetItem';
import { ManualMatchingModal } from './ManualMatchingModal';
import { QueryItem } from './QueryItem';
import { useAccount } from '../../../hooks/useAccount';
import { useApi } from '../../../hooks/useApi';
import { useBudgetDetails } from '../../../hooks/useBudgetDetails';
import {
  type IMarketProduct,
  type IProduct,
  type IQuery,
} from '../../../types';
import { useAnalyticsView } from '../../Analytics/hooks/useAnalyticsView';

interface Props {
  onEdit: (product: IProduct) => void;
  onSubmit: VoidFunction;
  submitLoading: boolean;
  setMatchedProducts: React.Dispatch<React.SetStateAction<any[]>>;
}

export const Cart = ({
  onEdit,
  onSubmit,
  submitLoading,
  setMatchedProducts,
}: Props) => {
  const [selectedQuery, setSelectedQuery] = useState<IQuery>();
  const [manualMatchingModalOpened, setManualMatchingModalOpened] =
    useState(false);
  const [appExportModalOpened, setAppExportModalOpened] = useState(false);
  const view = useAnalyticsView();
  const account = useAccount();
  const { budgetId } = useParams();
  const api = useApi();
  const budgetDetails = useBudgetDetails(budgetId!);

  const onDeleteQuery = async (query: string) => {
    await api.deleteQuery(budgetId!, query);
    await budgetDetails.refetch();
  };

  const onExportToApp = () => setAppExportModalOpened(true);

  const onResolveQuery = async (
    query: IQuery,
    product: IMarketProduct,
    method: 'AUTO' | 'MANUAL' | 'NEW',
  ) => {
    await api.deleteQuery(budgetId!, query.query);
    await api.addProduct(budgetId!, {
      ...product,
      unit: product.units[0].name,
      koef: product.units[0].koef,
      count: Number(query.count) / product.units[0].koef ?? 1,
      productId: product.productId,
      searchQuery: query.query,
      queryPrice: query?.queryPrice || 0,
    });
    if (method === 'AUTO' || method === 'MANUAL') {
      setMatchedProducts(prev => [
        ...prev,
        {
          query: query.query,
          productId: product.productId,
        },
      ]);
    }
    await budgetDetails.refetch();
    await api.reportMatching(query.matchingId, method, product.productId);
  };

  return (
    <div className="flex flex-1 p-5 flex-col">
      <div className="flex flex-roa gap-2">
        <p className="mb-5 text-lg font-bold">Ваша корзина:</p>

        <p className="mb-5 text-lg opacity-50">
          {`${
            (budgetDetails.data?.queries?.length ?? 0) +
            (budgetDetails.data?.products?.length ?? 0)
          } позиций`}
        </p>

        {!!budgetDetails.data?.queries?.length && (
          <p className="mb-5 text-lg opacity-50">
            | {budgetDetails.data?.queries?.length ?? 0} требуют уточнения
          </p>
        )}
      </div>

      <div className="flex flex-1 flex-col gap-2 overflow-auto">
        {budgetDetails.data?.queries?.map(queryItem => (
          <QueryItem
            queryItem={queryItem}
            key={queryItem.query}
            onResolve={p => {
              onResolveQuery(queryItem, p, 'AUTO');
            }}
            onManualMatch={() => {
              setManualMatchingModalOpened(true);
              setSelectedQuery(queryItem);
            }}
          />
        ))}

        {budgetDetails.data?.products?.map(s => (
          <BudgetItem key={s.productId} onClick={() => onEdit(s)} {...s} />
        ))}
      </div>

      <div className="py-5 flex flex-row gap-5">
        {account.data?.id === 'web00000019' && (
          <Button onClick={onExportToApp} variant="outline">
            Экспорт в приложение
          </Button>
        )}

        <Button
          loading={submitLoading}
          onClick={onSubmit}
          className="flex flex-1"
        >
          {view === 'analytics' && 'Получить аналитику'}
          {view === 'order' && 'Получить анализ цен'}
          {view === 'report' && 'Получить отчет'}
        </Button>
      </div>

      {appExportModalOpened && (
        <AppExportModal onClose={() => setAppExportModalOpened(false)} />
      )}

      {manualMatchingModalOpened && !!selectedQuery && (
        <ManualMatchingModal
          onResolve={(queryItem, product, method) => {
            onResolveQuery(queryItem, product, method);
          }}
          currentQuery={selectedQuery}
          onDeleteQuery={onDeleteQuery}
          onClose={() => setManualMatchingModalOpened(false)}
        />
      )}
    </div>
  );
};
