import { IAnalyticsRow } from '../../../types';
import { Table } from 'antd';
import React from 'react';
import { Button } from 'react-daisyui';
import { money } from '../../../utils/moneyTool';
import { useBudgetDetails } from '../../../hooks/useBudgetDetails';
import { useParams } from 'react-router';
import { LoadingScreen } from '../../../components/LoadingScreen';

interface Props {
  products: IAnalyticsRow[];
  onDetails: (productId: string) => void;
}

export const ReportTable = ({ products, onDetails }: Props) => {
  const { budgetId } = useParams();

  const budget = useBudgetDetails(budgetId!);

  if (!budget?.data?.products?.length) return <LoadingScreen />;

  const data = products
    .map(e => {
      const p = budget.data?.products.find(
        ({ productId }) => productId === e.productId,
      );

      if (!p) throw new Error('product not found');

      return {
        ...e,
        count: `${e.positionCount} ${e.positionUnit}`,
        totalPrice: money(e.totalPrice),
        documentPrice: money((p.queryPrice || 0) * p.count),
        profit: e.totalPrice - (p.queryPrice || 0) * p.count,
      };
    })
    .sort((a, b) => a.profit - b.profit);

  const totalProfit = data.reduce((acc, val) => {
    if (val.profit < 0) return acc + Math.abs(val.profit);
    return acc;
  }, 0);

  const columns = [
    {
      title: 'Номенклатура контрагента',
      dataIndex: 'positionName',
      key: 'positionName',
      fixed: 'left',
    },
    {
      title: 'Кол-во',
      dataIndex: 'count',
      key: 'count',
      fixed: 'left',
    },
    {
      title: 'Ваша цена',
      dataIndex: 'documentPrice',
      key: 'documentPrice',
      fixed: 'left',
    },
    {
      title: 'Лучшая цена СтройСети',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      fixed: 'left',
    },
    {
      title: 'Выгода',
      dataIndex: 'profit',
      key: 'profit',
      fixed: 'left',
      render: (price: number) =>
        price < 0 ? (
          <div className={'p-1 bg-red-400 rounded-lg text-center'}>
            {money(price)}
          </div>
        ) : (
          <div className={'p-1 bg-green-100 rounded-lg text-center'}>
            {money(price)}
          </div>
        ),
    },
    {
      title: '',
      dataIndex: 'productId',
      render: (productId: string) => (
        <Button variant={'outline'} onClick={() => onDetails(productId)}>
          Настроить
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className={'flex flex-row gap-1'}>
        <p className={'mb-5 text-lg'}>Итого потерь:</p>
        <p className={'mb-5 text-lg text-red-500'}>{money(totalProfit)}</p>
      </div>
      <Table
        size={'middle'}
        dataSource={data}
        // @ts-ignore
        columns={columns}
        rowKey={'id'}
        pagination={false}
        locale={{ emptyText: 'Нет данных' }}
      />
    </>
  );
};
