import {
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios, { type AxiosResponse } from 'axios';
import type {
  IDocumentEntity,
  IAddress,
  IAnalyticsRow,
  IBudget,
  IFileOptions,
  IMarketProduct,
  IObject,
  IOptimization,
  IOrder,
  IProduct,
  ISearchAutocomplete,
  PriceType,
  MatchedProduct,
} from 'types';
import type { OrderPaymentParams, OrderPaymentResponse } from '~/types/api';

interface IApiContext {
  token: null | string;
  setToken: (token: null | string) => void;
}

const ApiContext = createContext({} as IApiContext);

export const ApiContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState<null | string>(null);

  useEffect(() => {
    const localToken = localStorage.getItem('stroysetToken');

    if (localToken) setToken(localToken);
  }, []);

  return (
    <ApiContext.Provider value={{ token, setToken }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const { token, setToken: setTokenState } =
    useContext<IApiContext>(ApiContext);

  const baseURL =
    window.location.hostname === 'app.stroyset.ru'
      ? 'https://api.stroyset.ru/bff'
      : 'https://api.stage.stroyset.ru/bff';

  const http = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${token}` },
    withCredentials: true,
  });

  const setToken = useCallback(
    (newToken: string | null) => {
      setTokenState(newToken);

      if (newToken) {
        localStorage.setItem('stroysetToken', newToken);
      }
    },
    [setTokenState],
  );

  const download = async (url: string, fileName: string) => {
    const { data } = await http.get(url, { responseType: 'blob' });
    const href = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); // or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  http.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 401) {
        setToken(null);
      } else {
        // if (error?.response?.status?.toString()[0] !== '4')
        // toast.error(`Сбой. Код: ${error?.message}`);
        // boundary.showBoundary(`Axios Error: ${error?.message}`);
      }
    },
  );

  const getUser = async () => {
    const { data } = await http.get('/user');
    return data;
  };

  return {
    token,
    setToken,
    async updateAddress(budgetId: string, address: IAddress) {
      delete address.isFull;
      const { data } = await http.put(`/budget/${budgetId}/address`, address);
      return data;
    },

    download,

    async duplicateBudget(budgetId: string) {
      const { data } = await http.post(`/budget/${budgetId}/duplicate`);
      return data;
    },

    async uploadAudio(budgetId: string, file: any) {
      const formData = new FormData();
      formData.append('file', file);
      await http.post(`/budget/${budgetId}/upload/audio`, formData);
    },

    async uploadFile(budgetId: string, { file, extension }: IFileOptions) {
      // const filename = encodeURIComponent(file.name);

      const formData = new FormData();
      // TODO
      formData.append('file', file, 'file.xlsx');
      formData.append('extension', extension);

      const request = await http.post(
        `/budget/${budgetId}/upload/file`,
        formData,
        {
          timeout: 1000000000,
        },
      );

      return request;
    },

    async uploadFileWithoutMatching({
      file,
      extension,
    }: IFileOptions): Promise<AxiosResponse> {
      const filename = encodeURIComponent(file.name);

      const formData = new FormData();
      formData.append('filename', filename);
      formData.append('file', file, filename);
      formData.append('extension', extension);

      const request = await http.post(
        '/budget/1/upload/file/without-matching',
        formData,
        {
          timeout: 1000000000,
          headers: {
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          },
        },
      );

      return request;
    },

    async uploadText(budgetId: string, queries: string[]) {
      await http.post(`/budget/${budgetId}/upload/text`, { queries });
    },

    async getAllUsers() {
      const { data } = await http.get('/user/all');
      return data;
    },

    async getAddress(budgetId: string) {
      const { data } = await http.get(`/budget/${budgetId}/address`);
      return data;
    },

    async getApiVersion(): Promise<number> {
      const { data } = await http.get('/healthcheck');
      return data?.version || 0;
    },

    async login(phone: string, password: string) {
      const { data } = await http.post('/auth/login', { phone, password });
      return data.token as string;
    },

    getUser,

    logout() {
      setToken(null);
      localStorage.removeItem('stroysetToken');
    },

    async healthcheck() {
      const { data } = await http.get('healthcheck');
      return data?.health;
    },

    async finishPicking(budgetId: string) {
      const { data } = await http.post(`/budget/${budgetId}/payment`);
      return data;
    },

    async updateProduct(
      budgetId: string,
      itemId: string,
      payload: Partial<{
        productId: string;
        count: number;
        unit: string;
        koef: number;
        name: string;
      }>,
    ) {
      const { data } = await http.put(
        `/v2/budgets/${budgetId}/products/${itemId}`,
        payload,
      );
      return data;
    },

    async searchAutocomplete(query: string): Promise<ISearchAutocomplete> {
      const { data } = await http.post('/product/search/autocomplete', {
        query,
      });
      return data;
    },

    async searchTop(): Promise<IMarketProduct[]> {
      const { data } = await http.get('/product/search/top/', {});
      return data;
    },

    async search(query: string, categoryId: string): Promise<IMarketProduct[]> {
      const { data } = await http.post('/product/search/', {
        query,
        categoryId,
      });
      return data;
    },

    async positionSearch(
      query: string,
      productId: string,
    ): Promise<IMarketProduct[]> {
      const { data } = await http.post(
        `/product/${productId}/search/position`,
        {
          query,
        },
      );
      return data;
    },

    async getProviderEmails() {
      const { data } = await http.get('/provider/emails');
      return data;
    },

    async saveProviderEmail(email: string) {
      const { data } = await http.put(`/provider/emails/${email}`);
      return data;
    },

    async sendProviderEmails(emails: string[], text: string) {
      const { data } = await http.post('/provider/emails/', { emails, text });
      return data;
    },

    async addProduct(
      budgetId: string,
      {
        productId,
        count,
        unit,
        koef,
        searchQuery,
        queryPrice,
      }: {
        productId: string;
        count: number;
        unit: string;
        koef: number;
        searchQuery?: string;
        queryPrice?: number;
      },
    ) {
      const { data } = await http.post(`/product/${budgetId}`, {
        productId,
        count,
        unit,
        koef,
        searchQuery,
        queryPrice,
      });
      return data;
    },

    async deleteProduct(budgetId: string, itemId: string) {
      const { data } = await http.delete(
        `/v2/budgets/${budgetId}/products/${itemId}`,
      );
      return data;
    },

    async deleteOrder(orderId: string) {
      const { data } = await http.delete(`/order/${orderId}`);
      return data;
    },

    async deleteQuery(budgetId: string, query: string) {
      const { data } = await http.delete(`/budget/${budgetId}/query/`, {
        data: { query },
      });
      return data;
    },

    async getCategories() {
      const { data } = await http.get('/product/categories');
      return data;
    },

    async getProviders() {
      const { data } = await http.get('/provider');
      return data;
    },

    async getProducts(query: string, page: number) {
      const { data } = await http.get(`/product?search=${query}&page=${page}`);
      return data;
    },

    async getOrders() {
      const { data } = await http.get('/order/');
      return data;
    },

    async getOrdersInObject(objectId: string) {
      const { data } = await http.get(`/order/object/${objectId}`);
      return data;
    },

    async getOrder(id: string) {
      const { data } = await http.get(`/order/${id}`);
      return data;
    },

    async getOrderMap(id: string) {
      const { data } = await http.get(`/order/${id}/map`);
      return data;
    },

    async getBudgetProductsReport(budgetId: string) {
      const { data } = await http.get(
        `/report/budget/${budgetId}/products/view`,
      );
      return data;
    },

    async getOrderProductsReport(orderId: string) {
      const { data } = await http.get(`/report/order/${orderId}/products/view`);
      return data;
    },

    async replaceMatchingHistoryBudgetId(budgetId: string, orderId: string) {
      const { data } = await http.put(
        `/report/matching/replace/budget/${budgetId}/order/${orderId}`,
      );
      return data;
    },

    async getPositionsInOrder(id: string) {
      const { data } = await http.get(`/order/${id}/positions`);
      return data;
    },

    async getOrderDetails(id: string) {
      const { data } = await http.get(`/order/${id}/details`);
      return data;
    },

    async putPositionInOrder(orderId: string, positions: IAnalyticsRow[]) {
      const { data } = await http.put(`/order/${orderId}`, positions);
      return data;
    },

    async exportBudgetToApp(budgetId: string, userId: string) {
      const { data } = await http.post(
        `/budget/${budgetId}/export/app/user/${userId}`,
      );
      return data;
    },

    async updateOrderAddress(orderId: string, { name, lat, lng }: IAddress) {
      const { data } = await http.put(`/order/${orderId}/address`, {
        name,
        lat,
        lng,
      });
      return data;
    },

    async orderPayment(orderId: string, options: OrderPaymentParams) {
      const { data } = await http.post<OrderPaymentResponse>(
        `/order/${orderId}/payment`,
        options,
      );
      return data;
    },

    async updateOrder(
      orderId: string,
      payload: Partial<
        {
          name: string;
          budgetId: string;
          priceType: 'DEFAULT' | 'RETAIL';
          objectId: string;
          deliveryDate?: string;
          recipientName: string;
          products: {
            id: string;
            query: string;
            count: number;
            unit: string;
          }[];
          queries?: {
            query: string;
            count: number;
          }[];
        } & IOrder
      >,
    ) {
      const { data } = await http.put(`/order/${orderId}`, payload);
      return data;
    },

    async updateOrderProduct(itemId: string, payload: Partial<IProduct>) {
      const { data } = await http.put(`/order/products/${itemId}`, payload);
      return data;
    },

    async createOrder({
      name,
      deliveryDate,
      recipientName,
      budgetId,
      objectId,
      products,
      queries,
    }: CreateOrderData) {
      const { data } = await http.post('/order/', {
        name,
        budgetId,
        objectId,
        queries,
        products,
        deliveryDate,
        recipientName,
      });
      return data;
    },

    async updateProviders(excluded: string[]) {
      const { data } = await http.put('/provider', { excluded });
      return data;
    },

    async updatePositions(excluded: string[]) {
      const { data } = await http.put('/position', { excluded });
      return data;
    },

    async reportMatching(
      matchingId: string,
      matchingMethod: 'AUTO' | 'MANUAL' | 'NEW',
      resolvedProductId: string,
    ) {
      const { data } = await http.put('/report/matching', {
        matchingId,
        matchingMethod,
        resolvedProductId,
      });
      return data;
    },

    async getBudget(budgetId: string) {
      const { data } = await http.get(`/budget/${budgetId}`);
      return data;
    },

    async getBudgets(objectId?: string) {
      const { data } = await http.get(
        objectId ? `/object/${objectId}/budget` : '/budget',
      );
      return data;
    },

    async getObjects() {
      const { data } = await http.get('/object');
      return data;
    },

    async getObject(id: string) {
      const { data } = await http.get(`/object/${id}`);
      return data;
    },

    async getAnalytics(budgetId: string) {
      const { data } = await http.get(`/budget/${budgetId}/analytics`);
      return data;
    },

    async getOptimizationStatus(budgetId: string) {
      const { data } = await http.get(
        `/budget/${budgetId}/optimization/status`,
      );
      return data;
    },

    async batchUpdateVerifiedPositions(
      data: { positionId: string; productId: string; verified: boolean }[],
    ) {
      await http.post('/position/batchUpdateVerified', {
        positions: data,
      });
    },

    async includePosition(positionId: string) {
      await http.put(`/position/${positionId}`);
    },

    async excludePosition(positionId: string) {
      await http.delete(`/position/${positionId}`);
    },

    async excludePositions({
      orderId,
      productId,
      excludedPositions,
    }: {
      orderId: string;
      productId: string;
      excludedPositions: string[];
    }) {
      await http.put(`/product/${orderId}/${productId}/exclude-position`, {
        excludedPositions,
      });
    },

    async getPositionProperties(positionId: string) {
      const { data } = await http.get(`/position/${positionId}/properties`);
      return data;
    },

    async addFavoriteProduct(positionId: string) {
      const { data } = await http.post(`/products/${positionId}/favorites`);
      return data;
    },

    async removeFavoriteProduct(positionId: string) {
      const { data } = await http.delete(`/products/${positionId}/favorites`);
      return data;
    },

    async getFavoriteProducts() {
      const { data } = await http.get('/product/favorites');
      return data;
    },

    async searchText(
      query: string,
      categories: string[],
      onlyFavorites: boolean,
    ) {
      const { data } = await http.post(`/search/instant`, {
        query,
        categories,
        onlyFavorites,
      });
      return data;
    },

    async getExcludedPositions() {
      const response = await http.get('/position/excluded');

      // TODO: to be deleted
      // temporary crutch to allow endpoint work without auth
      if (response === undefined) return [];
      return response.data;
    },

    async includeProvider(providerId: string) {
      await http.put(`/provider/${providerId}`);
    },

    async excludeProvider(providerId: string) {
      await http.delete(`/provider/${providerId}`);
    },

    async getExcludedProviders() {
      const response = await http.get('/provider/excluded');

      // TODO: to be deleted
      // temporary crutch to allow endpoint work without auth
      if (response === undefined) return [];
      return response.data;
    },

    async getBudgetReport(budgetId: string) {
      const { data } = await http.get(`/report/budget/${budgetId}/market`);
      return data;
    },

    async downloadBudgetReport(
      budgetId: string,
      providerId: string,
      providerName: string,
    ) {
      await download(
        `/report/budget/${budgetId}/market/provider/${providerId}/download`,
        `Счет ${providerName}.xlsx`,
      );
    },

    async downloadExcel(json: any, filename: string) {
      await download(
        `/report/excel/download?json=${JSON.stringify(json)}`,
        filename,
      );
    },

    async downloadProductsReport(budgetId: string, budgetName: string) {
      await download(
        `/report/budget/${budgetId}/products/download`,
        `Смета ${budgetName}.xlsx`,
      );
    },

    async downloadOrderReport(budgetId: string, budgetName: string) {
      await download(
        `/report/order/${budgetId}/products/download`,
        `Смета ${budgetName}.xlsx`,
      );
    },

    async optimizeBudget(
      budgetId: string,
      budget: IBudget,
    ): Promise<IOptimization> {
      const { data } = await http.post(`/budget/${budgetId}/optimization`, {
        budget,
      });
      return data;
    },

    async acceptOffer(orderId: string, offerId: string): Promise<any> {
      const { data } = await http.post(`/order/${orderId}/offer/${offerId}`);
      return data;
    },

    async optimizeOrder(
      orderId: string,
      priceType: PriceType = 'wholesale',
      matchedProducts: MatchedProduct[] = [],
    ): Promise<any> {
      let geo: any;
      let map: any;

      try {
        map = await this.getOrderMap(orderId);
      } finally {
        // @ts-ignore
        geo = !!map && (await window.calculate(map));
        http.post(`/order/${orderId}/optimization`, {
          geo,
          priceType,
          matchedProducts,
        });

        return new Promise(res => setTimeout(() => res(true), 2000));
      }
    },

    async getOptimization(budgetId: string) {
      const { data } = await http.get(`/budget/${budgetId}/optimization`);
      return data;
    },

    async createObject(name?: string, address?: string) {
      const { data } = await http.post('/object', { name, address });
      return data;
    },

    async optimizeWeb(products: IProduct[]) {
      const { data } = await http.post('/budget/1/optimization/web', products);
      // @ts-ignore
      // document.querySelector('html').innerHTML = data;
      return data;
    },

    async createNewProduct(
      query: string,
      price: number,
      queryProviderId?: string,
    ) {
      const { data } = await http.post('product', {
        query,
        price,
        queryProviderId,
      });
      return data;
    },

    async createBudget(
      objectId: string,
      name?: string,
      view?: 'ANALYTICS' | 'REPORT',
    ) {
      const { data } = await http.post(`object/${objectId}/budget`, {
        name,
        view,
      });
      return data;
    },

    async updateBudget(budgetId: string, body: Partial<IBudget>) {
      const { data } = await http.put(`/budget/${budgetId}`, body);
      return data;
    },

    async deleteBudget(budgetId: string) {
      const { data } = await http.delete(`/budget/${budgetId}`);
      return data;
    },

    async deleteObject(objectId: string) {
      const { data } = await http.delete(`/object/${objectId}`);
      return data;
    },

    async updateObject(objectId: string, dto: Partial<IObject>) {
      const { data } = await http.put(`/object/${objectId}`, dto);
      return data;
    },

    async updateObjectAddress(objectId: string, { name, lat, lng }: IAddress) {
      const { data } = await http.put(`/object/${objectId}/address`, {
        name,
        lat,
        lng,
      });
      return data;
    },

    async confirmPayment(budgetId: string) {
      await http.post(`/budget/${budgetId}/payment/confirm`);
    },

    async autoCompleteCity(query: string): Promise<IAddress[]> {
      const { data } = await axios.post(
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        {
          locations_boost: [{ kladr_id: '5000001100000' }],
          locations: [{ kladr_id: '7400000000000' }],
          restrict_value: false,
          query,
          count: 5,
        },
        {
          headers: {
            authorization: 'Token d5c53d595fb98a4c82c29fc9cf6a6d07d45981a3',
          },
        },
      );

      // @ts-ignore
      return data.suggestions.map(({ value, data }) => ({
        name: value,
        value,
        label: value,
        lat: +data.geo_lat,
        lng: +data.geo_lon,
      }));
    },

    async getAllUserDocuments(userId: string): Promise<IDocumentEntity[]> {
      const data = (await http.get(
        `/document/${userId}/all`,
      )) as IDocumentEntity[];

      return data;
    },
  };
};

interface CreateOrderData {
  name: string;
  deliveryDate: string;
  recipientName: string;
  budgetId: string;
  objectId: string;
  products: {
    id: string;
    query: string;
    count: number;
    unit: string;
  }[];
  queries?: {
    query: string;
    count: number;
  }[];
}
