import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

export const dateTool = {
  display(date: Date) {
    const m = moment(date);
    return m.calendar();
  },
};
