import { useApi } from '../../../hooks/useApi';
import { useQuery } from '@tanstack/react-query';

interface IAnalyticsProduct {
  type: 'product' | 'query';
  query: string;
  productName: string;
  count: number;
  unit: string;
  minTotalPrice: number;
  maxTotalPrice: number;
}

export const useOrderProducts = (orderId: string) => {
  const api = useApi();

  return useQuery<IAnalyticsProduct[]>(['order-products', orderId], () =>
    api.getOrderProductsReport(orderId),
  );
};
