import { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'react-daisyui';
import { useForm } from 'react-hook-form';

interface Props {
  onClose: VoidFunction;
  open: boolean;
  onSubmit: ({ name }: { name: string }) => void;
}

export const AddModal = ({ onClose, open, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, setFocus } = useForm<{ name: string }>(
    {
      // name: '',
      // // address: '',
    },
  );

  useEffect(() => {
    if (open) {
      reset();
      setLoading(false);
      setTimeout(() => setFocus('name'), 100);
    }
  }, [open]);

  const onSubmitForm = (d: any) => {
    onSubmit(d);
    setLoading(true);
  };

  return (
    <Modal open={open}>
      <Modal.Header>Добавить</Modal.Header>

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Modal.Body className="flex flex-col gap-5">
          <Input
            placeholder="Название"
            {...register('name', { required: true })}
          />
          {/* <Input
            placeholder={'Адрес'}
            {...register('address', { required: true })}
          /> */}
          <Button loading={loading} type="submit">
            Подтвердить
          </Button>

          <Button type="button" onClick={onClose} variant="outline">
            Отмена
          </Button>
        </Modal.Body>
      </form>
    </Modal>
  );
};
