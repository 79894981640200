import { useQuery } from '@tanstack/react-query';
import { IBudget } from 'types';
import { useApi } from './useApi';

export const useOrdersInObject = (objectId: string) => {
  const api = useApi();
  return useQuery<IBudget[]>(
    ['orders', objectId],
    () => api.getOrdersInObject(objectId),
    {
      enabled: !!objectId,
    },
  );
};
