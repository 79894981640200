import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-daisyui';

interface Props {
  onClose: VoidFunction;
  open: boolean;
  onSubmit: VoidFunction;
  text?: string;
}

export const DeleteModal = ({ open, onClose, onSubmit, text }: Props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(false);
    }
  }, [open]);

  return (
    <Modal open={open}>
      <Modal.Header>Удаление</Modal.Header>
      <Modal.Body className={'flex flex-col gap-5'}>
        {!!text && <p>{text}</p>}
        <Button
          loading={loading}
          onClick={() => {
            setLoading(true);
            onSubmit();
          }}
          color={'error'}
        >
          Удалить
        </Button>
        <Button onClick={onClose} variant={'outline'}>
          Отмена
        </Button>
      </Modal.Body>
    </Modal>
  );
};
