import {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
  useMemo,
} from 'react';

interface IContext {
  selectedRowKeys: string[];
  setSelectedRowKeys: Dispatch<SetStateAction<string[]>>;
}

const Context = createContext<IContext>({
  selectedRowKeys: [],
  setSelectedRowKeys: () => {},
});

export const OrderContextProvider = ({ children }: PropsWithChildren) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const contextValue = useMemo(
    () => ({
      selectedRowKeys,
      setSelectedRowKeys,
    }),
    [selectedRowKeys, setSelectedRowKeys],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useOrderContext = (): IContext => useContext(Context);
