import { Popover } from 'antd';
import { PriceBadge } from 'pages/Order/components/OrderTab/PriceBadge';
import { useParams } from 'react-router';
import { PricesHistoryPopover } from '../../../components/PricesHistoryPopover';
import { useSettings } from '../../../hooks/useSettings';
import { useAnalyticsTableFilters } from '../hooks/useAnalyticsTableFilters';
import { useOptPrices } from '../hooks/useOptPrices';

interface Props {
  defaultPrice: number;
  currentPrice: number;
  personalPrice: number;
  retailPrice: number;
  count: number;
  koef: number;
  unit: string;
  baseUnit: string;
  personalPricesHistory: { date: string; price: number }[];
  showSumm: boolean | true;
  isBestPrice: boolean;
}

export const PricesCell = ({
  defaultPrice,
  currentPrice,
  personalPrice,
  retailPrice,
  personalPricesHistory,
  unit,
  baseUnit,
  koef,
  count,
  showSumm,
  isBestPrice,
}: Props) => {
  const { budgetId, orderId } = useParams();
  const filters = useAnalyticsTableFilters();
  const { optPrice } = useOptPrices(budgetId || orderId || '');
  const appSettings = useSettings();

  return (
    <Popover
      mouseEnterDelay={0.5}
      content={
        (!!personalPrice || !!currentPrice) &&
        !!personalPricesHistory?.length && (
          <PricesHistoryPopover
            unit={unit}
            pricesHistory={personalPricesHistory}
          />
        )
      }
    >
      <div className="flex flex-col gap-1">
        {(optPrice === 'retail' ||
          filters.value.personalPrices ||
          !filters.value.currentPrices) && (
          <PriceBadge
            unit={unit}
            positionUnit={baseUnit}
            koef={koef}
            count={count}
            price={optPrice === 'retail' ? retailPrice : defaultPrice}
            type="default"
            unitPrice={koef * defaultPrice}
            showSumm={showSumm}
            isBestPrice={isBestPrice}
          />
        )}

        {Boolean(currentPrice) && (
          <PriceBadge
            unit={unit}
            positionUnit={baseUnit}
            koef={koef}
            count={count}
            price={currentPrice}
            type="current"
            showSumm={showSumm}
            unitPrice={currentPrice * koef}
            isBestPrice={isBestPrice}
          />
        )}

        {Boolean(personalPrice) &&
          !currentPrice &&
          !!appSettings.settings?.personalPrices && (
            <PriceBadge
              unit={unit}
              price={personalPrice}
              count={count}
              type="personal"
              showSumm={showSumm}
              unitPrice={koef * defaultPrice}
            />
          )}
      </div>
    </Popover>
  );
};
