import React from 'react';
import { IBudget } from '../../../types';
import { dateTool } from '../../../utils/dateTool';
import { Button, Dropdown } from 'react-daisyui';

interface Props extends IBudget {
  onClick: VoidFunction;
  onDelete: VoidFunction;
}

export const BudgetItem = ({
  onClick,
  name,
  id,
  createdAt,
  onDelete,
}: Props) => {
  return (
    <div
      className={
        'cursor-pointer w-full rounded-xl bg-white p-5 flex flex-row justify-between shadow hover:bg-gray-50 transition'
      }
    >
      <div className={'flex flex-col w-full'} onClick={onClick}>
        <div className={'flex flex-row'}>
          <p className={'opacity-50 mr-1'}>
            #{id.slice(id.length - 4, id.length).toUpperCase()} -
          </p>
          <p>{name}</p>
        </div>
        <p className={'opacity-50'}>{dateTool.display(createdAt)}</p>
      </div>
      <div className={'flex flex-row gap-2'}>
        <Dropdown>
          <Button variant={'outline'}>...</Button>
          <Dropdown.Menu>
            {/*<Dropdown.Item onClick={onRename}>Редактировать</Dropdown.Item>*/}
            <Dropdown.Item onClick={onDelete} className={'text-red-500'}>
              Удалить
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
