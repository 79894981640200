import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

export const LoadingScreen = ({ description }: { description?: string }) => {
  return (
    <div
      className={
        'w-full h-96 flex justify-center items-center flex flex-col gap-10'
      }
    >
      <BeatLoader />
      {!!description && (
        <pre>
          <p className={'text-center'}>{description}</p>
        </pre>
      )}
    </div>
  );
};
