import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { Popover, Table } from 'antd';
import { truncate, orderBy, omit } from 'lodash';
import { useParams } from 'react-router';
import { PositionTitle } from '../../../../../components/PositionTitle';
import { useExcludedProviders } from '../../../../../hooks/useExcludedProviders';
import { useOrderDetails } from '../../../../../hooks/useOrderDetails';
import { type IProvider } from '../../../../../types';
import { PricesCell } from '../../../../Analytics/components/PricesCell';
import { useOrderPositions } from '../../../hooks/useOrderPositions';
import { useOrderPrices } from '../../../hooks/useOrderPrices';
import { getPositions } from '../../../hooks/useOrderSettingsTable';
import { useOrderContext } from '../../OrderContext';

interface Props {
  providers: IProvider[];
  productId: string;
  itemId: string;
}

export const PositionsTable = memo(
  ({ providers, productId, itemId }: Props) => {
    const { orderId } = useParams();
    const details = useOrderDetails(orderId);
    const { getExcludedPositions } = useOrderPositions(orderId);

    const excludedProviders = useExcludedProviders();

    const [sortedPositions, setSortedPositions] = useState([]);

    const { selectedRowKeys, setSelectedRowKeys } = useOrderContext();

    const onSelectChange = useCallback(
      (newSelectedRowKeys: string[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
      },
      [setSelectedRowKeys],
    );

    useEffect(() => {
      if (details.isLoading || !productId) {
        return;
      }

      const positions = getPositions(productId, details.data);

      const excludedPositions = getExcludedPositions(itemId);

      const pos = positions.map(a => ({
        ...a,
        excluded: excludedPositions.includes(a.id),
        sortPrice: a.count * a.minPrice,
      }));

      const result = orderBy(pos, ['excluded', 'sortPrice'], ['asc', 'asc']);

      const sorted = result
        .map(o => omit(o, ['excluded', 'sortPrice']))
        .filter(
          o =>
            o?.minPrice ||
            o?.maxPrice ||
            o?.currentPrices.some(currentPrice => currentPrice > 0),
        )
        .map(p => ({
          ...p,
          countString: `${p.count} ${p.unit}`,
          selected: !excludedPositions.includes(p.id),
        }));

      setSortedPositions(sorted);

      setSelectedRowKeys(
        getPositions(productId, details?.data)
          .map(({ id }) => id)
          .filter(id => !excludedPositions.includes(id)),
      );
    }, [
      details.data,
      details.isLoading,
      productId,
      setSelectedRowKeys,
      getExcludedPositions,
      itemId,
    ]);

    const { getIsBestPositionPrice } = useOrderPrices(orderId);

    const columns = useMemo(() => {
      if (
        !details?.data ||
        details.isLoading ||
        !productId ||
        excludedProviders.isLoading ||
        !providers
      ) {
        return [];
      }

      const positions = getPositions(productId, details.data);

      const filteredProviders = providers
        .filter(p => {
          if (excludedProviders.data.length === 0) {
            return true;
          }

          return !excludedProviders.data.includes(p.id);
        })
        .filter(p => {
          if (positions?.[0]) {
            return Object.keys(positions[0]).includes(p.id);
          }
        })
        .map(({ name, id }) => ({
          render: (value: any, record) => {
            const isBestPrice = getIsBestPositionPrice({
              itemId,
              providerId: id,
              positionId: record.id,
            });

            return <PricesCell {...value} isBestPrice={isBestPrice} />;
          },
          title: () => (
            <Popover content={name}>{truncate(name, { length: 10 })}</Popover>
          ),
          dataIndex: id,
          key: id,
          fixed: false,
        }));

      return [
        {
          title: 'Номенклатура контрагента',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          width: 200,
          render: (v: string, d: any) => (
            <div
              style={{
                opacity: sortedPositions?.find(({ name }) => name === v)
                  ?.selected
                  ? 1
                  : 0.7,
              }}
            >
              <PositionTitle id={d.id} title={v} />
            </div>
          ),
        },
        {
          title: 'Количество',
          dataIndex: 'countString',
          key: 'countString',
          fixed: 'left',
          width: 100,
        },
        ...filteredProviders,
      ];
    }, [
      itemId,
      details.data,
      details.isLoading,
      excludedProviders.data,
      excludedProviders.isLoading,
      getIsBestPositionPrice,
      productId,
      providers,
      sortedPositions,
    ]);

    return (
      <Table
        className="p-0 m-0 positionsTable"
        size="middle"
        scroll={{
          x: columns.length * 100 + 400,
          y: 500,
        }}
        dataSource={sortedPositions}
        // @ts-ignore
        columns={columns}
        rowKey="id"
        pagination={false}
        locale={{ emptyText: 'Нет данных' }}
        rowSelection={{
          checkStrictly: true,
          selectedRowKeys,
          onChange: onSelectChange,
        }}
      />
    );
  },
);
