import { Modal } from '../../../components/Modal';
import { Button, Input } from 'react-daisyui';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useApi } from '../../../hooks/useApi';

interface Props {
  title: string;
  defaultName?: string;
  onClose: VoidFunction;
  budgetId: string;
}

export const PickingRenameModal = ({
  title,
  defaultName,
  onClose,
  budgetId,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const form = useForm();
  const api = useApi();

  const onSubmit = async ({ name }: { name?: string }) => {
    setLoading(true);
    await api.updateBudget(budgetId, { name: name || defaultName });
    onClose();
  };

  return (
    <Modal>
      <form
        className={'flex flex-col gap-5 w-80'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <p>{title}</p>
        <Input autoFocus {...form.register('name')} placeholder={defaultName} />
        <Button type={'submit'} loading={loading}>
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
