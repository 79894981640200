import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import { Step1 } from './components/Step1';
import { Step2 } from './components/Step2';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { type IAnalyticsRow } from '../../types';
import { useParams } from 'react-router';

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

export const CreateOrderModal = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  const { objectId, budgetId } = useParams();
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const [data, setData] = useState<{
    name: string;
    budget: string;
    object: string;
  }>();

  useEffect(() => {
    if (open) setData(undefined);
  }, [open]);

  const onSubmit = async (products: IAnalyticsRow[]) => {
    const budget = await api.getBudget(budgetId || data?.budget!);
    setLoading(true);
    const { id } = await api.createOrder({
      name: data?.name!,
      objectId: objectId || data?.object!,
      budgetId: budgetId || data?.budget!,
      products: products.map(p => ({
        id: p.productId,
        query:
          budget.products.find(
            ({ productId }: any) => productId === p.productId,
          )?.searchQuery || '',
        count: p.productCount,
        unit: p.productUnit,
      })),
    });
    await api.optimizeOrder(id);
    setLoading(false);
    navigate(`/order/${id}`);
  };

  const submitFirstStep = async (e: {
    budget: string;
    name: string;
    object: string;
  }) => {
    let newObject = e?.object;

    if (!e?.object) {
      const objects = await api.getObjects();
      const found = objects.find(({ name }: any) => name === 'Без объекта');
      if (found) {
        newObject = found.id;
      } else {
        const { id } = await api.createObject('Без объекта');
        newObject = id;
      }
    }

    if (e.budget) {
      setData(e);
    } else {
      const budget = await api.createBudget(newObject, e.name);
      await api.deleteBudget(budget.id);
      navigate(`/object/${newObject}/budget/${budget.id}?view=order`);
    }
  };

  return (
    <Modal
      open={open}
      className="flex flex-col gap-5"
      style={{ minWidth: data && '80vw' }}
    >
      {data ? (
        <Step2 loading={loading} onSubmit={onSubmit} budgetId={data?.budget} />
      ) : (
        <Step1 onSubmit={submitFirstStep} />
      )}

      <Button type="button" onClick={onClose} variant="outline">
        Отмена
      </Button>
    </Modal>
  );
};
