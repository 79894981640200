import { useMutation } from '@tanstack/react-query';
import { useApi } from './useApi';

export const useBatchUpdateVerifiedPositions = () => {
  const api = useApi();
  return useMutation<
    void,
    void,
    { positionId: string; productId: string; verified: boolean }[]
  >(data => api.batchUpdateVerifiedPositions(data));
};
