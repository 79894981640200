import { firebaseAnalytics, logEvent } from './firebase';
import { IObject } from '../types';

const ga4 = firebaseAnalytics;

export const analytics = {
  createObject(object: IObject) {
    logEvent('create_object', object);
  },
  removeObject(object: IObject) {
    logEvent('remove_object', object);
  },
  renameObject(object: IObject) {
    logEvent('rename_object', object);
  },
  login() {
    logEvent('login');
  },
};
