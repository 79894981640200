import { useApi } from '../../../hooks/useApi';
import { useQuery } from '@tanstack/react-query';

interface IAnalyticsProduct {
  type: 'product' | 'query';
  query: string;
  productName: string;
  count: number;
  unit: string;
  minTotalPrice: number;
  maxTotalPrice: number;
}

export const useAnalyticsProducts = (budgetId: string) => {
  const api = useApi();

  return useQuery<IAnalyticsProduct[]>(['analytics-products', budgetId], () =>
    api.getBudgetProductsReport(budgetId),
  );
};
