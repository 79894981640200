import { TagFilled } from '@ant-design/icons';
import { Tag } from 'antd';

// @ts-ignore
import styles from './styles.module.css';
import { money } from '../../../../../utils/moneyTool';

interface Props {
  price?: number;
  type: 'default' | 'personal' | 'current' | 'order';
  count?: number;
  unit?: string;
  unitPrice?: number;
  isBestPrice?: boolean | false;
  showSumm?: boolean | false;
  positionUnit?: string;
  koef?: number;
}

export const PriceBadge = ({
  price,
  type,
  count,
  unit,
  unitPrice,
  koef,
  positionUnit,
  showSumm,
  isBestPrice,
}: Props) => {
  const printPrice = <p>{!price ? '-' : money(price, unit)}</p>;
  const printUnit = (
    <p className="text-xs">
      {money(unitPrice * 1, !positionUnit ? 'шт' : positionUnit)}
    </p>
  );
  const printSumm = <p>{!price ? '-' : money(price * (count ?? 1), '')}</p>;
  const valuePrint = (
    <div className="cursor-default text-center">
      {!showSumm ? (
        koef === 1 ? (
          <>{printPrice}</>
        ) : (
          <>
            {printPrice}
            {printUnit}
          </>
        )
      ) : (
        <>{printSumm}</>
      )}
    </div>
  );

  if (type === 'personal') {
    return (
      <div
        className="px-1 rounded-lg flex justify-center items-center"
        title={`${money(price || 1, unit)} за шт`}
      >
        {!(unit && price) ? (
          <p className="cursor-default">
            {!(unitPrice && price)
              ? '-'
              : money(price, !positionUnit ? 'шт' : positionUnit)}
          </p>
        ) : (
          <div>
            {!isBestPrice ? (
              <Tag color="blue" bordered={false}>
                {valuePrint}
              </Tag>
            ) : (
              <Tag
                color="blue"
                bordered={false}
                style={{ margin: 0, position: 'relative' }}
              >
                <div>
                  <TagFilled
                    rotate={270}
                    style={{
                      fill: 'red',
                      color: 'red',
                      position: 'absolute',
                      bottom: -7.5,
                      right: -7.5,
                      padding: 0,
                    }}
                  />
                  {valuePrint}
                </div>
              </Tag>
            )}
          </div>
        )}
      </div>
    );
  }

  if (type === 'current') {
    return (
      <div
        className="px-1 rounded-lg flex justify-center items-center "
        title={`${money(price || 1, unit)} за шт`}
      >
        {!(unitPrice && price) ? (
          <p className="cursor-default">
            {!price ? '-' : money(price, !positionUnit ? 'шт' : positionUnit)}
          </p>
        ) : (
          <div>
            {!isBestPrice ? (
              <Tag color="green" bordered={false}>
                {valuePrint}
              </Tag>
            ) : (
              <Tag
                color="green"
                bordered={false}
                style={{ margin: 0, position: 'relative' }}
              >
                <div>
                  <TagFilled
                    rotate={270}
                    style={{
                      fill: 'red',
                      color: 'red',
                      position: 'absolute',
                      bottom: -7.5,
                      right: -7.5,
                      padding: 0,
                    }}
                  />
                  {valuePrint}
                </div>
              </Tag>
            )}
          </div>
        )}
      </div>
    );
  }

  if (type === 'order') {
    return (
      <p className="cursor-default flex justify-center items-center">
        {price ? money(price) : '-'}
      </p>
    );
  }

  return (
    <div
      className="px-1 rounded-lg flex justify-center items-center "
      title={`${money(price || 1)} за шт`}
    >
      {!(unitPrice && price) ? (
        <p className="cursor-default">
          {!price ? '-' : money(price, !positionUnit ? 'шт' : positionUnit)}
        </p>
      ) : (
        <div>
          {!isBestPrice ? (
            <Tag bordered={false}>{valuePrint}</Tag>
          ) : (
            <Tag
              bordered={false}
              color="#f4ffb8"
              className={styles.tag}
              style={{
                margin: 0,
                position: 'relative',
                color: '#000 !important',
              }}
            >
              <div>
                <TagFilled
                  rotate={270}
                  style={{
                    fill: 'red',
                    color: 'red',
                    position: 'absolute',
                    bottom: -7.5,
                    right: -7.5,
                    padding: 0,
                  }}
                />

                {valuePrint}
              </div>
            </Tag>
          )}
        </div>
      )}
    </div>
  );
};
