import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';
import { type IOrderResponse } from '../types';

export const useOrder = (id: string) => {
  const api = useApi();

  return useQuery<IOrderResponse>(['order', id], () => api.getOrder(id), {
    enabled: !!id,
  });
};
