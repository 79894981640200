import { useAccount } from '../../hooks/useAccount';

export const SupplyReport = () => {
  const account = useAccount();
  const isSecondFunctionAvailable = account?.data?.id === '888012';

  if (!isSecondFunctionAvailable) return null;

  return (
    <iframe
      className={'w-full h-full'}
      src={
        'https://app.powerbi.com/view?r=eyJrIjoiNDAyNWE5YTQtYjJjNi00NDkyLWE1MmYtZTRhOTE2NWQyMzZkIiwidCI6IjhiZDM0ZDg4LWFmNDktNGE1Mi04ZWJiLTkzNjdiMjk0OTVkYiIsImMiOjl9'
      }
    />
  );
};
