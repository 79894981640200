import React from 'react';
import { Button } from 'react-daisyui';
import { useParams } from 'react-router';
import { useBudgetDetails } from '../../../hooks/useBudgetDetails';
import { ProductsTable } from './ProductsTable';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../hooks/useApi';
import { useAnalyticsView } from '../hooks/useAnalyticsView';
import { useAnalyticsProducts } from '../hooks/useAnalyticsProducts';
import BeatLoader from 'react-spinners/BeatLoader';

export const ProductsTab = () => {
  const { budgetId, objectId } = useParams();
  const navigate = useNavigate();
  const budgetDetails = useBudgetDetails(budgetId || '');
  const view = useAnalyticsView();
  const report = useAnalyticsProducts(budgetId || '');
  const api = useApi();

  const onAddProduct = () =>
    navigate(`/object/${objectId}/budget/${budgetId}?view=${view}`);
  const onDownloadReport = () =>
    api.downloadProductsReport(budgetId || '', budgetDetails.data?.name || '');

  return (
    <div>
      <div className={'flex flex-row justify-between mb-5'}>
        <div>
          <p className={'text-lg'}>Смета</p>
        </div>
        <div className={'flex flex-row gap-5'}>
          <Button variant={'outline'} onClick={onAddProduct}>
            Добавить позицию
          </Button>
          <Button onClick={onDownloadReport}>Выгрузить смету</Button>
        </div>
      </div>
      {!!report.data?.length ? <ProductsTable /> : <BeatLoader />}
    </div>
  );
};
