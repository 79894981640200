import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';
import { type IAnalytics } from '../types';

export const useOrderDetails = (id: string) => {
  const api = useApi();

  return useQuery<IAnalytics>(
    ['order-details', id],
    () => api.getOrderDetails(id),
    {
      refetchInterval: 10000,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
  );
};
