import React from 'react';
import { Table } from 'antd';

export const FactoriesTab = () => {
  const columns = [
    {
      title: 'Поставщик',
      dataIndex: 'name',
      key: 'id',
    },
  ];

  const rows = [
    {
      name: 'Knauf',
      id: 'Knauf',
    },
    {
      name: 'Ceresit',
      id: 'Ceresit',
    },
    {
      name: 'Bergauf',
      id: 'Bergauf',
    },
    {
      name: 'Paleta',
      id: 'Paleta',
    },
    {
      name: 'Волма',
      id: 'Волма',
    },
  ];

  return (
    <div>
      <p className={'my-5 text-lg'}>Производители</p>
      <Table
        showHeader={false}
        dataSource={rows}
        columns={columns}
        pagination={false}
        rowKey={'id'}
        size={'middle'}
        scroll={{ y: 500 }}
        rowSelection={{
          checkStrictly: true,
          defaultSelectedRowKeys: rows.map(({ id }) => id),
        }}
      />
    </div>
  );
};
