import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-daisyui';
import { useParams } from 'react-router';

import { useOrder } from '../../../hooks/useOrder';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { BudgetReportModal } from '../../../components/BudgetReportModal';
import { money } from '../../../utils/moneyTool';
import { useOrderDetails } from '../../../hooks/useOrderDetails';
import { PaymentTable } from './PaymentTable';

import { type IDocument } from '../../../types';

export const OrderPayment = () => {
  const { orderId } = useParams();
  const order = useOrder(orderId || '');
  const details = useOrderDetails(orderId || '');
  const [reportModalOpened, setReportModalOpened] = useState(false);

  const navigate = useNavigate();
  const onDownload = async ({ name, url }: IDocument) => {
    window.open(url, '_blank')?.focus();
  };

  const onBack = () => navigate('/orders');

  if (!order?.data || !details?.data) return <LoadingScreen />;

  return (
    <div className="px-10 py-5 flex flex-col gap-5">
      <div className="bg-white p-5 pb-0 rounded-xl shadow w-full">
        <p onClick={onBack} className="opacity-50 cursor-pointer">
          {'<'} к заявкам
        </p>

        <div className="flex flex-row justify-between items-center mb-2">
          <div>
            <p className="text-3xl my-2 mb-6">Заявка {order.data.name}</p>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-row gap-5">
        <div className="bg-white rounded-xl flex flex-1 p-5 flex-col gap-5">
          <PaymentTable />
        </div>

        <div className="flex flex-col gap-5">
          <div className="bg-white rounded-xl w-80 p-5 flex flex-col gap-5">
            <p className="font-bold">Доставка</p>
            <p>{order.data?.AddressInOrder?.name || 'Без доставки'}</p>
          </div>

          <div className="bg-white rounded-xl w-80 p-5 flex flex-col gap-5">
            <p className="font-bold">Ожидаем вашей оплаты</p>

            <div className="flex flex-col gap-4 px-5">
              <div className="flex justify-between">
                <p>{money(details.data?.orderPrice)}</p>
                <p>Товары</p>
              </div>

              <div className="flex justify-between">
                <p>{money(details.data?.deliveryPrice)}</p>
                <p>Доставка</p>
              </div>

              <div className="flex justify-between font-bold">
                <p>{money(details.data?.totalPrice)}</p>
                <p>Итого</p>
              </div>
            </div>
            {order.data?.Document?.map(d => (
              <Button onClick={() => onDownload(d)}>Открыть {d.name}</Button>
            ))}
          </div>
        </div>
      </div>

      <BudgetReportModal
        onClose={() => setReportModalOpened(false)}
        open={reportModalOpened}
        fallbackBudgetId={order.data.budgetId}
      />
    </div>
  );
};
