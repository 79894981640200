import React, { useState } from 'react';
import { Button, Input } from 'react-daisyui';
import { useForm } from 'react-hook-form';
import { useApi } from '../../hooks/useApi';

export const Login = () => {
  const api = useApi();
  const { register, handleSubmit, setValue } = useForm<{
    phone: string;
    password: string;
  }>();
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({
    phone,
    password,
  }: {
    phone: string;
    password: string;
  }) => {
    try {
      const clearPhone = phone
        .replaceAll('+7', '')
        .replaceAll(' ', '')
        .replaceAll('-', '');
      setLoading(true);
      const token = await api.login(clearPhone, password);

      if (token) api.setToken(token);
    } catch {
      setLoading(false);
    }
  };

  const formatPhone = (text: string) => {
    let result = text.replaceAll(' ', '').replaceAll('-', '');

    if (result[0] === '8') result = result.replace('8', '');
    if (result[0] === '+') result = result.replace('+7', '');

    result = `+7 ${result.slice(0, 3)} ${result.slice(3, 6)} ${result.slice(
      6,
      8,
    )}-${result.slice(8, 10)}`;

    setValue('phone', result.length === 16 ? result : text);
  };

  return (
    <div className={'w-full flex justify-center p-10'}>
      <form
        className={'flex flex-col gap-7 w-96 rounded-xl bg-white p-7'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className={'text-center text-xl font-bold'}>Вход в аккаунт</p>
        <Input
          autoFocus
          placeholder={'Номер телефона'}
          {...register('phone', {
            required: true,
            maxLength: 16,
            minLength: 16,
          })}
          onBlur={e => formatPhone(e.currentTarget.value)}
        />
        <Input
          placeholder={'Пароль'}
          type={'password'}
          {...register('password', { required: true })}
        />
        <Button loading={loading} type={'submit'}>
          Войти
        </Button>
      </form>
    </div>
  );
};

export default Login;
