import {
  type FC,
  useState,
  useEffect,
  memo,
  useCallback,
  useContext,
} from 'react';
import { ProTable } from '@ant-design/pro-components';
import { Button, Popover, Switch, Radio, Flex, Tag } from 'antd';
import { truncate } from 'lodash';
import { useOrderPrices } from 'pages/Order/hooks/useOrderPrices';
import { createRoot } from 'react-dom/client';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { CartModal } from './CartModal';
import { PriceBadge } from './PriceBadge';
import { PricePopover } from './PricePopover';
import { SendToProvidersModal } from './SendToProvidersModal';
import styles from './styles.module.css';
import { Total } from './Total';
import { LoadingScreen } from '../../../../components/LoadingScreen';
import { PositionTitle } from '../../../../components/PositionTitle';
import { ProvidersModal } from '../../../../components/ProvidersModal';
import { useApi } from '../../../../hooks/useApi';
import { useExcludedProviders } from '../../../../hooks/useExcludedProviders';
import { useOrder } from '../../../../hooks/useOrder';
import { useOrderDetails } from '../../../../hooks/useOrderDetails';
import { useFileMatching } from '../../../../hooks/useUpload';
import { useOrderStore } from '../../../../store/useOrderStore';
import {
  calculateUnitPrice,
  findMarketKoef,
  money,
} from '../../../../utils/moneyTool';
import { useOrderPositions } from '../../hooks/useOrderPositions';
import { UpdateToastContext } from '../../hooks/useUpdateToast';
import { useUploadFileWithoutMatching } from '../../hooks/useUploadFileWithoutMatching';
import { OrderContextProvider } from '../OrderContext';
import { SelectPositionsModal } from '../SelectPositionsModal';
import { StroySetProductPopover } from '../StroySetProductPopover';

interface Props {
  onReOptimize: VoidFunction;
}

interface CurrentPosition {
  id: string;
  price: number[];
  personalPricesHistory: {
    date: string;
    price: number;
  }[];
  count: number;
  koef: number;
}

export const OrderTab: FC<Props> = memo(({ onReOptimize }) => {
  const [providersModalOpened, setProvidersModalOpened] = useState(false);
  const [cartModalOpened, setCartModalOpened] = useState(false);
  const { orderId } = useParams();
  const [sendToProvidersModalOpened, setSendToProvidersModalOpened] =
    useState<boolean>(false);
  const orderDetails = useOrderDetails(orderId!);
  const excludedProviders = useExcludedProviders();
  const [selectedProduct, setSelectedProduct] = useState<{
    productId: string;
    itemId: string;
  } | null>(null);
  const api = useApi();
  const order = useOrder(orderId!);
  const navigate = useNavigate();
  const { getExcludedPositions } = useOrderPositions(orderId);

  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const uploadFileWithoutMatching = useUploadFileWithoutMatching({
    reload: onReOptimize,
  });
  const upload = useFileMatching();

  const [isTotalShown, setIsTotalShown] = useState<boolean>(true);

  const { priceType, changePriceType } = useOrderStore();
  const { setUpdated } = useContext(UpdateToastContext);

  const isRetailPrices = priceType === 'retail';

  const tableContainerNode = document.getElementById('tableId');

  const onUploadWithoutMatching = useCallback(async () => {
    const files = await upload.choose();
    uploadFileWithoutMatching.uploadFiles(files);
  }, [upload, uploadFileWithoutMatching]);

  useEffect(() => {
    if (orderDetails.isLoading || !tableContainerNode) return;

    if (tableContainerNode) {
      tableContainerNode.style.display = 'flex';

      const elem = document.createElement('div');
      elem.setAttribute('id', 'ara');
      tableContainerNode.appendChild(elem);

      const container =
        Array.from(
          tableContainerNode.getElementsByClassName('ant-table-container'),
        )?.[0] ?? null;
      // @ts-ignore TODO: fix ts error
      container.style.maxWidth = 'calc(100% - 180px)';
      elem.style.width = '180px';
      elem.style.minHeight = '100%';
      elem.style.background = '#fff';

      const root = createRoot(elem);

      root.render(
        <div
          style={{
            textAlign: 'center',
            padding: 15,
            paddingRight: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            alt=""
            style={{
              width: 40,
              height: 40,
              marginBottom: 15,
            }}
            src="/icons/file_icon.svg"
          />

          <div style={{ fontSize: '14px', marginBottom: 30 }}>
            Добавьте счета от поставщиков, мы подберем оптимальный вариант на
            основе ваших счетов
          </div>

          <Button
            style={{ width: 150 }}
            className="!bg-slate-500 !text-white"
            onClick={onUploadWithoutMatching}
          >
            Добавить счет
          </Button>
        </div>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails.isLoading, tableContainerNode]);

  const onAddProduct = useCallback(async () => {
    await api.deleteOrder(orderId || '');
    navigate(
      `/object/${order.data?.objectId}/budget/${order.data?.budgetId}?view=order`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.data?.budgetId, order.data?.objectId, orderId]);

  const {
    getIsBestPrice,
    getBestPosition,
    getBestPositionByPersonalPrice,
    getTotalPersonalPriceByProvider,
  } = useOrderPrices(orderId);

  const filteredProviders = orderDetails.data?.providers
    .filter(({ id }) => !excludedProviders.data?.includes(id))
    .map(({ name, id, totalPrice, deliveryPrice, orderPrice }) => ({
      render: (_: any, row: any) => {
        if (row.id === 'total') {
          return (
            <Total
              onCart={() => {
                setCartModalOpened(true);
                setSelectedProvider(id);
              }}
              total={totalPrice}
            />
          );
        }

        if (row.id === 'delivery') {
          return <PriceBadge price={deliveryPrice} type="order" />;
        }

        if (row.id === 'materialsTotalSum') {
          return <PriceBadge price={totalPrice} type="order" />;
        }

        if (row.id === 'materials') {
          const totalPersonalPrice = getTotalPersonalPriceByProvider(id);
          return (
            <div className="flex flex-col justify-center items-center">
              <PriceBadge price={orderPrice} type="order" />
              {totalPersonalPrice > 0 && (
                <Tag color="green" bordered={false}>
                  {money(totalPersonalPrice)}
                </Tag>
              )}
            </div>
          );
        }

        const position = getBestPosition(row.itemId, id);
        const positionByPersonalPrice = getBestPositionByPersonalPrice(
          row.id,
          id,
        );

        if (!position) {
          return <p className="text-center">-</p>;
        }

        const isBestPrice = getIsBestPrice({
          providerId: id,
          itemId: row.itemId,
        });

        return (
          <PricePopover
            providerName={name}
            providerIndex={orderDetails.data.providers.findIndex(
              p => p.id === id,
            )}
            isTotalShown={isTotalShown}
            isBestPrice={isBestPrice}
            isRetailPrice={isRetailPrices}
            position={position}
            positionByPersonalPrice={positionByPersonalPrice}
          />
        );
      },
      title: () => (
        <Popover content={name}>{truncate(name, { length: 10 })}</Popover>
      ),
      dataIndex: id,
      key: id,
      fixed: false,
    }));

  if (orderDetails.isLoading || !orderDetails.data || !excludedProviders.data) {
    return <LoadingScreen />;
  }

  const total: any = {};

  const data = orderDetails.data?.products.map(p => {
    const product = p;
    const providers: any = {};

    orderDetails.data.providers?.forEach(({ id }, index) => {
      const prices: number[] = [];
      const currentPrices: number[] = [];
      const curPos: CurrentPosition[] = [];

      const excludedPositions = getExcludedPositions(p.itemId);

      product.positions
        .filter(pos => !excludedPositions.includes(pos.id))
        .forEach(pos => {
          currentPrices.push(pos.currentPrices[index]);
          prices.push(
            isRetailPrices ? pos.retailPrices[index] : pos.prices[index],
          );
          curPos.push({
            id: pos.id,
            price: pos.currentPrices,
            personalPricesHistory: pos.personalPricesHistory[index],
            count: pos.count,
            koef: pos.koef,
          });
        });

      const preFinalPrice = Math.min(...prices.filter(v => !!v));
      const finalPrice = preFinalPrice < 10000000000000 ? preFinalPrice : 0;

      const preFinalPriceCurrent = Math.min(...currentPrices.filter(v => !!v));
      const finalPriceCurrent =
        preFinalPriceCurrent < Number.MAX_SAFE_INTEGER - 1
          ? preFinalPriceCurrent
          : 0;

      const currId = curPos.find(
        pos => pos.price[index] === preFinalPriceCurrent,
      );

      if (total?.[id]?.price) {
        total[id].price += finalPrice;
      } else {
        total[id] = {
          price: finalPrice,
          current: finalPriceCurrent,
          count: currId?.count,
          currentId: currId?.id,
          koef: currId?.koef,
          personalPricesHistory: currId?.personalPricesHistory,
        };
      }

      if (total?.[id]?.current) {
        total[id].current += finalPriceCurrent;
      } else {
        total[id].current = finalPriceCurrent;
      }

      providers[id] = {
        price: finalPrice,
        current: finalPriceCurrent,
        currentId: currId?.id,
        count: currId?.count,
        koef: currId?.koef,
        personalPricesHistory: currId?.personalPricesHistory,
      };
    });

    return {
      ...p,
      ...providers,
    };
  });

  data.unshift({ ...total, name: 'Общая сумма', id: 'materialsTotalSum' });
  data.push({ ...total, name: 'Доставка', id: 'delivery' });
  data.push({ ...total, name: 'Материалы', id: 'materials' });
  data.push({ ...total, name: 'ИТОГО', id: 'total' });

  const columns = [
    {
      dataIndex: 'id',
      key: 'id',
      width: '30px',
      render: (id: string, row) => {
        return (
          id !== 'total' &&
          id !== 'delivery' &&
          id !== 'materials' &&
          id !== 'materialsTotalSum' && (
            <img
              alt=""
              onClick={() =>
                setSelectedProduct({ productId: id, itemId: row.itemId })
              }
              className="w-4 cursor-pointer"
              src="/images/collapse.png"
            />
          )
        );
      },
    },
    {
      title: 'Номенклатура контрагента',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (name: string, row: any) => {
        if (
          row.id !== 'total' &&
          row.id !== 'delivery' &&
          row.id !== 'materials' &&
          row.id !== 'materialsTotalSum'
        ) {
          return <PositionTitle id={row.id} title={name} />;
        }

        return name;
      },
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      key: 'count',
      render: (count: number, record: any) => {
        if (count) {
          const productInOrder = order.data?.ProductInOrder?.find(
            ({ productId }) => productId === record.id,
          );

          if (!productInOrder) return '';

          const rawCount = Math.round(count * productInOrder.koef);

          return `${rawCount} ${productInOrder.unit}`;
        }

        return '';
      },
    },
    {
      title: 'Оптимальная закупка',
      key: 'stroyset',
      dataIndex: 'stroyset',
      className: styles.column,
      render: (_, { id, itemId }) => {
        if (id === 'total') {
          return (
            <Total
              onCart={() => {
                setCartModalOpened(true);
                setSelectedProvider('stroyset');
              }}
              total={orderDetails.data?.totalPrice}
            />
          );
        }

        if (id === 'delivery') {
          return (
            <PriceBadge
              price={orderDetails.data?.deliveryPrice || 0}
              type="order"
            />
          );
        }

        if (id === 'materials' || id === 'materialsTotalSum') {
          return (
            <PriceBadge
              price={orderDetails.data?.orderPrice || 0}
              type="order"
            />
          );
        }

        const marketProduct = orderDetails.data?.market.find(
          p => p.itemId === itemId,
        );

        if (!marketProduct) {
          return <p className="text-center">-</p>;
        }

        const isBestPrice = getIsBestPrice({
          providerId: marketProduct?.providerId,
          itemId,
        });

        return (
          <StroySetProductPopover
            orderDetails={orderDetails.data}
            marketProduct={marketProduct}
            itemId={itemId}
          >
            <div>
              <PriceBadge
                type="default"
                price={marketProduct?.price}
                koef={findMarketKoef(itemId, orderDetails)}
                count={marketProduct?.positionCount}
                unit={marketProduct?.positionUnit}
                positionUnit={marketProduct?.productUnit}
                unitPrice={calculateUnitPrice(itemId, orderDetails)}
                isBestPrice={isBestPrice}
                showSumm={isTotalShown}
              />
            </div>
          </StroySetProductPopover>
        );
      },
    },
    // @ts-ignore
    ...filteredProviders,
  ].filter(({ key }) => total?.[key] !== 0);

  const isCartModalShown = cartModalOpened && Boolean(selectedProvider);

  return (
    <div className="flex flex-row justify-center">
      <div style={{ width: '100%' }}>
        <ProTable
          id="tableId"
          rowKey={record => `${record.id}`}
          dataSource={data}
          columns={columns}
          style={{ maxWidth: '100%' }}
          scroll={{
            x: columns.length * 100 + 400,
            y: 700,
          }}
          className="orderTable"
          pagination={false}
          headerTitle={
            <>
              <Flex gap={5}>
                <Radio.Group
                  value={priceType}
                  onChange={event => {
                    changePriceType(event.target.value);
                    setUpdated(true);
                  }}
                  style={{ minWidth: 300 }}
                >
                  <Radio.Button value="retail">Розничные цены</Radio.Button>
                  <Radio.Button value="wholesale">Оптовые цены</Radio.Button>
                </Radio.Group>

                <div
                  className="flex text-end"
                  style={{ alignItems: 'center', marginRight: 10 }}
                >
                  <Switch
                    checked={isTotalShown}
                    onChange={() => setIsTotalShown(prevState => !prevState)}
                  />

                  <p
                    className="w-max  pl-1.5 text-center flex"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 400,
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsTotalShown(prevState => !prevState)}
                  >
                    Отображать сумму
                  </p>
                </div>
              </Flex>

              <div style={{ display: 'flex', gap: 5 }}>
                <Button onClick={() => setSendToProvidersModalOpened(true)}>
                  Запросить счета
                </Button>

                <Button onClick={onAddProduct}>Изменить смету</Button>

                <Button onClick={() => setProvidersModalOpened(true)}>
                  Изменить список поставщиков
                </Button>
              </div>
            </>
          }
          search={false}
          options={{
            reload: onReOptimize,
            setting: {
              draggable: false,
              settingIcon: false,
              showListItemOption: false,
            },
            density: false,
          }}
        />
      </div>

      {sendToProvidersModalOpened && (
        <SendToProvidersModal
          onClose={() => setSendToProvidersModalOpened(false)}
        />
      )}

      <OrderContextProvider>
        {selectedProduct && (
          <SelectPositionsModal
            isOpen={Boolean(selectedProduct)}
            onClose={() => setSelectedProduct(null)}
            providers={orderDetails.data?.providers}
            productId={selectedProduct.productId}
            itemId={selectedProduct.itemId}
          />
        )}

        <ProvidersModal
          onClose={() => setProvidersModalOpened(false)}
          open={providersModalOpened}
        />

        {isCartModalShown && (
          <CartModal
            selectedProvider={selectedProvider}
            onClose={() => setCartModalOpened(false)}
          />
        )}
      </OrderContextProvider>
    </div>
  );
});
