import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks/useApi';

export const usePositionSearch = (productId: string, query: string) => {
  const api = useApi();

  return useQuery<any[]>(
    ['positionSearch', productId],
    () => api.positionSearch(query, productId),
    {
      enabled: !!productId,
    },
  );
};
