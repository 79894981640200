import { type FC, useState, useMemo, useCallback } from 'react';
import { Table } from 'antd';
import { useOrderDetails } from '../../../../hooks/useOrderDetails';
import { useParams } from 'react-router';
import { money } from '../../../../utils/moneyTool';
import { Button } from 'react-daisyui';
import { useApi } from '../../../../hooks/useApi';
import toast from 'react-hot-toast';

interface Props {
  onOptimize?: VoidFunction;
}

export const OfferTab: FC<Props> = ({ onOptimize }) => {
  const { orderId } = useParams();
  const details = useOrderDetails(orderId);
  const [buttonLoading, setButtonLoading] = useState('');
  const api = useApi();

  const columns = [
    {
      title: 'Текущая позиция',
      dataIndex: 'currentPositionName',
      key: 'currentPositionName',
    },
    {
      title: 'Новая позиция',
      dataIndex: 'positionName',
      key: 'positionName',
    },
    {
      title: 'Текущая цена',
      dataIndex: 'currentPrice',
      key: 'currentPrice',
    },
    {
      title: 'Новая цена',
      dataIndex: 'positionPrice',
      key: 'positionPrice',
    },
    {
      title: 'Общая выгода',
      dataIndex: 'totalBenefit',
      key: 'totalBenefit',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const onAccept = useCallback(
    async (offerId: string) => {
      setButtonLoading(offerId);
      await api.acceptOffer(orderId || '', offerId);
      toast.success('Предложение принято');
      onOptimize?.();
    },
    [onOptimize, orderId],
  );

  const tableData = useMemo(() => {
    return details?.data?.offers?.map(offer => {
      const product = details?.data?.market?.find(
        ({ productId }) => productId === offer.productId,
      );
      const currentPrice = product?.price ?? 0;

      return {
        ...offer,
        currentPositionName: product?.positionName,
        currentPrice: money(currentPrice),
        positionPrice: money(offer.positionPrice),
        totalBenefit: money(
          (currentPrice - offer.positionPrice) * (product?.positionCount ?? 1),
        ),
        action: (
          <Button
            loading={buttonLoading === offer.offerId}
            disabled={offer.offerStatus === 'accepted'}
            onClick={() => onAccept(offer.offerId)}
          >
            {buttonLoading !== offer.offerId &&
              (offer.offerStatus === 'accepted' ? 'Принято' : 'Принять')}
          </Button>
        ),
      };
    });
  }, [buttonLoading, details?.data?.market, details?.data?.offers, onAccept]);

  if (!details?.data?.offers?.length) {
    return (
      <div
        className="flex justify-center items-center w-full h-full"
        style={{ minHeight: '100%' }}
      >
        <p>Сейчас для вас нет доступных предложений</p>
      </div>
    );
  }

  return (
    <>
      <p className="font-bold">Список товаров</p>

      <Table dataSource={tableData} columns={columns} pagination={false} />
    </>
  );
};
