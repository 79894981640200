import { useState } from 'react';
import { Button } from 'react-daisyui';
import { Modal } from './Modal';

interface OptimizationErrorModalProps {
  onReOptimize: () => Promise<void>;
}

export const OptimizationErrorModal = ({
  onReOptimize,
}: OptimizationErrorModalProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal>
      <div className={'flex flex-col gap-5 w-80'}>
        <p className={'text-xl font-bold'}>Произошла ошибка</p>
        <p className={''}>Пожалуйста пересчитайте</p>
        <Button
          loading={loading}
          onClick={async () => {
            setLoading(true);
            await onReOptimize();
          }}
        >
          Пересчитать цены
        </Button>
      </div>
    </Modal>
  );
};
