import React from 'react';
import { useProviders } from '../../../hooks/useProviders';
import BeatLoader from 'react-spinners/BeatLoader';
import { ProvidersList } from '../../../components/ProvidersList';

export const ProvidersTab = () => {
  const { data } = useProviders();

  if (!data?.length) return <BeatLoader />;

  return (
    <div>
      <p className={'my-5 text-lg'}>Поставщики</p>
      <ProvidersList providers={data} />
    </div>
  );
};
