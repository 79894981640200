import { useEffect, useRef, useState } from 'react';
import { SettingsTable } from './SettingsTable';
import { type IAnalyticsProduct, type IProvider } from '../../../types';
import { useAnalyticsSettingsTable } from '../hooks/useAnalyticsSettingsTable';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { useParams } from 'react-router';
import { money } from '../../../utils/moneyTool';
import { useBudgetDetails } from '../../../hooks/useBudgetDetails';
import { usePositions } from '../../../hooks/usePositions';
import { useOptPrices } from '../hooks/useOptPrices';
import { LoadingScreen } from '../../../components/LoadingScreen';

interface Props extends IAnalyticsProduct {
  isLite?: boolean;
  providers?: IProvider[];
}

export const PersonalProductItem = ({
  id,
  name,
  count,
  unit,
  isLite,
  positions,
  providers = [],
}: Props) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const { budgetId } = useParams();
  const analytics = useAnalytics(budgetId || '');
  const budgetDetails = useBudgetDetails(budgetId || '');
  const { optPrice } = useOptPrices(budgetId || '');
  const excludedPositions = usePositions();

  const analyticsSettingsTable = useAnalyticsSettingsTable(id, analytics.data);
  const tablePositions = isLite ? positions : analyticsSettingsTable;

  useEffect(() => {
    if (opened) {
      ref.current?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }, [opened]);

  const product = budgetDetails.data?.products.find(
    ({ productId }) => productId === id,
  );

  if (!product) return <LoadingScreen />;

  const minPrice = Math.min(
    ...positions
      .filter(({ id }) => !excludedPositions.data?.includes(id))
      .map(({ minPrice }) => minPrice),
  );
  const maxPrice = Math.max(
    ...positions
      .filter(({ id }) => !excludedPositions.data?.includes(id))
      .map(({ maxPrice }) => maxPrice),
  );

  return (
    // @ts-ignore
    <div ref={ref} className="rounded-2xl border-2 border-gray-100">
      <div
        className="flex flex-col cursor-pointer"
        onClick={() => setOpened(prev => !prev)}
      >
        <div className="flex flex-row items-center">
          <div className="p-8 pr-5">
            <img
              alt="toggle"
              src="/images/toggleArrow.svg"
              style={{
                transform: opened ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: '0.5s',
              }}
            />
          </div>
          <div className="flex flex-row justify-between items-center w-full pr-10">
            <div>
              <p>{product?.searchQuery || name}</p>
              {!!product?.searchQuery && <p className="opacity-50">{name}</p>}
            </div>

            {!isLite && (
              <div className="flex flex-row items-center gap-5">
                <p className="opacity-50">
                  {count} {unit}
                </p>

                {optPrice === 'retail' && (
                  <p className="text-lg">
                    {money(minPrice * count)} - {money(maxPrice * count)}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {opened && positions && providers && (
        <SettingsTable
          query={product?.searchQuery}
          providers={providers}
          isLite={isLite}
          positions={tablePositions}
        />
      )}
    </div>
  );
};
