import { create } from 'zustand';

type Position = {
  positionId: string;
  productId: string;
  verified: boolean;
};

type State = {
  positions: Record<string, Position>;
  toggle: (id: string, value: Position) => void;
  setPositions: (positions: Record<string, Position>) => void;
  clear: () => void;
};

export const useVerifiedPositionsStore = create<State>(set => ({
  positions: {},
  toggle(id, value) {
    set(prev => ({
      positions: {
        ...prev.positions,
        [id]: value,
      },
    }));
  },
  setPositions(positions) {
    set(prev => ({
      positions: {
        ...prev.positions,
        ...positions,
      },
    }));
  },
  clear() {
    set({
      positions: {},
    });
  },
}));
